import axios from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { GATEWAY_URL } from '../../Constant';

type ResetPasswordParams = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

type ResetPasswordWithTokenParams = {
  password: string;
  confirmPassword: string;
  resetToken: string;
};

type RequestResetPasswordParams = {
  email: string;
};

type GetPasswordResetTokenStatusParams = {
  resetToken: string;
};

interface getPasswordResetTokenStatus {
  passwordResetTokenStatus: string
}

interface PasswordResetState {
  isLoading: boolean
  passwordResetTokenStatus: string
}

export const resetPassword = createAsyncThunk(
  'resetPassword/resetPassword',
  async (
    {
      oldPassword,
      newPassword,
      newPasswordConfirm,
    }: ResetPasswordParams,
    {
      rejectWithValue,
    }
  ) => {
    try {
      const userToken = localStorage.getItem('userToken');

      await axios.post(
        `${GATEWAY_URL}/user/update-password`,
        {
          oldPassword,
          password: newPassword,
          passwordConfirmation: newPasswordConfirm,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
      return true
    } catch (error) {
      console.log(error);

      // return custom error message from API if any
      return rejectWithValue(error?.response?.data?.message
        || "Error resetting password!");
    }
  }
);

export const resetPasswordWithToken = createAsyncThunk(
  'resetPassword/resetPasswordWithToken',
  async (
    {
      password,
      confirmPassword,
      resetToken,
    }: ResetPasswordWithTokenParams,
    {
      rejectWithValue,
    }
  ) => {
    try {
      await axios.post(
        `${GATEWAY_URL}/password/reset-password`,
        {
          password: password,
          confirmPassword: confirmPassword,
          resetToken: resetToken,
        },
      );
      return true
    } catch (error) {
      console.log(error);

      // return custom error message from API if any
      return rejectWithValue(error?.response?.data?.message
        || "Error resetting password!");
    }
  }
);

export const requestResetPassword = createAsyncThunk(
  'resetPassword/requestResetPassword',
  async (
    {
      email,
    }: RequestResetPasswordParams,
    {
      rejectWithValue,
    }
  ) => {
    try {
      await axios.get(
        `${GATEWAY_URL}/password/forgot-password/${email}`
      );
      return true
    } catch (error) {
      console.log(error);

      // return custom error message from API if any
      return rejectWithValue(error?.response?.data?.message
        || "Error resetting password!");
    }
  }
);

export const getPasswordResetTokenStatus = createAsyncThunk(
  'resetPassword/getResetPasswordTokenStatus',
  async (
    {
      resetToken,
    }: GetPasswordResetTokenStatusParams,
    {
      rejectWithValue,
    }
  ) => {
    try {
      const response = await fetch(
        `${GATEWAY_URL}/password/get-password-reset-token-status/${resetToken}`
      );
      const data = await response.json();
      return data.passwordResetTokenStatus;
    } catch (error) {
      console.log(error);

      // return custom error message from API if any
      return rejectWithValue(error?.response?.data?.message
        || "Error getting password reset token status!");
    }
  }
);

