import { useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'

const ProtectedRoute = () => {
  const { userInfo } = useSelector((state) => (state as any).auth)

  // show unauthorized screen if no user is found in redux store
  if (!userInfo) {
    return (
      <Navigate to="/" replace />
    )
  }

  return <Outlet />
}

export default ProtectedRoute