import '../../styles/profile-preferences.css';

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
  RootState,
  useAppDispatch,
} from '../../app/store';
import ConditionsPreferencesDetails
  from '../../components/ConditionsPreferencesDetails';
import ConditionsPreferencesEdit
  from '../../components/ConditionsPreferencesEdit';
import useTranslation from '../../components/customHooks/Translations';
import {
  fetchPreferences,
  updatePreferences,
} from '../../features/traderPreferences/traderPreferencesActions';
import {
  actions,
} from '../../features/traderPreferences/traderPreferencesSlice';

const { setEdit } = actions;

function ProfilePreferencesScreen(): JSX.Element {
  const state = useSelector((state: RootState) => state.traderPreferences);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const translation = useTranslation();

  useEffect(() => {
    dispatch(fetchPreferences())
  }, [dispatch]);

  return (
    <div className="profile-preferences-container">
      {state.isEdit
        ? <ConditionsPreferencesEdit
          prefs={state.prefs}
          credibilityRating={userInfo?.credibilityRating ?? 0}
          loading={state.loading}
          error={state.error}
          onSubmit={(prefs) => {
            dispatch(updatePreferences(prefs));
          }}
        />
        : <>
          <div
            style={{
              textAlign: 'right',
              marginRight: '2rem',
            }}
          >
            <input
              className='btn'
              type="button"
              disabled={state.loading}
              value={translation.edit}
              onClick={() => {
                dispatch(setEdit(true))
              }}
            />
          </div>
          <ConditionsPreferencesDetails
            prefs={state.prefs}
          />
        </>
      }
    </div>
  )
}

export default ProfilePreferencesScreen;
