import './App.css';
import './styles/containers.css';

import { useState } from 'react';

import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import * as SockJS from 'sockjs-client';
import Stomp from 'stompjs';

import { RootState } from './app/store';
import Header from './components/Header';
import NewVersionAvailablePopup from './components/NewVersionAvailablePopup';
import Spinner from './components/Spinner';
import { GATEWAY_URL } from './Constant';
import ProtectedRoute from './routing/ProtectedRoute';
import AboutUsScreen from './screens/AboutUsScreen';
import AdvertsHome from './screens/AdvertsHome';
import BrokerHome from './screens/BrokerHome';
import HomeScreen from './screens/HomeScreen';
import ProfileScreen from './screens/profile/ProfileScreen';
import ProfileSetupScreen from './screens/ProfileSetupScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';

function App() {
  const [incomingMessage, setIncomingMessage] = useState([]);
  const [userDataLoad, setUserDataLoad] = useState("not loaded");
  const [loadRequested, setLoadRequest] = useState(false);
  const [userHeader, setUserHeader] = useState(false);
  const [websocketStatus, setWebsocketStatus] = useState(false);
  const [stateStompClient, setStompClient] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [socketMessageIndex, setSocketMessageIndex] = useState(0);
  const auth = useSelector((state: RootState) => state.auth);
  const isTrader = auth.userInfo?.role === 'TRADER';
  const websocketFailureCallback = function (userInfo) {
    console.log('Attempting Reconnect');
    setTimeout(() => {connect(userInfo)}, 10000);
    console.log('Reconnecting in 10 seconds');
  };

  function connect(userInfo) {
    const socket = new SockJS(GATEWAY_URL + '/websocket-endpoint');
    const stompClient = Stomp.over(socket);
    stompClient.reconnect_delay = 20000;
    stompClient.connect({}, () => {
      if(stompClient?.connected){
        setWebsocketStatus(true);
        setStompClient(stompClient);
        stompClient.subscribe('/topic/notifications/'+localStorage.getItem('userToken'), (message) => {
          console.log("Got Message: " + message);
          incomingMessage.push(message.body)
          setIncomingMessage([...incomingMessage]);
          message.ack()
        }, {ack: 'client'});
        console.log(stompClient);
      }
    }, (error)=> {
      setWebsocketStatus(false);
      console.log('Websocket Connection Lost');
      websocketFailureCallback(userInfo);

    });
  }

  function disconnect() {
    setWebsocketStatus(false);
    if (stateStompClient !== null) {
      console.log('Disconnecting from current client')
      stateStompClient.disconnect();
    }
    console.log("Disconnected");
  }

  window.addEventListener('beforeunload', () => {
    disconnect();
  });

  return (
    <Router>
      <main className={isMobile ? '': 'container content'}>
        {loadRequested && <Spinner/>}
        {auth.userInfo
          ? <NewVersionAvailablePopup />
          : null
        }
        <Routes>
          <Route
            element={
              <div>
                <Header
                  mobileMenu={mobileMenu}
                  setMobileMenu={setMobileMenu}
                  disconnectWS={disconnect}
                  userDataLoad={userDataLoad}
                  userHeader={userHeader}
                  setUserHeader={setUserHeader}
                />
                <Outlet />
              </div>
            }
          >
            <Route
              path='/'
              element={
                <HomeScreen
                  mobileMenu={mobileMenu}
                  setLoadRequest={setLoadRequest}
                  connectWS={connect}
                  setUserHeader={setUserHeader}
                  userDataLoad={userDataLoad}
                  setUserDataLoad={setUserDataLoad}
                />
              }
            />
            <Route
              path='/about-us'
              element={
                <AboutUsScreen mobileMenu={mobileMenu} />
              }
            />
            <Route
                path='/forget-password'
                element={
                  <ForgotPasswordScreen
                  />
                }
              />
               <Route
                path='/reset-password'
                element={
                  <ResetPasswordScreen
                  />
                }
              />
          </Route>
          <Route
            element={
              <div className='user-background-container'>
                <ProtectedRoute />
              </div>
            }>
              <Route
                path='/first-login'
                element={
                  <ProfileSetupScreen
                    userHeader={userHeader}
                    setUserHeader={setUserHeader}
                    setUserDataLoad={setUserDataLoad}
                    userDataLoad={userDataLoad}
                    disconnectWS={disconnect}
                    incomingMessage={incomingMessage}
                  />
                }
              />
              <Route
                path='/adverts-home'
                element={
                  <AdvertsHome
                    setSocketMessageIndex={setSocketMessageIndex}
                    socketMessageIndex={socketMessageIndex}
                    userHeader={userHeader}
                    setUserHeader={setUserHeader}
                    setUserDataLoad={setUserDataLoad}
                    userDataLoad={userDataLoad}
                    disconnectWS={disconnect}
                    incomingMessage={incomingMessage}
                  />
                }
              />
              <Route
                path='/brokers-home'
                element={
                  <BrokerHome
                    setSocketMessageIndex={setSocketMessageIndex}
                    socketMessageIndex={socketMessageIndex}
                    userHeader={userHeader}
                    setUserHeader={setUserHeader}
                    userDataLoad={userDataLoad}
                    disconnectWS={disconnect}
                    incomingMessage={incomingMessage}
                  />
                }
              />
              <Route
                path='/profile'
                element={
                  isTrader
                  ? <Navigate to='/profile/ioi-counts' replace />
                  : <Navigate to='/profile/security' replace />
                }
              />
              <Route
                path='/profile/*'
                element={
                  <ProfileScreen
                    disconnectWS={disconnect}
                    setUserHeader={setUserHeader}
                  />
                }
              />
          </Route>
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </main>
    </Router>
  )
}

export default App
