import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import {GATEWAY_URL} from '../../Constant'

interface StaffState {
  isLoading: boolean;
  error: string | null;
  lastAction: string | null;
}

const initialState: StaffState = {
  isLoading: false,
  error: null,
  lastAction: null,
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    contactAccountManagerStart(state) {
      state.isLoading = true;
      state.error = null;
      state.lastAction= 'contactAccountManagerStart';
    },
    contactAccountManagerSuccess(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.lastAction= 'contactAccountManagerSuccess';
    },
    contactAccountManagerFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction= 'contactAccountManagerFailure';
    }
  },
});

export const {
  contactAccountManagerStart,
  contactAccountManagerSuccess,
  contactAccountManagerFailure,
} = staffSlice.actions;

export default staffSlice.reducer;

export const contactAccountManager = (): AppThunk => async (dispatch, getState) => {
  dispatch(contactAccountManagerStart());
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/staff/contact-account-manager', {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const data = await response;
    dispatch(contactAccountManagerSuccess(data));
  } catch (error) {
    dispatch(contactAccountManagerFailure(error.message));
  }
};
