import '../../styles/IOIpopups.css';

import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useSelector } from 'react-redux';

import { LocateNotification } from '../../app/model/model';
import {
  RootState,
  useAppDispatch,
} from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';
import {
  fetchLocationNotification,
  resumeAdvert,
} from '../../features/adverts/advertisementActions';

type DeclineNotification = {
  locateNotificationId?: string;
  message: 'Connection Declined' | 'Contra Connection Declined' | 'Contra locate timeout';
  advertId?: string;
};

type IOIConnectionDeclinedProps = {
  notification: DeclineNotification;
  notificationStack: any[];
  updateNotificationStack: (stack: any[]) => void;
  closeNotification: (notification: DeclineNotification) => void;
}

export const IOIConnectionDeclined = ({
  notification,
  notificationStack,
  updateNotificationStack,
  closeNotification,
}: IOIConnectionDeclinedProps) => {

  const dispatch = useAppDispatch();

  const [direction, setDirection] = useState<'buy' | 'sell'>('buy');

  let advancedDetails = false;

  const equitiesList = useSelector((state: RootState) => state.equities);
  const userAdverts = useSelector((state: RootState) => state.advertisements.advertisements);
  const [notificationEquityName, setNotificationEquityName] = useState('');
  const [locateNotification, setLocateNotification] = useState(undefined);

  const getLocateNotification = useCallback(async () => {
    const result = await dispatch(fetchLocationNotification(notification?.locateNotificationId || ''));
    setLocateNotification(result);
  }, [dispatch, notification?.locateNotificationId])

  useEffect(() => {
    getLocateNotification()
  }, [getLocateNotification])

  useEffect(() => {
    const correctEquity = equitiesList.equities.find(equity => equity.equityId === locateNotification?.securityId);
    setNotificationEquityName(correctEquity?.ric + ' - ' + correctEquity?.name);
    const updateNotifications = [...notificationStack];
    if (updateNotifications.includes(notification)
      && !('securityId' in notification)
      && correctEquity !== undefined) {
      const prevNotif = updateNotifications.findIndex((notif) => {
        return notif?.securityId === correctEquity.equityId && notif?.message !== 'Contra Connection Accepted!'
      });
      if (prevNotif !== -1) {
        updateNotifications.splice(prevNotif,1);
      }
      updateNotifications[updateNotifications.indexOf(notification)].securityId = correctEquity.equityId;
      updateNotificationStack([...updateNotifications]);
    }
    if(userAdverts.findIndex(advert => advert.id === locateNotification?.buyId) !== -1){
      setDirection('buy');
    }else{
      setDirection('sell');
    }
  }, [equitiesList.equities, locateNotification, notification, notificationStack, updateNotificationStack, userAdverts])

  return (
    <IOIConnectionDeclinedContent
      advancedDetails={advancedDetails}
      direction={direction}
      notification={notification}
      notificationEquityName={notificationEquityName}
      locateNotification={locateNotification}
      closeNotification={closeNotification}
    />
  )
}

type IOIConnectionDeclinedContentProps = {
  advancedDetails: boolean;
  direction: 'buy' | 'sell';
  notification: DeclineNotification;
  notificationEquityName: string;
  locateNotification: LocateNotification;
  closeNotification: (notification: DeclineNotification) => void;
};

export function IOIConnectionDeclinedContent({
  advancedDetails,
  direction,
  notification,
  notificationEquityName,
  locateNotification,
  closeNotification,
}: IOIConnectionDeclinedContentProps) {
  const translation = useTranslation();
  const dispatch = useAppDispatch();

  function getMessageString(message) {
    let retVal = '';
    switch (message) {
      case 'Connection Declined':
        retVal = translation.connectionDeclined;
        break;
      case 'Contra Connection Declined':
        retVal = translation.contraConnectionDeclined;
        break;
      case 'Contra locate timeout':
        retVal = translation.locateTimeout;
        break;
      default:
        break;
    }
    return retVal;
  }

  return (
    <div className={advancedDetails ? 'IOI-basic-popup-advanced':'IOI-basic-popup'}>
      <div className='IOI-connection-declined-top'>{getMessageString(notification?.message)}</div>
      <div className='IOI-review-body'>
        <div className={advancedDetails ? 'IOI-main-details-advanced':'IOI-main-details'}>
          <div className='two-line-clamp'>
            <span className={`IOI-main-details-direction-${direction?.toLowerCase()}`}>
              {direction === 'buy' ? translation.buy : translation.sell}
            </span>
            {' '}
            <span className='IOI-main-details-security'>
              {notificationEquityName}
            </span>
          </div>
          {' '}
          <span className='IOI-main-details-amount'>
            ({`${locateNotification?.locateQuantity} ${translation.shares}`})
          </span>
        </div>
        <div className={advancedDetails ? 'IOI-advanced-details': 'hide'} >
          <div className='IOI-review-advertising-trigger IOI-advanced-details-content-div'>
            <span className='IOI-advanced-details-label'>{translation.advertisingTrigger}:</span>
            <span className={`IOI-advanced-details-value-${direction?.toLowerCase()}`}>JPY 1,950</span>
          </div>
          <div className='IOI-cancel-trigger IOI-advanced-details-content-div'>
            <span className='IOI-advanced-details-label'>{translation.cancelTrigger}:</span>
            <span className={`IOI-advanced-details-value-${direction?.toLowerCase()}`}>JPY 1,950</span>
          </div>
          <div className='IOI-index-relative-cancel IOI-advanced-details-content-div'>
            <span className='IOI-advanced-details-label'>{translation.indexRelativeCancel}:</span>
            <span className={`IOI-advanced-details-value-${direction?.toLowerCase()}`}>3%</span>
          </div>
        </div>
        <div className='IOI-review-buttons'>
          {notification?.message === 'Contra Connection Declined'
            && notification?.advertId
            ? (
              <button
                className='review-send-button'
                onClick={()=> {
                  dispatch(resumeAdvert(notification.advertId))
                  closeNotification(notification)
                }}
              >
                {translation.resume}
              </button>
            )
            : null
          }
          <button
            className='connection-declined-button'
            onClick={()=> {
              closeNotification(notification)
            }}
          >
            {translation.ok}
          </button>
        </div>
      </div>
    </div>
  )
}

export default IOIConnectionDeclined;
