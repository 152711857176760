import '../../styles/profile-security.css';

import ResetPassword from '../../components/profile/ResetPassword';
import TwoFactorAuth from '../../components/profile/TwoFactorAuth';

function ProfileSecurityScreen(): JSX.Element {
  return (
    <div className="profile-security-container">
      <ResetPassword />
      <TwoFactorAuth />
    </div>
  )
}

export default ProfileSecurityScreen;
