import '../../styles/profile.css';

import { useSelector } from 'react-redux';
import {
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom';

import { RootState } from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';
import Header from '../../components/Header';
import Tabs from '../../components/tabs/Tabs';
import IOICountsScreen from './IOICountsScreen';
import ProfilePreferencesScreen from './ProfilePreferences';
import ProfileSecurityScreen from './ProfileSecurityScreen';

type ProfileScreenProps = {
  disconnectWS: () => void;
  setUserHeader: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileScreen = ({
  disconnectWS,
  setUserHeader,
}: ProfileScreenProps) => {
  const { params: { tab } } = useMatch('/profile/:tab/*');
  const navigate = useNavigate();
  const translation = useTranslation();
  const auth = useSelector((state: RootState) => state.auth);
  const isTrader = auth.userInfo?.role === 'TRADER';
  const tabs = [
    isTrader
      ? {
        label: translation.ioiCounts,
        value: 'ioi-counts',
      }
      : null,
    isTrader
      ? {
        label: translation.condPrefs,
        value: 'preferences',
      }
      : null,
    {
      label: translation.profileSecurity,
      value: 'security',
    }
  ].filter(Boolean);

  return (
    <div className='profile-container'>
      <div className='profile-container-inner'>
        <Header
          userHeader
          container='profile-header'
          hideNotifications={true}
          disconnectWS={disconnectWS}
          setUserHeader={setUserHeader}
        />
        <div className='profile-content'>
          <Tabs
            tabs={tabs}
            activeValue={tab}
            onTabClick={(value) => {
              navigate(`./${value}`);
            }}
          />
          <Routes>
            {isTrader
              ? <Route
                path='/ioi-counts'
                element={<IOICountsScreen />}
              />
              : null
            }
            {isTrader
              ? <Route
                path='/preferences'
                element={<ProfilePreferencesScreen />}
              />
              : null
            }
            <Route
              path='/security'
              element={<ProfileSecurityScreen />}
            />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default ProfileScreen