import '../styles/forms.css';
import '../styles/buttons.css';

import {
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import useTranslation from '../components/customHooks/Translations';
import {
  RootState,
  useAppDispatch,
} from '../app/store';
import {
  getPasswordResetTokenStatus,
  resetPasswordWithToken,
} from '../features/resetPassword/resetPasswordActions';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PasswordResetEmailSentPopup from '../components/PasswordResetEmailSentPopup';

type Inputs = {
  password: string;
  confirmPassword: string,
  resetToken: string;
}

function ResetPasswordScreen(): JSX.Element {
  const state = useSelector((state: RootState) => state.resetPassword);
  const dispatch = useAppDispatch();
  const translation = useTranslation();
  const [queryParameters] = useSearchParams();
  const resetToken = queryParameters.get("resetToken");
  const [tokenStatus, setTokenStatus] = useState('PASSWORD_TOKEN_OK')
  const [showPopup, setShowPopup] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm<Inputs>({
    reValidateMode: 'onChange',
  });

  const onValid: SubmitHandler<Inputs> = ({
    password,
    confirmPassword
  }) => {
    setShowPopup(true)
    dispatch(resetPasswordWithToken({
      password,
      confirmPassword,
      resetToken
    }))
  };
  
  useEffect(() => {
    dispatch(getPasswordResetTokenStatus({resetToken})).then((result) =>
      setTokenStatus(result.payload)
    )
  }, [dispatch])

  
  if (state.error) {
    return <PasswordResetEmailSentPopup message={translation.gatewayError} redirect='/forget-password'/>
  }

  if (tokenStatus !== 'PASSWORD_TOKEN_OK') {
    return <PasswordResetEmailSentPopup message={translation.linkExpired} redirect='/forget-password'/>
  }

  if (showPopup) {
    return <PasswordResetEmailSentPopup message={translation.passwordResetSuccess} redirect='/login'/>
  }

  return (
    <div className='reset-password-content'>
      <div className='form-container'>
        <form
          className='form'
          style={{
            alignItems: 'center',
            gap: '2rem',
            marginTop: '12rem',
          }}
          onSubmit={handleSubmit(onValid)}
        >
          <div id="Logo_g">
            <img
              id="Logo_ha"
              alt="Authentic Indication"
              src="Logo_ha.png"
              srcSet="Logo_ha.png 1x, Logo_ha@2x.png 2x"
            />
          </div>
          <h4 className='header'>{translation.resetPassword}</h4>
          <div className='form-field'>
            <label htmlFor="password">{translation.newPassword}</label>
            <input
              type='password'
              className={errors.password && 'error'}
              {...register('password', {
                required: true,
                deps: 'newPassword',
                onChange() {
                  trigger('password');
                }
              })}
            />
            {errors.password && <p className='error' role="alert">{errors.password.message}</p>}
          </div>
          <div className='form-field'>
            <label htmlFor="confirmPassword">{translation.confirmNewPassword}</label>
            <input
              type='password'
              className={errors.confirmPassword && 'error'}
              {...register('confirmPassword', {
                required: true,
                onChange() {
                  if (dirtyFields.confirmPassword) {
                    trigger('confirmPassword');
                  }
                },
                validate(value, formValues) {
                  return value === formValues.password
                  || translation.passwordDoesNotMatch;
                }
              })}
            />
            {errors.confirmPassword && <p className='error' role="alert">{errors.confirmPassword.message}</p>}
          </div>
          <div>
            <input
              className='btn'
              type="submit"
              value={translation.submitForm}
              disabled={state.loading}
            />
          </div>
          {state.error && <p className='error'>{state.error === "Invalid existing password" ? translation.invalidExistingPassword : state.error}</p>}
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordScreen;
