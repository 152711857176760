import Header from '../components/Header'
import PropTypes from 'prop-types';
import useTranslation from '../components/customHooks/Translations';
import { useNavigate } from 'react-router-dom'

import '../styles/containers.css'
import '../styles/profileSetup.css'
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../app/store';
import { callFirstSetup } from '../features/auth/authSlice'
import { useForm } from 'react-hook-form'

const ProfileSetupScreen = props => {
  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translation = useTranslation();

  const [notifications, setNotifications] = useState([]);

  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if(auth.lastAction === 'firstSetupSuccess'){
      navigate('/');
    }
  }, [auth.lastAction])

  const submitForm = (data) => {
    if(data.password === data.passwordConfirmation) {
      dispatch(callFirstSetup(data.password, data.passwordConfirmation) as any);
    } 
  }

  return (
    <div className='profile-setup-container containers'>
      {<Header notifications={notifications} setNotifications={setNotifications} container={"profile-setup"} disconnectWS={props.disconnectWS} userDataLoad={props.userDataLoad} userHeader={props.userHeader} setUserHeader={props.setUserHeader}/>}
      <div className='profile-setup-main'>
        <div className='profile-setup-main-header'>
            {translation.firstLoginWelcome}
        </div>
        <div className='profile-setup-main-message'>
           {translation.firstLoginInstructions}
        </div>
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
      <div className='password'>
        {translation.password}
      </div>
      <div className='password-form'>
        <svg className="Rectangle_password_gt">
          <rect id="Rectangle_password_gt" rx="22" ry="22" x="0" y="0" width="100%" height="100%">
          </rect>
        </svg>
        <div className='form-group password-input'>
          <input
            type='password'
            className='form-input setup-form-input'
            placeholder='Min. 8 characters'
            {...register('password')}
            required
          />
        </div>
      </div>
      <div className='confirm-password'>
        {translation.passwordConfirmation}
      </div>
      <div className='confirm-password-form'>
        <svg className="Rectangle_password_gt">
          <rect id="Rectangle_password_gt" rx="22" ry="22" x="0" y="0" width="100%" height="100%">
          </rect>
        </svg>
        <div className='form-group password-input'>
          <input
            type='password'
            className='form-input setup-form-input'
            placeholder='Min. 8 characters'
            {...register('passwordConfirmation')}
            required
          />
        </div>
      </div>
      <button type='submit' className='submit-password-button prevent-select' disabled={auth?.loading}>
        <span>{translation.submit}</span>
      </button>
      </form>
    </div>
  )
}

ProfileSetupScreen.propTypes = {
  incomingMessage: PropTypes.array.isRequired,
  disconnectWS: PropTypes.func.isRequired,
  userDataLoad: PropTypes.string.isRequired,
  setUserDataLoad: PropTypes.func.isRequired,
  userHeader: PropTypes.bool.isRequired,
  setUserHeader: PropTypes.func.isRequired,
};

export default ProfileSetupScreen