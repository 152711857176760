import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import { Broker } from '../../app/model/model';
import {GATEWAY_URL} from '../../Constant'

interface BrokerState {
  isLoading: boolean;
  brokers: Broker[];
  error: string | null;
  lastAction: string | null;
}

const initialState: BrokerState = {
  isLoading: false,
  brokers: [],
  error: null,
  lastAction: null,
};

const brokersSlice = createSlice({
  name: 'brokers',
  initialState,
  reducers: {
    fetchBrokersStart(state) {
      state.isLoading = true;
      state.error = null;
      state.lastAction = 'fetchStart';
    },
    fetchBrokersSuccess(state, action: PayloadAction<Broker[]>) {
      state.isLoading = false;
      state.brokers = action.payload;
      state.lastAction = 'fetchSuccess';
    },
    fetchBrokersFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'fetchFailure'
    }
  },
});

export const {
  fetchBrokersStart,
  fetchBrokersSuccess,
  fetchBrokersFailure,
} = brokersSlice.actions;

export default brokersSlice.reducer;

export const fetchBrokers = (): AppThunk => async (dispatch, getState) => {
  dispatch(fetchBrokersStart());
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/broker/user-brokers-list', {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const data = await response.json();
    dispatch(fetchBrokersSuccess(data));
  } catch (error) {
    dispatch(fetchBrokersFailure(error.message));
  }
};
