import '../../styles/IOIpopups.css';

import React, {
  useEffect,
  useState,
} from 'react';

import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { TransactionType } from '../../app/model/enums';
import { RootState } from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';
import {
  BuySellLower,
  BuySellUpper,
} from './IOIConnectionAccepted';

type ContraLocatedNotification = {
  ack: boolean;
  contraDirection: BuySellUpper | BuySellLower;
  securityId: string;
  transactionType: TransactionType;
  locateQuantity: string;
  contraMinimum: string;
  stockMovePercentage: string;
  indexMovePercentage: string;
};

type ContraLocatedProps = {
  notification: ContraLocatedNotification;
  closeNotification: (
    notification: ContraLocatedNotification,
    response?: 'decline' | 'accept',
  ) => void;
}

export const ContraLocated = ({
  notification,
  closeNotification,
}: ContraLocatedProps) => {

  const translation = useTranslation();

  const [direction, setDirection] = useState<BuySellLower>('buy');
  const [countdown, setCountdown] = useState(30);
  const [notificationEquityName, setNotificationEquityName] = useState('');
  const [transactionTypeText, setTransactionTypeText] = useState('');

  const equitiesList = useSelector((state: RootState) => state.equities);

  useEffect(() => {
    if (!notification.ack) {
      countdown > 0 && setTimeout(() => setCountdown(countdown - .1), 100);
      if (countdown <= 0) {
        closeNotification(notification)
      };
    }
  }, [countdown]);

  useEffect(() => {
    setDirection(notification.contraDirection.toLowerCase() as BuySellLower);
    const correctEquity = equitiesList.equities.find(equity => equity.equityId === notification.securityId);
    setNotificationEquityName(`${correctEquity.ric} - ${correctEquity.name}`);
    if (notification.transactionType === 'CASH_SWAP'){
      setTransactionTypeText(translation.transactionTypeCASH_SWAP)
    } else if (notification?.transactionType === 'SWAP_ONLY') {
      setTransactionTypeText(translation.transactionTypeSWAP_ONLY)
    } else {
      setTransactionTypeText(translation.transactionTypeCASH_ONLY)
    }

  }, [equitiesList.equities, notification, translation])

  return (
    <div className='IOI-contra-located-popup' onLoad={() => window.focus()}>
      <div
        className='IOI-contra-located-top'
        style={notification.ack
          ? {
            background: '#0D1265',
            color: '#FFFFFF',
          } : {
            background: `linear-gradient(to right,#FFB94A 0%,#FFB94A ${countdown / 30 * 100}%,#C9C9C9 ${countdown / 30 * 100}%,#C9C9C9 100%)`
          }
        }
      >
        {translation.contraLocated}
      </div>
      <div className='IOI-contra-located-body'>
        <div className='IOI-accepted-security-locate'>
          {notificationEquityName}
        </div>
        <div className='IOI-contra-located-details'>
          <div className='IOI-connection-detail-div'>
            <span className='IOI-contra-detail-label'>
              {translation.contraIoiDirection}:
            </span>
            <span className={`IOI-contra-located-detail-value-${direction}`}>
              {direction.toUpperCase()}
            </span>
          </div>
          <div className='IOI-connection-detail-div'>
            <span className='IOI-contra-detail-label'>{translation.commonQuantity}:</span>
            <span className={`IOI-contra-located-detail-value-${direction}`}>
              <NumericFormat
                id='commonQuantity-value'
                displayType='text'
                value={notification.locateQuantity || '0'}
                thousandSeparator=','
                decimalScale={0}
              />
            </span>
          </div>
          <div className='IOI-connection-detail-div'>
            <span className='IOI-contra-detail-label'>
              {translation.transactionType}:
            </span>
            <span className={`IOI-contra-located-detail-value-${direction}`}>
              {transactionTypeText}
            </span>
          </div>
          <div className='IOI-connection-detail-div'>
            <span className='IOI-contra-detail-label'>
              {translation.minimumQuantity}:
            </span>
            <span className={`IOI-contra-located-detail-value-${direction}`}>
              <NumericFormat
                id='minimumQuantity-value'
                displayType='text'
                value={notification.contraMinimum || '0'}
                thousandSeparator=','
                decimalScale={0}
              />
            </span>
          </div>
          <div className='IOI-connection-divider-div'></div>
          <div className='IOI-connection-detail-div'>
            <span className='IOI-contra-detail-label'>
              {translation.stockMove}:
            </span>
            <span
              className={'IOI-connection-detail-value'}
              style={{
                color: '#000000',
                fontWeight: 'bold'
              }}
            >
              <NumericFormat
                id='stockMove-value'
                displayType='text'
                value={notification.stockMovePercentage || '0'}
                thousandSeparator=','
                decimalScale={1}
                fixedDecimalScale
              />%
            </span>
          </div>
          <div className='IOI-connection-detail-div'>
            <span className='IOI-contra-detail-label'>
              {translation.indexMove}:
            </span>
            <span
              className={'IOI-connection-detail-value'}
              style={{
                color: '#000000',
                fontWeight: 'bold'
              }}
            >
              <NumericFormat
                id='indexMove-value'
                displayType='text'
                value={notification.indexMovePercentage || '0'}
                thousandSeparator=','
                decimalScale={1}
                fixedDecimalScale
              />%
            </span>
          </div>
        </div>
        {notification.ack
          ? (
            <button
              className='IOI-locate-close-button'
              onClick={() => closeNotification(notification)}
            >
              {translation.close}
            </button>
          )
          : (
            <div className='IOI-contra-located-buttons'>
              <button
                className='contra-decline-button'
                onClick={() => closeNotification(notification, 'decline')}
              >
                {translation.decline}
              </button>
              <div className='contra-located-timer'>
                {countdown.toFixed(0)}
              </div>
              <button
                className='contra-accept-button'
                onClick={() => closeNotification(notification, 'accept')}
              >
                {translation.accept}
              </button>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ContraLocated;
