import '../styles/forms.css';
import '../styles/buttons.css';

import {
  AdvertisementDuration,
  TransactionType,
} from '../app/model/enums';
import {
  ConditionsPreferences as ConditionsPreferencesType,
} from '../features/traderPreferences/traderPreferencesSlice';
import useTranslation from './customHooks/Translations';

type ConditionsPreferencesDetailsProps = {
  prefs: ConditionsPreferencesType;
}

function getTransactionTypeLabel(
  transactionType: TransactionType,
  translation: ReturnType<typeof useTranslation>,
) {
  switch (transactionType) {
    case TransactionType.CASH_ONLY:
      return translation.transactionTypeCASH_ONLY;
    case TransactionType.CASH_SWAP:
      return translation.transactionTypeCASH_SWAP;
    case TransactionType.SWAP_ONLY:
      return translation.transactionTypeSWAP_ONLY;
  }
}

function ConditionsPreferencesDetails({
  prefs: {
    advertPreferences: {
      contraTransactionType,
      credibilityHurdle,
      duration,
      minQuantity,
      quantityDecay,
      transactionType,
    },
    pathPreferences: {
      brokers,
      ranked
    },
    priceConditions: {
      advertTriggerOffset,
      advertTriggerStatus,
      indexRelativeOffset,
      indexRelativeStatus,
      knockoutTriggerOffset,
      knockoutTriggerStatus,
    },
  },
}: ConditionsPreferencesDetailsProps): JSX.Element {
  const translation = useTranslation();
  const credibilityStars = Math.trunc((credibilityHurdle - 1) / 20) + 1

  return (
    <div
      className='preferences-conditions-content'
      style={{
        paddingBottom: '2rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
      }}
    >
      <div className='form-container'>
        <div className='form horizontal'>
          <div className='form-field'>
            <label>{translation.transactionType}</label>
            <span>{getTransactionTypeLabel(transactionType, translation)}</span>
          </div>
          <h4>{translation.priceConditions}</h4>
          <div className='form-field'>
            <label>{translation.advertisingTrigger}</label>
            <span
              style={{
                color: advertTriggerStatus ? null : '#96969F',
              }}
            >{`${advertTriggerOffset}%`}</span>
          </div>
          <div className='form-field'>
            <label>{translation.cancelTrigger}</label>
            <span
              style={{
                color: knockoutTriggerStatus ? null : '#96969F',
              }}
            >{`${knockoutTriggerOffset}%`}</span>
          </div>
          <div className='form-field'>
            <label>{translation.indexRelativeCancel}</label>
            <span
              style={{
                color: indexRelativeStatus ? null : '#96969F',
              }}
            >{`${indexRelativeOffset}%`}</span>
          </div>
          <h4>{translation.advertisingPreferences}</h4>
          <div className='form-field'>
            <label>{translation.credibilityFilter}</label>
            <div className='credibility-filter-value'>
              {Array.apply(null, { length: credibilityStars}).map((_: null, i: number) => (
                <img
                  key={'credibilityFilter' + i}
                  alt={'credibilityFilter' + i}
                  src="/Group_11.png"
                  srcSet="/Group_11.png 1x, /Group_11@2x.png 2x"
                />
              ))}
              {Array.apply(null, { length: 5 - credibilityStars }).map((_: null, i: number) => {
                const val = credibilityHurdle + ((i + 1) * 20);
                return (
                  <svg
                    key={'credibilityFilter' + val}
                    className="Path_315_xl"
                    viewBox="1.075 1.13 23.277 22.138"
                  >
                    <path id="Path_315_xl" d="M 12.71335887908936 1.12969970703125 L 16.30998229980469 8.417112350463867 L 24.35202026367188 9.585659027099609 L 18.53284645080566 15.2583179473877 L 19.9066047668457 23.26806831359863 L 12.71335887908936 19.48629760742188 L 5.520115375518799 23.26806831359863 L 6.89387321472168 15.2583179473877 L 1.074699401855469 9.585659027099609 L 9.116737365722656 8.417112350463867 L 12.71335887908936 1.12969970703125 Z">
                    </path>
                  </svg>
                );
              })}
            </div>
          </div>
          <div className='form-field'>
            <label>{translation.duration}</label>
            <span>
              {duration === AdvertisementDuration.END_OF_DAY
                ? translation.goodForTheDay
                : duration + ' ' + translation.minutes
              }
            </span>
          </div>
          <div className='form-field'>
            <label>{translation.minimumQ}</label>
            <span>{`${(minQuantity).toFixed(0)}%`}</span>
          </div>
          <div className='form-field'>
            <label>{translation.quantityDecay}</label>
            <span>{quantityDecay ? translation.yes : translation.no}</span>
          </div>
          <div className='form-field'>
            <label>{translation.contraTransactionType}</label>
            <span>{getTransactionTypeLabel(contraTransactionType, translation)}</span>
          </div>
          <h4>{translation.pathPreferences}</h4>
          <div className='form-field'>
            <label>{translation.rankedEqual}</label>
            <span>{ranked ? translation.ranked : translation.equalRank}</span>
          </div>
          <div className='form-field'>
            <label>{translation.brokers}</label>
            <span>{brokers.map(b => b.shortName).join(', ')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConditionsPreferencesDetails;
