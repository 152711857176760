import { useRef } from 'react';

import {
  Broker,
  BrokerResponse,
} from '../../app/model/model';

function useDragDropBrokers(
  brokersList: Broker[],
  rankOrder: BrokerResponse[],
  onChange: (rankOrder: BrokerResponse[]) => void,
) {
  const dragItem = useRef<number>();
  const dragOverItem = useRef<number>();

  const dragStart = (
    e: React.DragEvent<HTMLDivElement>,
    i: number,
  ) => {
    dragItem.current = i;
  };

  const dragEnter = (
    e: React.DragEvent<HTMLDivElement>,
    i: number,
  ) => {
    const target = e.target as HTMLDivElement;
    if(target.outerText !== '' && target.outerText !== undefined) {
      dragOverItem.current = i;
    }
  };

  const drop = (e: React.DragEvent<HTMLDivElement>) => {
    const copyBrokers = [...rankOrder];
    copyBrokers[
      dragItem.current !== undefined
      ? dragItem.current
      : 0
    ] = copyBrokers.splice(
      dragOverItem.current,
      1,
      copyBrokers[
        dragItem.current !== undefined
        ? dragItem.current
        : 0
      ]
    )[0];
    onChange(copyBrokers);
  };

  function replaceBroker(
    originalBrokerIndex: number,
    newBrokerIndex: number,
  ) {
    const copyBrokers = [...rankOrder];
    const positionOfNewBroker = copyBrokers.findIndex(
      b => b.id === brokersList[newBrokerIndex].brokerID
    );
    if(positionOfNewBroker !== -1) {
      copyBrokers[originalBrokerIndex] = copyBrokers.splice(
        positionOfNewBroker,
        1,
        copyBrokers[originalBrokerIndex]
      )[0];
    } else {
      const broker = brokersList[newBrokerIndex];
      copyBrokers[originalBrokerIndex] = {
        id: broker.brokerID,
        brokerName: broker.brokerName,
        shortName: broker.shortName,
      };
    }
    onChange(copyBrokers);
  }

  function removeBroker(originalBroker: number) {
    const copyBrokers = [...rankOrder];
    copyBrokers.splice(originalBroker, 1);
    onChange(copyBrokers);
  }

  function addBroker(newBrokerIndex: number) {
    const copyBrokers= [...rankOrder];
    if(
      copyBrokers.findIndex(
        b => b.id === brokersList[newBrokerIndex].brokerID
      ) === -1
    ){
      const broker = brokersList[newBrokerIndex];
      copyBrokers.push({
        id: broker.brokerID,
        brokerName: broker.brokerName,
        shortName: broker.shortName,
      });
    } else {
      //already in list do not add again
    }
    onChange(copyBrokers);
  }

  return {
    dragStart,
    dragEnter,
    drop,
    replaceBroker,
    removeBroker,
    addBroker,
  }
}

export default useDragDropBrokers;
