import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import {GATEWAY_URL} from '../../Constant'

interface DemoState {
  isLoading: boolean;
  error: string | null;
}

const initialState: DemoState = {
  isLoading: false,
  error: null,
};

const demoSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    resetDemoStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    resetDemoSuccess(state, action: PayloadAction<string>) {
      state.isLoading = false;
    },
    resetDemoFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  },
});

export const {
  resetDemoStart,
  resetDemoSuccess,
  resetDemoFailure,
} = demoSlice.actions;

export default demoSlice.reducer;

export const resetDemo = (): AppThunk => async (dispatch, getState) => {
  dispatch(resetDemoStart());
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/test/delete-all', {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const data = await response.json();
    dispatch(resetDemoSuccess(data));
  } catch (error) {
    dispatch(resetDemoFailure(error.message));
  }
};
