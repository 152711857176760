import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  AdvertisementDuration,
  TransactionType,
} from '../../app/model/enums';
import { BrokerResponse } from '../../app/model/model';
import {
  fetchPreferences,
  updatePreferences,
} from './traderPreferencesActions';

type PriceConditions = {
  advertTriggerOffset: number;
  advertTriggerStatus: boolean;
  knockoutTriggerOffset: number;
  knockoutTriggerStatus: boolean;
  indexRelativeOffset: number;
  indexRelativeStatus: boolean;
}

type AdvertPreferences = {
  credibilityHurdle: number;
  duration: AdvertisementDuration;
  minQuantity: number;
  quantityDecay: boolean;
  transactionType: TransactionType;
  contraTransactionType: TransactionType;
}

type PathPreferences = {
  ranked: boolean;
  brokers: BrokerResponse[];
}

export type ConditionsPreferences = {
  priceConditions: PriceConditions;
  advertPreferences: AdvertPreferences;
  pathPreferences: PathPreferences;
};

type TraderPreferencesState = {
  loading: boolean;
  success: boolean;
  error?: string;
  isEdit: boolean;
  prefs: ConditionsPreferences,
}

const initialState: TraderPreferencesState = {
  loading: false,
  success: false,
  isEdit: false,
  error: null,
  prefs: {
    priceConditions: {
      advertTriggerOffset: 0,
      advertTriggerStatus: false,
      knockoutTriggerOffset: 5,
      knockoutTriggerStatus: false,
      indexRelativeOffset: 0,
      indexRelativeStatus: false,
    },
    advertPreferences: {
      credibilityHurdle: 3,
      duration: AdvertisementDuration.SIXTY,
      minQuantity: 25,
      quantityDecay: false,
      transactionType: TransactionType.CASH_ONLY,
      contraTransactionType: TransactionType.CASH_ONLY,
    },
    pathPreferences: {
      ranked: false,
      brokers: [],
    }
  },
}

const traderPreferencesSlice = createSlice({
  name: 'traderPreferences',
  initialState,
  reducers: {
    setEdit(state, action: PayloadAction<boolean>) {
      state.isEdit = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPreferences.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(fetchPreferences.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.prefs = action.payload;
      })
      .addCase(fetchPreferences.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload as string;
      })
      .addCase(updatePreferences.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updatePreferences.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.prefs = action.payload;
        state.isEdit = false;
      })
      .addCase(updatePreferences.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload as string;
      })
  }
});

export default traderPreferencesSlice.reducer;
export const { actions } = traderPreferencesSlice;
