import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './app/store'
import reportWebVitals from './reportWebVitals';
import LanguageContextProvider from "./components/contexts/LanguageContext";

//Adding Local Fonts
//Noto_Sans
import './fonts/Noto_Sans/NotoSans-Black.ttf';
//import './fonts/Noto_Sans/NotoSans-BlackItalic.ttf';
import './fonts/Noto_Sans/NotoSans-Bold.ttf';
//import './fonts/Noto_Sans/NotoSans-BoldItalic.ttf';
import './fonts/Noto_Sans/NotoSans-ExtraBold.ttf';
//import './fonts/Noto_Sans/NotoSans-ExtraBoldItalic.ttf';
import './fonts/Noto_Sans/NotoSans-ExtraLight.ttf';
//import './fonts/Noto_Sans/NotoSans-ExtraLightItalic.ttf';
//import './fonts/Noto_Sans/NotoSans-Italic.ttf';
import './fonts/Noto_Sans/NotoSans-Light.ttf';
//import './fonts/Noto_Sans/NotoSans-LightItalic.ttf';
import './fonts/Noto_Sans/NotoSans-Medium.ttf';
//import './fonts/Noto_Sans/NotoSans-MediumItalic.ttf';
import './fonts/Noto_Sans/NotoSans-Regular.ttf';
import './fonts/Noto_Sans/NotoSans-SemiBold.ttf';
//import './fonts/Noto_Sans/NotoSans-SemiBoldItalic.ttf';
import './fonts/Noto_Sans/NotoSans-Thin.ttf';
//import './fonts/Noto_Sans/NotoSans-ThinItalic.ttf';
//Noto_Sans_JP
import './fonts/Noto_Sans_JP/NotoSansJP-Black.otf';
import './fonts/Noto_Sans_JP/NotoSansJP-Bold.otf';
import './fonts/Noto_Sans_JP/NotoSansJP-Light.otf';
import './fonts/Noto_Sans_JP/NotoSansJP-Medium.otf';
import './fonts/Noto_Sans_JP/NotoSansJP-Regular.otf';
import './fonts/Noto_Sans_JP/NotoSansJP-Thin.otf';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <LanguageContextProvider>
        <Provider store={store}>
            <App />
        </Provider>
    </LanguageContextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
