import '../../styles/IOIbasic.css';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useAppDispatch } from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';
import {
  cancelAdvert,
  modifyAdvert,
  pauseAdvert,
  resumeAdvert,
  submitAdvert,
} from '../../features/adverts/advertisementActions';

export const AdvertButtons = props => {

  const dispatch = useAppDispatch()
  const translation = useTranslation();

  const submitAdvertCall = (data) => {
    dispatch(submitAdvert(props.advert) as any)
  }

  const pauseAdvertCall = (data) => {
    dispatch(pauseAdvert(props.advert) as any)
  }

  const resumeAdvertCall = (data) => {
    dispatch(resumeAdvert(props.advert.id))
  }

  const cancelAdvertCall = (data) => {
    dispatch(cancelAdvert(props.advert) as any)
  }

  async function bulkActionSubmit () {
    for(let i = 0; i < props?.selectedSecurities.length; i++){
      if(props.selectedSecurities[i].advertisement.advertisementStatus.toUpperCase() === 'DEAD' || props.selectedSecurities[i].advertisement.advertisementStatus.toUpperCase() === 'UNSENT'){
        const submitResult = await dispatch(submitAdvert(props?.selectedSecurities[i]?.advertisement));
      }else{
        const submitResult = await dispatch(modifyAdvert(props?.selectedSecurities[i]?.advertisement));
      }
    }
    props?.setSelectedSecurities([]);
    const allSecurities = document.getElementsByName('securityCheck');
    for(let i = 0; i < allSecurities.length; i++) {
      (allSecurities[i] as HTMLInputElement).checked = false;
    }
    const masterCheckBox = document.getElementById('masterCheck');
    if((masterCheckBox as HTMLInputElement)?.checked){
      (masterCheckBox as HTMLInputElement).checked = false;
    }
  }

  async function bulkActionCancel () {
    for(let i = 0; i < props?.selectedSecurities.length; i++){
      if(props.selectedSecurities[i].advertisement.advertisementStatus.toUpperCase() !== 'DEAD'){
        const submitResult = await dispatch(cancelAdvert(props?.selectedSecurities[i]?.advertisement));
      }
    }
    props?.setSelectedSecurities([]);
    const allSecurities = document.getElementsByName('securityCheck');
    for(let i = 0; i < allSecurities.length; i++) {
      (allSecurities[i] as HTMLInputElement).checked = false;
    }
    const masterCheckBox = document.getElementById('masterCheck');
    if((masterCheckBox as HTMLInputElement)?.checked){
      (masterCheckBox as HTMLInputElement).checked = false;
    }
  }

  async function bulkActionPause () {
    for(let i = 0; i < props?.selectedSecurities.length; i++){
      if(props.selectedSecurities[i].advertisement.advertisementStatus.toUpperCase() === 'LIVE' || props.selectedSecurities[i].advertisement.advertisementStatus.toUpperCase() === 'SENT'){
        const submitResult = await dispatch(pauseAdvert(props?.selectedSecurities[i]?.advertisement));
      }
    }
    props?.setSelectedSecurities([]);
    const allSecurities = document.getElementsByName('securityCheck');
    for(let i = 0; i < allSecurities.length; i++) {
      (allSecurities[i] as HTMLInputElement).checked = false;
    }
    const masterCheckBox = document.getElementById('masterCheck');
    if((masterCheckBox as HTMLInputElement)?.checked){
      (masterCheckBox as HTMLInputElement).checked = false;
    }
  }

  async function bulkActionResume () {
    for(let i = 0; i < props?.selectedSecurities.length; i++){
      if(props.selectedSecurities[i].advertisement.advertisementStatus.toUpperCase() === 'PAUSED'){
        dispatch(resumeAdvert(props?.selectedSecurities[i]?.advertisement?.id));
      }
    }
    props?.setSelectedSecurities([]);
    const allSecurities = document.getElementsByName('securityCheck');
    for(let i = 0; i < allSecurities.length; i++) {
      (allSecurities[i] as HTMLInputElement).checked = false;
    }
    const masterCheckBox = document.getElementById('masterCheck');
    if((masterCheckBox as HTMLInputElement)?.checked){
      (masterCheckBox as HTMLInputElement).checked = false;
    }
  }

  return (
    <div className='AdvertButtons prevent-select'>
        {props?.container === 'history' ?
        <button className='cancel-button'
        disabled={!(props?.selectedSecurities.length > 0 && (props?.selectedSecurities.findIndex((selected) => selected?.advertisement?.advertisementStatus !== 'DEAD') !== -1))}
        onClick={() => {bulkActionCancel()}}>
          {translation.cancel}
        </button>
        :
        <button className='cancel-button'
          disabled={props.advert?.advertisementStatus === undefined || ['Located'?.toUpperCase(), 'Accept/Decline?'?.toUpperCase(), 'Dead'?.toUpperCase(), 'Completed'?.toUpperCase()].indexOf(props.advert?.advertisementStatus?.toUpperCase()) !== -1}
          onClick={cancelAdvertCall}>
            {translation.cancel}
        </button>}
        {props?.container === 'history' ?
        <button className='pause-button'
        disabled={!(props?.selectedSecurities.length > 0 && (props?.selectedSecurities.findIndex((selected) => selected?.advertisement?.advertisementStatus !== 'DEAD') !== -1))}
        onClick={() => {props?.selectedSecurities.findIndex((selected) => (selected?.advertisement?.advertisementStatus !== 'PAUSED' && selected?.advertisement?.advertisementStatus !== 'DEAD' )) !== -1 ? bulkActionPause() : bulkActionResume()}}>
          {props?.selectedSecurities.findIndex((selected) => (selected?.advertisement?.advertisementStatus !== 'PAUSED' && selected?.advertisement?.advertisementStatus !== 'DEAD')) !== -1 ? translation.pause : translation.resume}
        </button>
        :
        <button className='pause-button'
          disabled={props.advert?.advertisementStatus === undefined || ['Sent'?.toUpperCase(), 'Live'?.toUpperCase(), 'Paused'?.toUpperCase()].indexOf(props.advert?.advertisementStatus?.toUpperCase()) === -1}
          onClick={( props.advert?.advertisementStatus?.toUpperCase() === 'Paused'?.toUpperCase() ? resumeAdvertCall : pauseAdvertCall )}>
            {props.advert?.advertisementStatus?.toUpperCase() === 'Paused'?.toUpperCase() ? translation.resume : translation.pause}
        </button>}
        {props?.container === 'history' ?
        <button className='send-button'
        disabled={!(props?.selectedSecurities.length > 0)}
        onClick={() => {bulkActionSubmit()}}>
          {translation.submit}
        </button>
        :
        <button tabIndex={3} className='send-button'
          disabled={props.advert?.advertisementStatus === undefined || ['Located'?.toUpperCase(), 'Accept/Decline?'?.toUpperCase(), 'Completed'?.toUpperCase()].indexOf(props.advert?.advertisementStatus?.toUpperCase()) !== -1}
          onClick={() => {props?.container !== 'history' ? props.setReview(true) : bulkActionSubmit()}}>
            {(props.advert?.advertisementStatus?.toUpperCase() === 'Unsent'?.toUpperCase() || props.advert?.advertisementStatus?.toUpperCase() === 'Dead'?.toUpperCase()) ? translation.send: translation.update}
        </button>}
    </div>
  )
}

AdvertButtons.propTypes = {
  setReview: PropTypes.func.isRequired,
  container: PropTypes.string.isRequired,
  selectedSecurities: PropTypes.array.isRequired,
  setSelectedSecurities: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  // Map the relevant state from the Redux store to props
  return {
    advert: state.advertisements.selectedAdvertisement,
  };
};

export default connect(mapStateToProps)(AdvertButtons);