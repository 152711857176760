import Error from '../components/Error'
import Spinner from '../components/Spinner'
import '../styles/aboutUs.css'
import useTranslation from "../components/customHooks/Translations";
import { isMobile } from 'react-device-detect'

const AboutUsScreen = props => {
  const translation = useTranslation();
  if(isMobile){
    return(
      <div>
        <div className='mobile-about-us' style={props?.mobileMenu ? {boxShadow: '3000px 3000px rgba(0,0,0,.70) inset', filter: 'brightness(0.3)'} : null}>
        <div className='mobile-about-us-header'>{translation.aboutUs}</div>
        <div className='mobile-about-us-subtext'>{translation.aboutUsText}</div>
        <div className='mobile-footer-watermark'>© 2023 Authentic Indication.</div>
      </div>
      </div>
    )
  }else{
    return (
        <div>
        <div id="Seigaiha_Accent_gn">
          <img id="Group_949_go" src="Group_949_go.png" srcSet="Group_949_go.png 1x, Group_949_go@2x.png 2x">

          </img>
        </div>
        <div id="about-us-text">
          <p>{translation.aboutUsText}</p>
        </div>

        </div>
    )
  }
}

export default AboutUsScreen