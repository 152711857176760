import { useEffect } from 'react'
import react from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from "../../components/customHooks/Translations";
import '../../styles/stockSelector.css'

export const BulkUploadButton = props => {

  const translation = useTranslation();

  return (
    <div className={'bulk-upload prevent-select '+props.container} onClick={() => {if (!props?.restore) props?.setUpload(true)}}>
      <img id="Group_32" src="Group_32.png" srcSet="Group_32.png 1x, Group_32@2x.png 2x"></img>
      <div className='bulk-upload-text' style={translation.language === 'Jp' ? {fontSize: 'calc(11*var(--responsiveFont))'} : {}}>{translation.bulkUpload}</div>
    </div>
  )
}

export default BulkUploadButton