import '../styles/forms.css';
import '../styles/buttons.css';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  AdvertisementDuration,
  TransactionType,
} from '../app/model/enums';
import { RootState } from '../app/store';
import {
  ConditionsPreferences as ConditionsPreferencesType,
} from '../features/traderPreferences/traderPreferencesSlice';
import useTranslation from './customHooks/Translations';
import useDragDropBrokers from './customHooks/useDragDropBrokers';

type ConditionsPreferencesEditProps = {
  prefs: ConditionsPreferencesType;
  credibilityRating?: number;
  loading?: Boolean,
  error?: string,
  onSubmit?: (prefs: ConditionsPreferencesType) => void;
}

function getTransactionTypeLabel(
  transactionType: TransactionType,
  translation: ReturnType<typeof useTranslation>,
) {
  switch (transactionType) {
    case TransactionType.CASH_ONLY:
      return translation.transactionTypeCASH_ONLY;
    case TransactionType.CASH_SWAP:
      return translation.transactionTypeCASH_SWAP;
    case TransactionType.SWAP_ONLY:
      return translation.transactionTypeSWAP_ONLY;
  }
}

function getRangeValueLeft(val: number) {
  const min = 10;
  const max = 75;
  const newVal = Number(((val - min) * 100) / (max - min));
  return `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
}

function ConditionsPreferencesEdit({
  prefs,
  credibilityRating,
  loading,
  error,
  onSubmit,
}: ConditionsPreferencesEditProps): JSX.Element {
  const translation = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  }  = useForm<ConditionsPreferencesType>({
    reValidateMode: 'onChange',
    values: prefs,
  });

  const brokersList = useSelector((state: RootState) => state.brokers.brokers);

  const requiredMessage = translation.requiredField;

  register('advertPreferences.credibilityHurdle', {
    required: {
      value: true,
      message: requiredMessage,
    },
    min: {
      value: 10,
      message: translation.formatString(translation.minValue, {
        min: 10,
      }) as string,
    },
  });
  register('advertPreferences.duration', {
    required: {
      value: true,
      message: requiredMessage,
    },
  });
  register('advertPreferences.minQuantity', {
    required: {
      value: true,
      message: requiredMessage,
    },
    min: {
      value: 10,
      message: translation.formatString(translation.minValue, {
        min: 10,
      }) as string,
    },
    max: {
      value: 75,
      message: translation.formatString(translation.maxValue, {
        max: 75,
      }) as string,
    }
  });
  register('advertPreferences.quantityDecay');
  register('advertPreferences.transactionType', {
    required: {
      value: true,
      message: requiredMessage,
    },
  });
  register('advertPreferences.contraTransactionType', {
    required: {
      value: true,
      message: requiredMessage,
    },
  });
  register('pathPreferences.brokers', {
    required: {
      value: true,
      message: requiredMessage,
    },
  });
  register('priceConditions.advertTriggerStatus');
  register('priceConditions.indexRelativeStatus');
  register('priceConditions.knockoutTriggerStatus');

  const formCredibilityHurdle = watch('advertPreferences.credibilityHurdle');
  const formDuration = watch('advertPreferences.duration');
  const formQuantityDecay = watch('advertPreferences.quantityDecay');
  const formMinimumQ = watch('advertPreferences.minQuantity');
  const formTransactionType = watch('advertPreferences.transactionType');
  const formContraTransactionType = watch('advertPreferences.contraTransactionType');
  const formRanked = watch('pathPreferences.ranked');
  const formRankOrder = watch('pathPreferences.brokers');
  const formAdvertTriggerStatus = watch('priceConditions.advertTriggerStatus');
  const formIndexRelativeStatus = watch('priceConditions.indexRelativeStatus');
  const formKnockoutTriggerStatus = watch('priceConditions.knockoutTriggerStatus');

  const formCredibilityStars = Math.trunc((formCredibilityHurdle - 1) / 20) + 1


  const {
    dragStart,
    dragEnter,
    drop,
    addBroker,
    replaceBroker,
    removeBroker,
  } = useDragDropBrokers(
    brokersList,
    formRankOrder,
    (updatedRankOrder) => {
      setValue('pathPreferences.brokers', updatedRankOrder)
    },
  )

  return (
    <div
      className='preferences-conditions-content'
      style={{
        paddingBottom: '2rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
      }}
    >
      <div className='form-container'>
        <form
          className='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='form-field'>
            <label htmlFor="transactionType">{translation.transactionType}</label>
            <div className='transaction-type-menu prevent-select'>
              <div className='transaction-type-option-dropdown'>
                <span>
                  {getTransactionTypeLabel(formTransactionType, translation)}
                </span>
                <svg className='Path_26_f' viewBox="2.6 4.6 11 7">
                  <path id="Path_26_f" d="M 8.09999942779541 11.60000038146973 L 2.599999666213989 6.041176319122314 L 4.02592658996582 4.599999904632568 L 8.09999942779541 8.717647552490234 L 12.17407512664795 4.599999904632568 L 13.59999942779541 6.041176319122314 L 8.09999942779541 11.60000038146973 Z">
                  </path>
                </svg>
              </div>
              <div className='transaction-type-option-options'>
                {Object.values(TransactionType).map((t) => (
                  <span
                    key={t}
                    onClick={() => setValue('advertPreferences.transactionType', t)}
                  >
                    {getTransactionTypeLabel(t, translation)}
                  </span>
                ))}
              </div>
            </div>
            {errors.advertPreferences?.transactionType &&
              <p
                className='error'
                role="alert"
              >
                {errors.advertPreferences.transactionType.message}
              </p>
            }
          </div>
          <h4>{translation.priceConditions}</h4>
          <div className='form-field'>
            <label htmlFor="advertisingTrigger">{`${translation.advertisingTrigger} (%)`}</label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <div className={`quantity-decay-toggle-container ${(credibilityRating ?? 0) < 41 ? 'disabled' : ''}`}>
                <div
                  onClick={() => {
                    if (formAdvertTriggerStatus) {
                      // reset value
                      setValue('priceConditions.advertTriggerOffset', prefs.priceConditions.advertTriggerOffset);
                    }
                    setValue('priceConditions.advertTriggerStatus', !formAdvertTriggerStatus);
                  }}
                  className={formAdvertTriggerStatus
                    ? 'quantity-decay-option-toggle-active'
                    : 'quantity-decay-option-toggle'
                  }
                >
                  <div className='toggle-circle'></div>
                </div>
              </div>
              <input
                type='number'
                disabled={!formAdvertTriggerStatus}
                className={errors.priceConditions?.advertTriggerOffset && 'error'}
                {...register('priceConditions.advertTriggerOffset', {
                  valueAsNumber: true,
                  required: {
                    value: true,
                    message: requiredMessage,
                  },
                  min: {
                    value: 0,
                    message: translation.formatString(translation.minValue, {
                      min: 0,
                    }) as string,
                  },
                  max: {
                    value: 10,
                    message: translation.formatString(translation.maxValue, {
                      max: 10,
                    }) as string,
                  }
                })}
              />
            </div>
            {errors.priceConditions?.advertTriggerOffset &&
              <p
                className='error'
                role="alert"
              >
                {errors.priceConditions.advertTriggerOffset.message}
              </p>
            }
          </div>
          <div className='form-field'>
            <label htmlFor="cancelTrigger">{translation.cancelTrigger}</label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <div className='quantity-decay-toggle-container'>
                <div
                  onClick={() => {
                    if (formKnockoutTriggerStatus) {
                      // reset value
                      setValue('priceConditions.knockoutTriggerOffset', prefs.priceConditions.knockoutTriggerOffset);
                    }
                    setValue('priceConditions.knockoutTriggerStatus', !formKnockoutTriggerStatus);
                  }}
                  className={formKnockoutTriggerStatus
                    ? 'quantity-decay-option-toggle-active'
                    : 'quantity-decay-option-toggle'
                  }
                >
                  <div className='toggle-circle'></div>
                </div>
              </div>
              <input
                type='number'
                disabled={!formKnockoutTriggerStatus}
                className={errors.priceConditions?.knockoutTriggerOffset && 'error'}
                {...register('priceConditions.knockoutTriggerOffset', {
                  valueAsNumber: true,
                  required: {
                    value: true,
                    message: requiredMessage,
                  },
                  min: {
                    value: 0,
                    message: translation.formatString(translation.minValue, {
                      min: 0,
                    }) as string,
                  },
                  max: {
                    value: 10,
                    message: translation.formatString(translation.maxValue, {
                      max: 10,
                    }) as string,
                  }
                })}
              />
            </div>
            {errors.priceConditions?.knockoutTriggerOffset &&
              <p
                className='error'
                role="alert"
              >
                {errors.priceConditions.knockoutTriggerOffset.message}
              </p>
            }
          </div>
          <div className='form-field'>
            <label htmlFor="indexRelativeCancel">{translation.indexRelativeCancel}</label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <div className='quantity-decay-toggle-container'>
                <div
                  onClick={() => {
                    if (formIndexRelativeStatus) {
                      // reset value
                      setValue('priceConditions.indexRelativeOffset', prefs.priceConditions.indexRelativeOffset);
                    }
                    setValue('priceConditions.indexRelativeStatus', !formIndexRelativeStatus);
                  }}
                  className={formIndexRelativeStatus
                    ? 'quantity-decay-option-toggle-active'
                    : 'quantity-decay-option-toggle'
                  }
                >
                  <div className='toggle-circle'></div>
                </div>
              </div>
              <input
                type='number'
                disabled={!formIndexRelativeStatus}
                className={errors.priceConditions?.indexRelativeOffset && 'error'}
                {...register('priceConditions.indexRelativeOffset', {
                  valueAsNumber: true,
                  required: {
                    value: true,
                    message: requiredMessage,
                  },
                  min: {
                    value: 3,
                    message: translation.formatString(translation.minValue, {
                      min: 3,
                    }) as string,
                  },
                  max: {
                    value: 7,
                    message: translation.formatString(translation.maxValue, {
                      max: 7,
                    }) as string,
                  }
                })}
              />
            </div>
            {errors.priceConditions?.indexRelativeOffset &&
              <p
                className='error'
                role="alert"
              >
                {errors.priceConditions.indexRelativeOffset.message}
              </p>
            }
          </div>
          <h4>{translation.advertisingPreferences}</h4>
          <div className='form-field'>
            <label htmlFor="credibilityHurdle">{translation.credibilityFilter}</label>
            <div className='credibility-filter-value'>
              {Array.apply(null, { length: formCredibilityStars}).map((_: null, i: number) => (
                <img
                  key={'credibilityFilter' + i}
                  alt={'credibilityFilter' + i}
                  onClick={() => {
                    let newVal = i * 20 + 1;
                    if (newVal < 10) {
                      newVal = 10;
                    }
                    return setValue('advertPreferences.credibilityHurdle', newVal);
                  }}
                  id="Group_11"
                  src="/Group_11.png"
                  srcSet="/Group_11.png 1x, /Group_11@2x.png 2x"
                />
              ))}
              {Array.apply(null, { length: 5 - formCredibilityStars }).map((_: null, i: number) => {
                const val = formCredibilityHurdle + ((i + 1) * 20);
                return (
                  <svg
                    key={'credibilityFilter' + val}
                    onClick={() => setValue('advertPreferences.credibilityHurdle', val)}
                    className="Path_315_xl"
                    viewBox="1.075 1.13 23.277 22.138"
                  >
                    <path id="Path_315_xl" d="M 12.71335887908936 1.12969970703125 L 16.30998229980469 8.417112350463867 L 24.35202026367188 9.585659027099609 L 18.53284645080566 15.2583179473877 L 19.9066047668457 23.26806831359863 L 12.71335887908936 19.48629760742188 L 5.520115375518799 23.26806831359863 L 6.89387321472168 15.2583179473877 L 1.074699401855469 9.585659027099609 L 9.116737365722656 8.417112350463867 L 12.71335887908936 1.12969970703125 Z">
                    </path>
                  </svg>
                );
              })}
            </div>
            {errors.advertPreferences?.credibilityHurdle &&
              <p
                className='error'
                role="alert"
              >
                {errors.advertPreferences.credibilityHurdle.message}
              </p>
            }
          </div>
          <div className='form-field'>
            <label htmlFor="duration">{translation.duration}</label>
            <div className='duration-menu prevent-select'>
              <div className='duration-option-dropdown'>
                <span
                  style={{
                    width: '75%',
                    textAlign: 'left'
                  }}
                >
                  {formDuration === AdvertisementDuration.END_OF_DAY
                    ? translation.goodForTheDay
                    : formDuration + ' ' + translation.minutes
                  }
                </span>
                <svg className='Path_26_f' viewBox="2.6 4.6 11 7">
                  <path id="Path_26_f" d="M 8.09999942779541 11.60000038146973 L 2.599999666213989 6.041176319122314 L 4.02592658996582 4.599999904632568 L 8.09999942779541 8.717647552490234 L 12.17407512664795 4.599999904632568 L 13.59999942779541 6.041176319122314 L 8.09999942779541 11.60000038146973 Z">
                  </path>
                </svg>
              </div>
              <div className='transaction-type-option-options'>
                {Object.values(AdvertisementDuration).map((d) => (
                  <span
                    key={d}
                    onClick={() => setValue('advertPreferences.duration', d)}
                  >
                    {d === AdvertisementDuration.END_OF_DAY
                      ? translation.goodForTheDay
                      : d + ' ' + translation.minutes
                    }
                  </span>
                ))}
              </div>
            </div>
            {errors.advertPreferences?.duration &&
              <p
                className='error'
                role="alert"
              >
                {errors.advertPreferences.duration.message}
              </p>
            }
          </div>
          <div className='form-field'>
            <label htmlFor="minimumQ">{translation.minimumQ}</label>
            <div className='minimum-q-slider'>
              <input
                style={{
                  background: 'linear-gradient(to right,#0D1265 0%,#0D1265 '+ formMinimumQ.toFixed(0)+'%,#D6D6D6 100%)'
                }}
                type="range"
                onChange={(e) => { setValue('advertPreferences.minQuantity', parseInt(e.target.value)) }}
                name="minimumQ"
                min="10"
                max="75"
                step={1}
                value={(formMinimumQ).toFixed(0)}
                className="minimum-q-slider-input"
              />
              <output
                className="minimum-q-slider-input-hover-value"
                style={{
                  left: getRangeValueLeft(formMinimumQ)
                }}
              >
                {`${(formMinimumQ).toFixed(0)}%`}
              </output>
            </div>
            {errors.advertPreferences?.minQuantity &&
              <p
                className='error'
                role="alert"
              >
                {errors.advertPreferences.minQuantity.message}
              </p>
            }
          </div>
          <div className='form-field'>
            <label htmlFor="quantityDecay">{translation.quantityDecay}</label>
            <div className='quantity-decay-toggle-container'>
              <div
                onClick={() => setValue('advertPreferences.quantityDecay', !formQuantityDecay)}
                className={formQuantityDecay
                  ? 'quantity-decay-option-toggle-active'
                  : 'quantity-decay-option-toggle'
                }
              >
                <div className='toggle-circle'></div>
              </div>
            </div>
            {errors.advertPreferences?.quantityDecay &&
              <p
                className='error'
                role="alert"
              >
                {errors.advertPreferences.quantityDecay.message}
              </p>
            }
          </div>
          <div className='form-field'>
            <label htmlFor="contraTransactionType">{translation.contraTransactionType}</label>
            <div className='transaction-type-menu prevent-select'>
              <div className='transaction-type-option-dropdown'>
                <span>
                  {getTransactionTypeLabel(formContraTransactionType, translation)}
                </span>
                <svg className='Path_26_f' viewBox="2.6 4.6 11 7">
                  <path id="Path_26_f" d="M 8.09999942779541 11.60000038146973 L 2.599999666213989 6.041176319122314 L 4.02592658996582 4.599999904632568 L 8.09999942779541 8.717647552490234 L 12.17407512664795 4.599999904632568 L 13.59999942779541 6.041176319122314 L 8.09999942779541 11.60000038146973 Z">
                  </path>
                </svg>
              </div>
              <div className='transaction-type-option-options'>
                {Object.values(TransactionType).map((t) => (
                  <span
                    key={t}
                    onClick={() => setValue('advertPreferences.contraTransactionType', t)}
                  >
                    {getTransactionTypeLabel(t, translation)}
                  </span>
                ))}
              </div>
            </div>
            {errors.advertPreferences?.contraTransactionType &&
              <p
                className='error'
                role="alert"
              >
                {errors.advertPreferences.contraTransactionType.message}
              </p>}
          </div>
          <h4>{translation.pathPreferences}</h4>
          <div className='form-field'>
            <label htmlFor="ranked">{translation.rankedEqual}</label>
            <div className='rank-options'>
              <label className='rank-option'>
                {translation.ranked}
                <input
                  type='radio'
                  name="rank-option-select"
                  checked={!!formRanked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue('pathPreferences.ranked', true);
                    }
                  }}
                />
                <span className='radioCheck'></span>
              </label>
              <label className='rank-option'>
                {translation.equalRank}
                <input
                  type='radio'
                  name="rank-option-select"
                  checked={!formRanked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue('pathPreferences.ranked', false);
                    }
                  }}
                />
                <span className='radioCheck'></span>
              </label>
            </div>
            {errors.pathPreferences?.ranked &&
              <p
                className='error'
                role="alert"
              >
                {errors.pathPreferences.ranked.message}
              </p>
            }
          </div>
          <div className='form-field'>
            <label htmlFor="pathOrder">{translation.brokers}</label>
            <div className='broker-option-drag-drop-container'>
              {formRankOrder.length !== 0
                ? formRankOrder.map(({ id }, index) => (
                    <div
                      key={`broker-rank-${id}`}
                      className='broker-rank'
                    >
                      <div className='broker-rank-label'>
                        <span>
                          {formRanked ? `${translation.rank} ${index + 1}` : ""}
                        </span>
                      </div>
                      <div
                        className='broker-option-drag-drop'
                        draggable
                        onDragStart={(e) => dragStart(e, index)}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragEnd={drop}
                      >
                        <div className='draggable-icon-container'>
                          <div className='draggable-icon-row'>
                            <div></div>
                            <div></div>
                          </div>
                          <div className='draggable-icon-row'>
                            <div></div>
                            <div></div>
                          </div>
                          <div className='draggable-icon-row'>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                        <span className='broker-option-text'>
                          {brokersList.find(broker => broker.brokerID === id)?.shortName}
                        </span>
                        <svg className='Path_26_f' viewBox="2.6 4.6 11 7">
                          <path id="Path_26_f" d="M 8.09999942779541 11.60000038146973 L 2.599999666213989 6.041176319122314 L 4.02592658996582 4.599999904632568 L 8.09999942779541 8.717647552490234 L 12.17407512664795 4.599999904632568 L 13.59999942779541 6.041176319122314 L 8.09999942779541 11.60000038146973 Z">
                          </path>
                        </svg>
                      </div>
                      <div className='broker-option-dropdown-menu'>
                        {brokersList.map((brokerInList, j) => (
                          <span
                            key={`broker-option-${j}`}
                            onClick={() => replaceBroker(index, j)}
                          >
                            {`${brokerInList.brokerName} | ${brokerInList.shortName}`}
                          </span>
                        ))}
                        <span onClick={() => removeBroker(index)}>
                          - {translation.none} -
                        </span>
                      </div>
                    </div>
                    )
                  )
                : null
              }
              {Array.apply(null, { length: 4 - formRankOrder.length}).map((_: null, i: number) => (
                <div
                  key={`brokerEmpty-${i + formRankOrder.length}`}
                  className='broker-rank'
                >
                  <div className='broker-rank-label'>
                    <span>
                      {formRanked ? `${translation.rank} ${i + 1 + formRankOrder.length}` : ""}
                    </span>
                  </div>
                  <div
                    className='broker-option-drag-drop no-broker'
                    style={{opacity: 0.4}}
                  >
                    <div className='draggable-icon-container'>
                      <div className='draggable-icon-row'>
                        <div></div>
                        <div></div>
                      </div>
                      <div className='draggable-icon-row'>
                        <div></div>
                        <div></div>
                      </div>
                      <div className='draggable-icon-row'>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <span className='broker-option-text'>
                      {translation.noneSelected}
                    </span>
                    <svg className='Path_26_f' viewBox="2.6 4.6 11 7">
                      <path id="Path_26_f" d="M 8.09999942779541 11.60000038146973 L 2.599999666213989 6.041176319122314 L 4.02592658996582 4.599999904632568 L 8.09999942779541 8.717647552490234 L 12.17407512664795 4.599999904632568 L 13.59999942779541 6.041176319122314 L 8.09999942779541 11.60000038146973 Z">
                      </path>
                    </svg>
                  </div>
                  <div className='broker-option-dropdown-menu'>
                    {brokersList.map((brokerInList, j) => (
                      <span
                        key={`emptyBrokerOptions-${j}`}
                        onClick={() => addBroker(j)}
                      >
                        {`${brokerInList.brokerName} | ${brokerInList.shortName}`}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {errors.pathPreferences?.brokers &&
              <p
                className='error'
                role="alert"
              >
                {errors.pathPreferences.brokers.message}
              </p>
            }
          </div>
          <div>
            <input
              className='btn'
              type="submit"
              style={{
                marginTop: '1rem',
              }}
              disabled={!!loading}
              value={translation.submit}
            />
          </div>
          {error && <p className='error'>{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default ConditionsPreferencesEdit;
