import '../../styles/forms.css';
import '../../styles/profile-ioi-counts.css';

import useTranslation from '../../components/customHooks/Translations';
import { useGetIOICountsQuery } from '../../features/ioiCounts';

type CountRowProps = {
  label: string;
  count: number;
}

function CountRow({
  label,
  count,
}: CountRowProps): JSX.Element {
  return (
    <div className='form-field'>
      <label>{label}</label>
      <span>{count}</span>
    </div>
  )
}

function IOICountsScreen(): JSX.Element {
  const { data } = useGetIOICountsQuery(null, {
    pollingInterval: 5000,
  });
  const translation = useTranslation();

  return (
    <div className="profile-ioi-counts-container">
      <div className='form horizontal'>
        <CountRow
          label={translation.uploads}
          count={data?.upload ?? 0}
        />
        <CountRow
          label={translation.cancels}
          count={data?.cancel ?? 0}
        />
        <CountRow
          label={translation.locates}
          count={data?.locate ?? 0}
        />
        <CountRow
          label={translation.accepts}
          count={data?.accept ?? 0}
        />
        <CountRow
          label={translation.declines}
          count={data?.decline ?? 0}
        />
        <CountRow
          label={translation.timeouts}
          count={data?.timeout ?? 0}
        />
        <CountRow
          label={translation.triggerCancels}
          count={data?.triggerCancel ?? 0}
        />
      </div>
    </div>
  )
}

export default IOICountsScreen;
