import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store';
import { fetchAdvertisements, selectAdvertisement, setAdvertisementSortProperty} from '../../features/adverts/advertisementSlices';
import { Advertisement } from '../../app/model/model';
import '../../styles/IOIbasic.css'
import PropTypes from 'prop-types';
import useTranslation from '../customHooks/Translations';
import { NumericFormat } from 'react-number-format';

const IOIListingsComponent = props => {
  const dispatch = useDispatch();
  const translation = useTranslation();
  const advertisements = useSelector((state: RootState) => state.advertisements.advertisements);
  const isLoading = useSelector((state: RootState) => state.advertisements.isLoading);
  const selectedAdvertisement = useSelector((state: RootState) => state.advertisements.selectedAdvertisement)
  const equitiesList = useSelector((state: RootState) => state.equities);

  useEffect(() => {
    dispatch(fetchAdvertisements());
  }, [dispatch]);

  useEffect(() => {
    if(props?.container !== 'history'){
      props?.setSelectedSecurities([])
    }else{
      const newIntervalId = setInterval(() => {
        dispatch(fetchAdvertisements());
      }, 10000);
      return () => {
        clearInterval(newIntervalId);
      };
    }
  }, [props?.container])

  const handleAdvertisementClick = (advertisement: Advertisement) => {
    console.log("Click triggered :" + advertisement.id);
    dispatch(selectAdvertisement(advertisement));
  };

  let emptyIoIs = 0;
  if(advertisements.length < 8){
    emptyIoIs = 8-advertisements.length;
  }

  function getSecurityName (securityId) {
    const matchingEquity = equitiesList.equities.find(equity => equity.equityId === securityId)
    return (matchingEquity.ric + ' - ' + matchingEquity.name) || '';
  }

  function updateSelected() {
    const allSecurities = document.getElementsByName('securityCheck');
    const newSelectedSecurities = [];
    for(let i = 0; i < allSecurities.length; i++){
        if((allSecurities[i] as HTMLInputElement).checked){
          const selectedAd = [];
          selectedAd['ric'] = equitiesList.equities.find(equity => equity.equityId === allSecurities[i].id)?.ric;
          selectedAd['advertisement'] = advertisements.find(advert => advert.id === allSecurities[i].id);

          newSelectedSecurities.push(selectedAd);
        }
    }
    props?.setSelectedSecurities(newSelectedSecurities);
  }

  function updateAll() {
    const masterCheckStatus = (document.getElementById('masterCheck') as HTMLInputElement).checked;
    const allSecurities = document.getElementsByName('securityCheck');
    const newSelectedSecurities = [];
    for(let i = 0; i < allSecurities.length; i++){
        if(masterCheckStatus){
          if (['accept/decline?', 'completed', 'located'].indexOf(advertisements.find(advert => advert.securityId === allSecurities[i].id)?.advertisementStatus?.toLowerCase()) === -1) {
            (allSecurities[i] as HTMLInputElement).checked = true;
            const selectedAd = [];
            selectedAd['ric'] = equitiesList.equities.find(equity => equity.equityId === allSecurities[i].id)?.ric;
            selectedAd['advertisement'] = advertisements.find(advert => advert.id === allSecurities[i].id);

            newSelectedSecurities.push(selectedAd);
          }
        }else{
          (allSecurities[i] as HTMLInputElement).checked = false;
        }
    }
    props?.setSelectedSecurities(newSelectedSecurities);
  }

  function setStatusText(status, causeOfDeath) {
    if(status){
      if (status === 'sent' || status == 'live') {
        return(translation.statusSENT);
      } else if (status === 'paused') {
        return(translation.statusPAUSED);
      } else if (status === 'unsent') {
        return(translation.statusUNSENT);
      } else if (status === 'cancelled' || status === 'dead') {
        if(causeOfDeath === 'expired'){
          return(translation.statusEXPIRED);
        }
        return(translation.statusCANCELLED);
      } else if (status === 'completed' || status === 'complete') {
        return(translation.statusCOMPLETE);
      } else if (status === 'accept/decline?' || status === 'located') {
        return(translation.statusLOCATED);
      }else {
        return(translation.statusUndefined);
      }
    }
  }

  return (
    <div>{props.container !== 'history' ? 
      <div className='IOI-listings-window positionTransition' style={props.container === "advanced" ? {top: "16.643%"} : {}}>
        {isLoading && <p>Loading...</p>}
        {advertisements?.map(IOI => (
          <div className='userIOI' key={IOI.id} onClick={() => handleAdvertisementClick(IOI)}>
          <div className={'userIOI-status-'+IOI?.advertisementStatus?.toUpperCase()}></div>
          <div className={'userIOI-security'}><span className={selectedAdvertisement != null && IOI.id === selectedAdvertisement.id ? 'userIOI-security-text userIOI-security-text-bold' : 'userIOI-security-text'}>{getSecurityName(IOI.securityId)}</span></div>
          <div className={selectedAdvertisement != null && IOI.id === selectedAdvertisement.id ? 'userIOI-security-text userIOI-security-text-bold userIOI-security-text-direction userIOI-security-text-direction-' + IOI.direction : 'userIOI-security-text userIOI-security-text-direction userIOI-security-text-direction-' + IOI.direction}>
            {IOI.direction === 'BUY' ? translation.buyList : translation.sellList}
          </div>
        </div>
      ))}
      {Array.apply(null, { length: emptyIoIs }).map((e, i) => (
        <div className="emptyIOI" key={i}>
          <div className={'userIOI-status-empty'}></div>
        </div>
      ))}
      </div>
    : 
      <div className='IOI-history-table positionTransition'>
          <div className='IOI-history-table-header'>
          <div className='IOI-entry-index-header'>#</div>
          <div className='IOI-entry-security-header'>{translation.security}
            <div className='up-down-sort-container'>
              <div className='triangle-container sort-direction' onClick={() => {dispatch(setAdvertisementSortProperty(1, 'securityName'))}}><div className='up-triangle'></div></div>
              <div className='triangle-container' onClick={() => {dispatch(setAdvertisementSortProperty(-1, 'securityName'))}}><div className='down-triangle'></div></div>
            </div>
          </div>
          {advertisements.findIndex((advert) => ['accept/decline?', 'completed', 'located'].indexOf(advert?.advertisementStatus?.toLowerCase()) === -1) !== -1 ? 
            <div className='IOI-entry-security-checkbox-header-active'>
              <input type={'checkbox'} name='masterCheck' id='masterCheck' onChange={updateAll}/>
              <span className="checkmark"></span>
            </div>
          : 
            <div className='IOI-entry-security-checkbox-header'></div>
          }
          <div className='IOI-entry-direction-header'>{translation.buy}/{translation.sell} 
            <div className='up-down-sort-container'>
              <div className='triangle-container sort-direction' onClick={() => {dispatch(setAdvertisementSortProperty(1, 'direction'))}}><div className='up-triangle'></div></div>
              <div className='triangle-container' onClick={() => {dispatch(setAdvertisementSortProperty(-1, 'direction'))}}><div className='down-triangle'></div></div>
            </div>
          </div>
          <div className='IOI-entry-quantity-header'>{translation.quantity}
            <div className='up-down-sort-container'>
              <div className='triangle-container sort-direction' onClick={() => {dispatch(setAdvertisementSortProperty(-1, 'shareQuantity'))}}><div className='up-triangle'></div></div>
              <div className='triangle-container' onClick={() => {dispatch(setAdvertisementSortProperty(1, 'shareQuantity'))}}><div className='down-triangle'></div></div>
            </div>
          </div>
          <div className='IOI-entry-type-header'>{translation.type}
            <div className='up-down-sort-container'>
              <div className='triangle-container sort-direction' onClick={() => {dispatch(setAdvertisementSortProperty(1, 'transactionType'))}}><div className='up-triangle'></div></div>
              <div className='triangle-container' onClick={() => {dispatch(setAdvertisementSortProperty(-1, 'transactionType'))}}><div className='down-triangle'></div></div>
            </div>
          </div>
          <div className='IOI-entry-usd-header'>{translation.usdNotional}
            <div className='up-down-sort-container'>
              <div className='triangle-container sort-direction' onClick={() => {dispatch(setAdvertisementSortProperty(-1, 'usdnotional'))}}><div className='up-triangle'></div></div>
              <div className='triangle-container' onClick={() => {dispatch(setAdvertisementSortProperty(1, 'usdnotional'))}}><div className='down-triangle'></div></div>
            </div>
          </div>
          <div className='IOI-entry-jpy-header'>{translation.jpyNotional}
            <div className='up-down-sort-container'>
              <div className='triangle-container sort-direction' onClick={() => {dispatch(setAdvertisementSortProperty(-1, 'jpynotional'))}}><div className='up-triangle'></div></div>
              <div className='triangle-container' onClick={() => {dispatch(setAdvertisementSortProperty(1, 'jpynotional'))}}><div className='down-triangle'></div></div>
            </div>
          </div>
          <div className='IOI-entry-status-column-header'>{translation.status}
            <div className='up-down-sort-container'>
              <div className='triangle-container sort-direction' onClick={() => {dispatch(setAdvertisementSortProperty(1, 'advertisementStatus'))}}><div className='up-triangle'></div></div>
              <div className='triangle-container' onClick={() => {dispatch(setAdvertisementSortProperty(-1, 'advertisementStatus'))}}><div className='down-triangle'></div></div>
            </div>
          </div>
          <div className='IOI-entry-time-remaining-header'>{translation.timeRemaining}
            <div className='up-down-sort-container'>
              <div className='triangle-container sort-direction' onClick={() => {dispatch(setAdvertisementSortProperty(-1, 'timeRemaining'))}}><div className='up-triangle'></div></div>
              <div className='triangle-container' onClick={() => {dispatch(setAdvertisementSortProperty(1, 'timeRemaining'))}}><div className='down-triangle'></div></div>
            </div>
          </div>
          <div className='IOI-entry-quantity-remaining-header'>{translation.quantityRemaining}
            <div className='up-down-sort-container'>
              <div className='triangle-container sort-direction' onClick={() => {dispatch(setAdvertisementSortProperty(-1, 'currentQuantity'))}}><div className='up-triangle'></div></div>
              <div className='triangle-container' onClick={() => {dispatch(setAdvertisementSortProperty(1, 'currentQuantity'))}}><div className='down-triangle'></div></div>
            </div>
          </div>
          <div className='IOI-entry-percent-remaining-header'>{translation.percentRemaining}
            <div className='up-down-sort-container'>
              <div className='triangle-container sort-direction' onClick={() => {dispatch(setAdvertisementSortProperty(-1, 'percentRemaining'))}}><div className='up-triangle'></div></div>
              <div className='triangle-container' onClick={() => {dispatch(setAdvertisementSortProperty(1, 'percentRemaining'))}}><div className='down-triangle'></div></div>
            </div>
          </div>
          </div>
          <div className='IOI-history-table-entries'>
          {advertisements.map((advert,index) => (
              <div className="IOI-history-entry" key={index}>
                  <div className='IOI-entry-index'>{index+1}</div>
                  <div className={'IOI-entry-status-bar '+advert.advertisementStatus.toLowerCase()}></div>
                  <div className='IOI-entry-security'>{getSecurityName(advert.securityId)}</div>
                  {['accept/decline?', 'completed', 'located'].findIndex(x => x === advert.advertisementStatus?.toLowerCase()) !== -1 ? 
                    <div className='IOI-entry-security-checkbox-empty'></div> 
                  :
                    <div className='IOI-entry-security-checkbox'>
                      <input type={'checkbox'} name='securityCheck' onChange={updateSelected} id={advert.id}/>
                      <span className="checkmark"></span>
                    </div>
                  }
                  <div className={'IOI-entry-direction-'+ advert.direction.toLowerCase()}>{advert.direction === 'SELL' ? translation.sell:translation.buy}</div>
                  <div className='IOI-entry-quantity'><NumericFormat displayType='text' value={advert.shareQuantity} thousandSeparator=',' decimalScale={2}></NumericFormat></div>
                  <div className='IOI-entry-type'>{advert.transactionType === 'CASH_ONLY' ? translation.transactionTypeCASH_ONLY : advert.transactionType === 'SWAP_ONLY' ? translation.transactionTypeSWAP_ONLY : translation.transactionTypeCASH_SWAP}</div>
                  <div className='IOI-entry-usd'><NumericFormat displayType='text' value={advert.usdnotional} thousandSeparator=',' decimalScale={2}></NumericFormat></div>
                  <div className='IOI-entry-jpy'><NumericFormat displayType='text' value={advert.jpynotional} thousandSeparator=',' decimalScale={0}></NumericFormat></div>
                  <div className='IOI-entry-status-column'>
                      <div className={'IOI-entry-status-div ' + advert.advertisementStatus.toLowerCase()}>{setStatusText(advert.advertisementStatus?.toLowerCase(), advert?.causeOfDeath?.toLowerCase())}</div>
                  </div>
                  <div className='IOI-entry-time-remaining'>{['accept/decline?', 'dead', 'completed'].findIndex(x => x === advert.advertisementStatus?.toLowerCase()) !== -1 ? '-' : advert?.timeRemaining}</div>
                  <div className='IOI-entry-quantity-remaining'>{['accept/decline?', 'dead', 'completed'].findIndex(x => x === advert.advertisementStatus?.toLowerCase()) !== -1 ? '-' : <NumericFormat displayType='text' value={advert.currentQuantity} thousandSeparator=',' decimalScale={2}></NumericFormat>}</div>
                  <div className='IOI-entry-percent-remaining'>{['accept/decline?', 'dead', 'completed'].findIndex(x => x === advert.advertisementStatus?.toLowerCase()) !== -1 ? '-' : advert.quantityDecay ? <NumericFormat displayType='text' suffix='%' value={(advert.currentQuantity / advert.shareQuantity)*100} thousandSeparator=',' decimalScale={0}></NumericFormat>|| "" : "-"}</div>
              </div>
          ))}
          {Array.apply(null, { length: emptyIoIs }).map((e, i) => (
            <div className="IOI-history-entry" key={i}>
              <div className='IOI-entry-index'></div>
              <div className={'IOI-entry-status-bar empty'}></div>
              <div className='IOI-entry-security'></div>
              <div className='IOI-entry-security-checkbox-empty'>
              </div>
              <div className={'IOI-entry-direction-empty'}></div>
              <div className='IOI-entry-quantity'></div>
              <div className='IOI-entry-type'></div>
              <div className='IOI-entry-usd'></div>
              <div className='IOI-entry-jpy'></div>
              <div className='IOI-entry-status-column'>
                  <div className={'IOI-entry-status-div empty'}></div>
              </div>
              <div className='IOI-entry-time-remaining'></div>
              <div className='IOI-entry-quantity-remaining'></div>
              <div className='IOI-entry-percent-remaining'></div>
            </div>
          ))}
          </div>
          <div className='IOI-history-table-footer'>
          {props?.selectedSecurities.length > 0 ? <><span>{props?.selectedSecurities.length} {translation.securitiesSelected}</span><span> | {props?.selectedSecurities?.map((selected) => {return selected?.ric})?.join(", ")} {translation.selectBulkAction}</span></> : null}
          </div>
      </div>
    }</div>
  );
};

IOIListingsComponent.propTypes = {
  container: PropTypes.string.isRequired,
  selectedSecurities: PropTypes.array.isRequired,
  setSelectedSecurities: PropTypes.func.isRequired
};

export default IOIListingsComponent;
