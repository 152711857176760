import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import { FetchedNotification } from '../../app/model/model';
import {GATEWAY_URL} from '../../Constant'

interface NotificationFetchState {
  isLoading: boolean;
  initialized: boolean;
  notifications: FetchedNotification[];
  error: string | null;
  lastAction: string | null;
}

const initialState: NotificationFetchState = {
  isLoading: false,
  initialized: false,
  notifications: [],
  error: null,
  lastAction: null,
};

const notificationFetchSlice = createSlice({
  name: 'notificationFetch',
  initialState,
  reducers: {
    fetchNotificationListStart(state) {
      state.isLoading = true;
      state.error = null;
      state.lastAction = 'fetchNotificationStart';
    },
    fetchNotificationListSuccess(state, action: PayloadAction<FetchedNotification[]>) {
      state.isLoading = false;
      state.notifications = action.payload;
      state.lastAction = 'fetchNotificationSuccess';
    },
    fetchNotificationListFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'fetchNotificationFailure'
    }
  },
});

export const {
  fetchNotificationListStart,
  fetchNotificationListSuccess,
  fetchNotificationListFailure,
} = notificationFetchSlice.actions;

export default notificationFetchSlice.reducer;

export const fetchNotificationList = (): AppThunk => async (dispatch, getState) => {
  dispatch(fetchNotificationListStart());
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/notifications', {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const data = await response.json();
    dispatch(fetchNotificationListSuccess(data));
  } catch (error) {
    dispatch(fetchNotificationListFailure(error.message));
  }
};
