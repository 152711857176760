import { useEffect } from 'react'
import react from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from "../../components/customHooks/Translations";
import '../../styles/IOIhistory.css'
import PropTypes from 'prop-types';
import { fetchAdvertisements } from '../../features/adverts/advertisementSlices';

export const MenuButton = props => {

  const dispatch = useDispatch()
  const translation = useTranslation();


  return (
    <div>{props.container !== 'history' ? 
      <button className='IOI-summary-button prevent-select positionTransition' onClick={() => {dispatch(fetchAdvertisements()); props.setContainerState("history")}}>
        <img id="Group_38_a" src="Group_38_a.png" srcSet="Group_38_a.png 1x, Group_38_a@2x.png 2x"></img>
        <span className='IOI-summary-button-text'>{translation.ioiSummary}</span>
      </button>
      : 
      <button className='return-to-menu-button prevent-select positionTransition' onClick={() => {props.setContainerState("basic")}}>
        <img id="Group_180" src="Group_180.png" srcSet="Group_180.png 1x, Group_180@2x.png 2x"></img>
        <span className='return-to-menu-button-text'>{translation.returnToMain}</span>
      </button>
    }</div>
  )
}

MenuButton.propTypes = {
  container: PropTypes.string.isRequired,
  setContainerState: PropTypes.func.isRequired
};

export default MenuButton