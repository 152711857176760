import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { GATEWAY_URL } from '../../Constant';

export type IOICounts = {
  upload: number;
  cancel: number;
  locate: number;
  accept: number;
  decline: number;
  timeout: number;
  triggerCancel: number;
}

export const ioiCountsApi = createApi({
  reducerPath: 'ioiCountsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${GATEWAY_URL}/user`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('userToken');
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        return headers
      }
    },
  }),
  endpoints: (builder) => ({
    getIOICounts: builder.query<IOICounts, void>({
      query: () => 'ioi-counts',
    }),
  }),
});

export const { useGetIOICountsQuery } = ioiCountsApi;
