import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Equity } from '../../app/model/model';
import { AppThunk } from '../../app/store';
import { GATEWAY_URL } from '../../Constant';

interface EquityState {
  isLoading: boolean;
  equities: Equity[];
  error: string | null;
  lastAction: string | null;
}

const initialState: EquityState = {
  isLoading: false,
  equities: [],
  error: null,
  lastAction: null,
};

const equitiesSlice = createSlice({
  name: 'equities',
  initialState,
  reducers: {
    fetchEquitiesStart(state) {
      state.isLoading = true;
      state.error = null;
      state.lastAction = 'fetchStart';
    },
    fetchEquitiesSuccess(state, action: PayloadAction<Equity[]>) {
      state.isLoading = false;
      state.equities = action.payload;
      state.lastAction = 'fetchSuccess';
    },
    fetchEquitiesFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'fetchFailure';
    },
    setEquities(state, action: PayloadAction<Equity[]>) {
      state.equities = action.payload;
    },
  },
});

export const {
  fetchEquitiesStart,
  fetchEquitiesSuccess,
  fetchEquitiesFailure,
  setEquities,
} = equitiesSlice.actions;

export default equitiesSlice.reducer;

export const fetchEquities = (): AppThunk => async (dispatch, getState) => {
  dispatch(fetchEquitiesStart());
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/equity/get-equities', {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const data = await response.json();
    dispatch(fetchEquitiesSuccess(data));
  } catch (error) {
    dispatch(fetchEquitiesFailure(error.message));
  }
};
