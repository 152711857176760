import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useTranslation from "../../components/customHooks/Translations";
import '../../styles/IOIpopups.css'

export const IOIErrorPopup = props => {

  const dispatch = useDispatch();
  const translation = useTranslation();

  function getMessageString(error) {
    let retVal = '';
    if(error.message?.includes("Platform hours for the")) {
        retVal = translation.outsideHoursMessage + '\n';
        const timeStrings = error.message?.match(/\d\d:\d\d to \d\d:\d\d/g)
        for(let i = 0; i < timeStrings.length; i++){
          const smallerTimes = timeStrings[i]?.match(/\d\d:\d\d/g);
          retVal = retVal + new Date('Jan 1, 1970 ' + (smallerTimes[0] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" })
          retVal = retVal + '-' + new Date('Jan 1, 1970 ' + (smallerTimes[1] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" })
          if(i !== timeStrings.length-1){
            retVal = retVal + ', ';
          }
        }
       
        retVal = retVal.replace('[ric]', error.variables[0] || '');

    }
    return retVal;
  }

  return (
    <div className='IOI-basic-popup' style={props.container === 'minimal' ? {top: '-8%', height: "115%"}: {}}>
      <div className='IOI-connection-declined-top'>{translation.genericErrorMessage}</div>
      <div className='IOI-review-body'>
        <div className='IOI-main-details'>
          {getMessageString(props.notification.error)}
        </div>
        <div className='IOI-review-buttons' style={{marginTop: '5%'}}>
            <button className='connection-declined-button' onClick={()=> {props.closeNotification(props.notification)}}>{translation.ok}</button>
        </div>
      </div>
    </div>
  )
}

IOIErrorPopup.propTypes = {
  notification: PropTypes.object.isRequired,
  closeNotification: PropTypes.func.isRequired,
  container: PropTypes.string.isRequired
};

export default IOIErrorPopup