import '../styles/IOIpopups.css';

import useTranslation from './customHooks/Translations';

type InvalidCSVPopupProps = {
  onCloseClick?: () => void;
}

function InvalidCSVPopup({
  onCloseClick,
}: InvalidCSVPopupProps) {
  const translation = useTranslation();

  return (
    <div
      className='IOI-basic-popup'
      style={{
        width: '100%',
        height: 'auto',
        left: '-1rem',
        padding: '1rem',
      }}
    >
      <div className='IOI-review-body'>
        <div className='IOI-main-details'>
          {translation.invalidCsvFileSelected}
        </div>
        <div
          className='IOI-review-buttons'
          style={{
            marginTop: '2rem',
          }}
        >
          <button
            className='connection-declined-button'
            style={{
              width: '5rem',
              height: 'auto',
              padding: '1rem',
            }}
            onClick={onCloseClick}
          >
            {translation.ok}
          </button>
        </div>
      </div>
    </div>
  )
}

export default InvalidCSVPopup;
