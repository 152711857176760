import axios from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { GATEWAY_URL } from '../../Constant';
import { ConditionsPreferences } from './traderPreferencesSlice';

export const fetchPreferences = createAsyncThunk(
  'traderPreferences/fetchPreferences',
  async (
    _,
    {
      rejectWithValue,
    }
  ) => {
    try {
      const userToken = localStorage.getItem('userToken');
      const response = await axios.get<ConditionsPreferences>(
        `${GATEWAY_URL}/user-defaults/get-trader-defaults`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.log(error);
      // return custom error message from API if any
      return rejectWithValue(
        error?.response?.data?.message ?? "Error fetching prefs!"
      );
    }
  }
);

export const updatePreferences = createAsyncThunk(
  'traderPreferences/updatePreferences',
  async (
    prefs: ConditionsPreferences,
    {
      rejectWithValue,
    }
  ) => {
    try {
      const userToken = localStorage.getItem('userToken');
      const response = await axios.post<ConditionsPreferences>(
        `${GATEWAY_URL}/user-defaults/set-trader-defaults`,
        prefs,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.log(error);
      // return custom error message from API if any
      return rejectWithValue(
        error?.response?.data?.message ?? "Error updating prefs!"
      );
    }
  }
);
