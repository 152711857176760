import '../styles/login.css';
import '../styles/forms.css';

import {
  useEffect,
  useState,
} from 'react';

import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import {
  RootState,
  useAppDispatch,
} from '../app/store';
import useTranslation from '../components/customHooks/Translations';
import Error from '../components/Error';
import HomeBrokerCarousel from '../components/HomeBrokerCarousel';
import { fetchAdvertisements } from '../features/adverts/advertisementSlices';
import { userLogin } from '../features/auth/authActions';
import { fetchUserInfo } from '../features/auth/authSlice';
import { fetchBrokers } from '../features/brokers/brokersSlices';
import {
  fetchConnectionAgreements,
} from '../features/connectionAgreements/connectionAgreementSlices';
import { fetchEquities } from '../features/equity/equitiesSlices';
import {
  fetchNotificationList,
} from '../features/notifications/notificationFetchSlice';

type Inputs = {
  email: string;
  password: string;
}

const HomeScreen = props => {
  const translation = useTranslation();
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const navigate = useNavigate()

  const userAdvertisements = useSelector((state: RootState) => state.advertisements);

  const equitiesList = useSelector((state: RootState) => state.equities);

  const notificationFetchList = useSelector((state: RootState) => state.notificationFetch);

  const brokersList = useSelector((state: RootState) => state.brokers);

  const brokerConnectionAgreements = useSelector((state: RootState) => state.connectionAgreements);

  const [equitiesListLoad, setEquitiesListLoad] = useState("not loaded");

  const [, setNotificationFetchListLoad] = useState("not loaded");

  const [brokersListLoad, setBrokersListLoad] = useState("not loaded");

  const [userInfoLoad, setUserInfoLoad] = useState("not loaded");

  const [connectionAgreementLoad, setConnectionAgreementLoad] = useState("not loaded");

  //user info has been updated, get the users advertisments
  useEffect(() => {
    if(localStorage.getItem('userToken')) {
      props.setLoadRequest(true);
      dispatch(fetchUserInfo());
    }
  }, [dispatch, localStorage.getItem('userToken')]);

  useEffect(() => {
    if(userAdvertisements.isLoading){
      props.setUserDataLoad('Started Loading');
    }
  }, [userAdvertisements])

  useEffect(() => {
    if(equitiesList.isLoading){
      setEquitiesListLoad('Started Loading');
    }
  }, [equitiesList])

  useEffect(() => {
    if(notificationFetchList.isLoading){
      setNotificationFetchListLoad('Started Loading');
    }
  }, [notificationFetchList])

  useEffect(() => {
    if(brokersList.isLoading){
      setBrokersListLoad('Started Loading');
    }
  }, [brokersList])

  useEffect(() => {
    if(brokerConnectionAgreements.isLoading){
      setConnectionAgreementLoad('Started Loading');
    }
  }, [brokerConnectionAgreements])

  useEffect(() => {
    if(auth.loading){
      setUserInfoLoad('Started Loading');
    }
  }, [auth])

  useEffect(() => {
    if(auth.lastAction === 'fetchSuccess' && userInfoLoad === 'Started Loading' && equitiesListLoad === connectionAgreementLoad && equitiesListLoad === brokersListLoad && equitiesListLoad === 'not loaded'){
      dispatch(fetchEquities());
      if(auth.userInfo?.role === 'TRADER'){
        dispatch(fetchAdvertisements());
        dispatch(fetchBrokers());
        dispatch(fetchNotificationList());
      }else{
        dispatch(fetchConnectionAgreements());
      }
    }
    if([equitiesList.lastAction, userAdvertisements.lastAction, auth.lastAction, brokersList.lastAction, brokerConnectionAgreements.lastAction].find(lastAction => lastAction === 'fetchFailure')){
      //atleast one fetch failed, stop the load
      //will need to find a way to display the error
      props.setLoadRequest(false);
    }
    const startedFetches = (equitiesListLoad === 'Started Loading' && equitiesListLoad === userInfoLoad && ((equitiesListLoad === props.userDataLoad && equitiesListLoad === brokersListLoad) || (equitiesListLoad === connectionAgreementLoad)) );
    const fetchSuccesses = ((equitiesList.lastAction === 'fetchSuccess' && equitiesList.lastAction === auth.lastAction && ((equitiesList.lastAction === userAdvertisements.lastAction && equitiesList.lastAction === brokersList.lastAction) || (equitiesList.lastAction === brokerConnectionAgreements.lastAction)) ));
    if(startedFetches && fetchSuccesses){
      //All data has been loaded
      props.connectWS(auth.userInfo);
      props.setUserHeader(true);
      props.setLoadRequest(false);
      if(auth.userInfo?.firstLogin === true){
        navigate('/first-login');
      } else {
        if(auth.userInfo?.role === 'TRADER'){
          navigate('/adverts-home');
        }else{
          navigate('/brokers-home');
        }
      }

    }
  }, [userInfoLoad, equitiesList.lastAction, userAdvertisements.lastAction, auth.lastAction, brokersList.lastAction, brokerConnectionAgreements.lastAction])

  useEffect(() => {
    if(auth.error !== null){
      props.setLoadRequest(false);
    }
  }, [auth.error])

  const submitForm = (data) => {
    dispatch(userLogin(data) as any)
  }

  if (isMobile) {
    return (
      <div className='mobile-home' style={props?.mobileMenu ? {boxShadow: '3000px 3000px rgba(0,0,0,.70) inset', filter: 'brightness(0.3)'} : null}>
        <div className='mobile-home-logo'>
          <img
            id="Logo_ha"
            alt="Home"
            src="Logo_ha.png"
            width={'765px'}
            height={'78px'}
            srcSet="Logo_ha.png 1x, Logo_ha@2x.png 2x"
          />
        </div>
        <div className='mobile-home-subtext'>{translation.viewOnDesktop}</div>
        <div className='mobile-footer-watermark' style={{position: 'absolute', bottom: '8%'}}>© 2023 Authentic Indication.</div>
      </div>
    )
  }

  return (
    <div>
      <div id="Seigaiha_Accent_gn">
        <img
          id="Group_949_go"
          alt="Seigaiha Accent"
          src="Group_949_go.png"
          srcSet="Group_949_go.png 1x, Group_949_go@2x.png 2x"
        />
      </div>
      <form
        className='form'
        style={{
          alignItems: 'center',
          gap: '2rem',
          marginTop: '12rem',
        }}
        onSubmit={handleSubmit(submitForm)}
      >
        <div id="Logo_g">
          <img
            id="Logo_ha"
            alt="Authentic Indication"
            src="Logo_ha.png"
            srcSet="Logo_ha.png 1x, Logo_ha@2x.png 2x"
          />
        </div>
        {auth?.error && <Error>{auth?.error.toString()}</Error>}
        <div className='form-field'>
          <label htmlFor="email">{translation.email}</label>
          <input
            type='email'
            className={errors.email && 'error'}
            placeholder='mail@domain.com'
            {...register('email', {
              required: true,
            })}
            required
          />
          {errors.email && <p className='error' role="alert">{errors.email.message}</p>}
        </div>
        <div className='form-field'>
          <label htmlFor="password">{translation.password}</label>
          <input
            type='password'
            className={errors.password && 'error'}
            placeholder='Min. 8 characters'
            {...register('password', {
              required: true,
            })}
            required
          />
          {errors.password && <p className='error' role="alert">{errors.password.message}</p>}
          <NavLink className='form-field' to='/forget-password'><label>{translation.forgotPassword}</label></NavLink>
        </div>
        <div
          style={{
            width: '22rem',
          }}
        >
          <input
            className='btn'
            type="submit"
            value={translation.login}
            disabled={auth?.loading}
          />
        </div>
      </form>
      <HomeBrokerCarousel />
    </div>
  );
}

export default HomeScreen