import '../styles/carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';

import CLSA from '../assets/CLSA.png';
import Jefferies from '../assets/Jefferies.png';
import SCB from '../assets/SCB.png';
import SMBC from '../assets/SMBC.png';
import useTranslation from './customHooks/Translations';

function HomeBrokerCarousel() {
  const translation = useTranslation();

  const logos = [
    {
      id: 'CLSA',
      alt: 'CLSA',
      src: CLSA,
    },
    {
      id: 'Jefferies',
      alt: 'Jefferies',
      src: Jefferies,
    },
    {
      id: 'SCB',
      alt: 'SCB',
      src: SCB,
    },
    {
      id: 'SMBC',
      alt: 'SMBC',
      src: SMBC,
    },
    // duplicate for proper scrolling
    {
      id: 'CLSA1',
      alt: 'CLSA',
      src: CLSA,
    },
    {
      id: 'Jefferies1',
      alt: 'Jefferies',
      src: Jefferies,
    },
    {
      id: 'SCB1',
      alt: 'SCB',
      src: SCB,
    },
    {
      id: 'SMBC1',
      alt: 'SMBC',
      src: SMBC,
    },
  ];

  return (
    <div
      style={{
        padding: '1rem',
        textAlign: 'center',
        marginTop: '2rem',
      }}
    >
      <h2 className='carousel-header'>{translation.executingBrokers}</h2>
      <Slider
        dots={false}
        slidesToShow={4}
        slidesToScroll={1}
        autoplay={true}
        infinite={true}
        autoplaySpeed={3000}
        arrows={true}
      >
        {logos.map((logo) => (
          <div
            key={logo.id}
          >
            <div className='slide'>
              <img
                src={logo.src}
                alt={logo.alt}
                title={logo.alt}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default HomeBrokerCarousel;
