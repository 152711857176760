import { useEffect } from 'react'
import react from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from "../../components/customHooks/Translations";
import '../../styles/stockSelector.css'
import {
  RootState,
} from '../../app/store';

export const RestoreIOIButton = props => {

  const translation = useTranslation();

  const {restoreAvailable } = useSelector((state: RootState) => state.advertisements);


  return (
    <div className={restoreAvailable ? 'restore-ioi prevent-select '+props.container : 'restore-ioi prevent-select disabled ' + props.container} onClick={() => { if(restoreAvailable && !props?.upload) {props?.setRestore(true)}}}>
      <div className='restore-ioi-text' style={translation.language === 'Jp' ? {fontSize: 'calc(11*var(--responsiveFont))'} : {}}>{translation.restoreIOI}</div>
    </div>
  )
}

export default RestoreIOIButton