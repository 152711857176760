import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {GATEWAY_URL} from '../../Constant'

export const userLogin = createAsyncThunk(
  'user/login',
  async ({ email, password }: { email: any, password: any}, { rejectWithValue }) => {
    try {
      email = email?.toLowerCase();
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${GATEWAY_URL}/login`,
        { email, password },
        config
      )

      // store user's token in local storage
      localStorage.setItem('userToken', data.access_token)

      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue("Could not log you in")
      }
    }
  }
)