type TabProps = {
  label: string;
  value: string;
  active: boolean;
  onClick?: () => void;
};

function Tab({
  label,
  value,
  active,
  onClick,
}: TabProps): JSX.Element {
  return (
    <div
      className={
        Object.entries(
          {
            tab: true,
            active,
          }
        )
        .filter(([_, v]) => !!v)
        .map(([k, _]) => k)
        .join(' ')
      }
      onClick={onClick}
    >{label}</div>
  );
}

export default Tab;
