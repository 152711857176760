import '../../styles/bulkUpload.css';

import {
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import {
  RootState,
  useAppDispatch,
} from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';
import { Advertisement, AdvertisementRestore } from '../../app/model/model';
import {
  submitAdvert,
} from '../../features/adverts/advertisementActions';
import {
  prepareRestoreAdverts,
} from '../../features/adverts/advertisementSlices';
import Spinner from '../Spinner';

export const RestoreIOI = props => {

  const dispatch = useAppDispatch()
  const translation = useTranslation();
  const {equities } = useSelector((state: RootState) => state.equities);

  const {lastAction, isLoading, advertisements, expiredAdvertisements, restoreDate } = useSelector((state: RootState) => state.advertisements);

  const [dispatchedData, setDispatchedData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(true)

  const equitiesList = useSelector((state: RootState) => state.equities);

  function replacePlaceholders(translation, value) {
    let result = translation;
    const placeholder = `%s${1}`; // Using %s1, %s2, ... as placeholders
    const placeholderRegex = new RegExp(placeholder.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
    result = result.replace(placeholderRegex, value);
  
    return result;
  }

  function changeAllStates(selected: boolean) {
    setSelectAll(selected)
    let newAdvertSelection: AdvertisementsSelection[] = [];
    dispatchedData.forEach(item => {
      const modifiableAdvert = { ...item };
      modifiableAdvert.selected = selected
      newAdvertSelection.push(modifiableAdvert);
    });

    setDispatchedData(newAdvertSelection);
  }
  
  function getSecurityName (securityId) {
    const matchingEquity = equitiesList.equities.find(equity => equity.equityId === securityId)
    return (matchingEquity?.ric + ' - ' + matchingEquity?.name) || '';
  }

  useEffect(() => {
    // Build the list of expired IOIs
    /*const dispatchedDataNew = [];
    expiredAdvertisements.forEach(item => {
      const modifiableAdvert = { ...item };
      let found = false;
      // Update the equityIds
      equities.forEach(equity => {
        if ((equity?.ric + ' - ' + equity?.name) === item.securityName) {
          modifiableAdvert.securityId = equity.equityId;
          found = true;
          return true;
        }
      });
      if (found) {
        const newObj = {
          status: 'OK',
          advertisement: modifiableAdvert,
        };
        // Push the new object to the array
        dispatchedDataNew.push(newObj);
      }
    });*/
    const todayDate = new Date();
    const today = todayDate.toLocaleDateString();
    let found = false;
    let backDays = 1;
    let previousDay = getPreviousDay(todayDate);
    let sourceExpiredAdvertisements;
    while(!found && backDays < 7) {
      let storedData = localStorage.getItem(previousDay.toLocaleDateString());
      if (storedData != null) {
        sourceExpiredAdvertisements = JSON.parse(storedData);
        found = true;
      }
      previousDay = getPreviousDay(previousDay);
      backDays++;
    }

    let expiredAdvertisements: Advertisement[] = [];
    if (sourceExpiredAdvertisements != null) {
      sourceExpiredAdvertisements.forEach(item => {
        const modifiableAdvert = { ...item };
        let found = false;
        // Update the equityIds
        equities.forEach(equity => {
          if ((equity?.ric + ' - ' + equity?.name) === item.securityName) {
            modifiableAdvert.securityId = equity.equityId;
            found = true;
            return true;
          }
        });
        if (found) {
          // Push the new object to the array
          expiredAdvertisements.push(modifiableAdvert);
        }
      });
    }
    
    dispatch(prepareRestoreAdverts(expiredAdvertisements));

    //setDispatchedData(dispatchedDataNew);
  }, [dispatch]);

  function getPreviousDay(today) {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
  }

  type AdvertisementsSelection = {
    advertisementRestore: AdvertisementRestore;
    selected: boolean;
  };

  useEffect(() => {

    if (expiredAdvertisements != null) {
      let advertSelection: AdvertisementsSelection[] = [];
      expiredAdvertisements.forEach(item => {
        const modifiableAdvert = { ...item };
        let found = false;
        // Push the new object to the array
        const newObj: AdvertisementsSelection = {
          advertisementRestore: item,
          selected: true,
        };
        advertSelection.push(newObj);
      });
      setDispatchedData(advertSelection);
    } else {
      setDispatchedData(null);
    }
  }, [expiredAdvertisements]); 

  async function submitUploads() {
    setSubmitLoading(true);
    let submitPassed = false;
    if(dispatchedData?.length > 0){
      for(let i = 0; i < dispatchedData.length; i++){
        if(dispatchedData[i].advertisementRestore.status === 'OK' && dispatchedData[i].selected === true){
          const submitResult = await dispatch(submitAdvert(dispatchedData[i].advertisementRestore.advertisement));
          if(typeof submitResult === typeof [] && 'status' in (submitResult as undefined as object)){
            submitPassed = true;
          }
        }
      }
    }
    setSubmitLoading(false);
    if(submitPassed && props.container === 'minimal'){
      props.setContainer('basic');
    }
    props.setRestore(false);
  }

  function getErrorMessage(dataEntry) {
    let errorString = translation[dataEntry?.errorCode] || '';
    for(const key in dataEntry?.errorMap){
      var value = dataEntry?.errorMap[key]
      if (key === 'shareQuantity' || key === 'minimumShareQuantity') {
        value = parseInt(value).toLocaleString('en-US')
      }
      errorString = errorString.replace('['+key+']', (translation[value.toLowerCase()] || value));
    }

    errorString = errorString.replace('[ric]', (equitiesList.equities.find(equity => equity.equityId === dataEntry?.errorMap["security_id"])?.ric));
    
    if(dataEntry?.errorCode === "RESTORE-ERR-03") {
        const timeStrings = dataEntry?.status?.match(/\d\d:\d\d to \d\d:\d\d/g)
        for(let i = 0; i < timeStrings.length; i++){
          const smallerTimes = timeStrings[i]?.match(/\d\d:\d\d/g);
          errorString = errorString + new Date('Jan 1, 1970 ' + (smallerTimes[0] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" })
          errorString = errorString + '-' + new Date('Jan 1, 1970 ' + (smallerTimes[1] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" })
          if(i !== timeStrings.length-1){
            errorString = errorString + ', ';
          }
        }
        const exchangeName = dataEntry?.status?.match(/the\s(.+)\sare/);
        if(exchangeName.length > 0){
          errorString = errorString.replace('[exchange]', exchangeName[1]);
        }
    }
    return errorString || dataEntry?.status;
  }

  function changeSelectedState(index) {
    let newAdvertSelection: AdvertisementsSelection[] = [];
    let count = 0;
    dispatchedData.forEach(item => {
      const modifiableAdvert = { ...item };
      if (count == index) {
        modifiableAdvert.selected = !modifiableAdvert.selected;
      }
      newAdvertSelection.push(modifiableAdvert);
      count++;
    });
    setDispatchedData(newAdvertSelection);
  }

  return (
    <div className={'bulk-upload-confirmation-popup ' + props?.container}>
      {(isLoading || submitLoading) && <Spinner/>}
      <div className='bulk-upload-popup-top'>{replacePlaceholders(translation.restoreDate, restoreDate)}</div>
      <div className='upload-confirmation-IOI-summary-header'>
        <div className='bulk-upload-checkbox-container' onClick={() => changeAllStates(!selectAll)}>
          <div className='bulk-upload-checkbox'>
            <div className={selectAll ?'bulk-upload-checkbox-check' : 'IOI-entry-security-checkbox-empty'}></div>
          </div>
        </div>
        <div className='upload-confirmation-summary-header-text'>{translation.ioiSummary}</div>
      </div>
      <div className='bulk-upload-IOI-summary'>
        {dispatchedData?.map((dataEntry,index) => (
          <div className='bulk-upload-IOI-summary-entry' key={index} style={dataEntry?.advertisementRestore.status === 'OK' ? null :  {backgroundColor: "#D5D5D5"}}>
            <div className='bulk-upload-checkbox-container' onClick={() => changeSelectedState(index)}>
              <div className='bulk-upload-checkbox'>
                {dataEntry?.advertisementRestore.status === 'OK' ?
                   dataEntry?.selected === true ? <div className='bulk-upload-checkbox-check'></div> : <div className='IOI-entry-security-checkbox-empty'></div> 
                  : 
                  <svg className='bulk-upload-checkbox-x prevent-select' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>
                }              </div>
            </div>
            {dataEntry?.advertisementRestore.status === 'OK' ?
            <div className='bulk-upload-IOI-summary-entry-text'>
              <span>{getSecurityName(dataEntry?.advertisementRestore.advertisement?.securityId || "")} | </span>
              <span style={dataEntry?.advertisementRestore.advertisement?.direction === 'BUY' ? {color: "#0EAA3B"}: {color: "#BC3C3C"}}>{dataEntry?.advertisementRestore.advertisement?.direction === 'BUY' ? translation.buy : translation.sell} {dataEntry?.advertisementRestore.advertisement?.shareQuantity ? <NumericFormat displayType='text' thousandSeparator=',' value={dataEntry?.advertisementRestore.advertisement?.shareQuantity}></NumericFormat> : '-'} {translation.shares}</span>
              <span style={{fontWeight: "normal"}}> (
              <NumericFormat displayType='text' decimalScale={1} prefix='$' suffix={dataEntry?.advertisementRestore.advertisement?.usdnotional > 100000 ? 'm ' : ' '} value={dataEntry?.advertisementRestore.advertisement?.usdnotional > 100000 ? (dataEntry?.advertisementRestore.advertisement?.usdnotional / 1000000): dataEntry?.advertisementRestore.advertisement?.usdnotional}></NumericFormat>
               /
              <NumericFormat displayType='text' decimalScale={1} prefix=' JPY ' suffix={dataEntry?.advertisementRestore.advertisement?.jpynotional > 100000 ? 'm ' : ' '} value={dataEntry?.advertisementRestore.advertisement?.jpynotional > 100000 ? (dataEntry?.advertisementRestore.advertisement?.jpynotional / 1000000): dataEntry?.advertisementRestore.advertisement?.jpynotional}></NumericFormat>
              {translation.notional})</span> <span>{dataEntry?.advertisementRestore.advertisement?.transactionTypePreference === 'CASH_SWAP' ? translation.transactionTypeCASH_SWAP : dataEntry?.advertisementRestore.advertisement?.transactionTypePreference === 'CASH_ONLY' ? translation.transactionTypeCASH_ONLY : translation.transactionTypeSWAP_ONLY}</span>
            </div>
            :
            <div className='bulk-upload-IOI-summary-entry-text'>
              {dataEntry?.advertisementRestore.advertisement !== null ?
                <span>{dataEntry?.advertisementRestore.status}</span>
                :
                <span style={{color: '#454545'}}>{getErrorMessage(dataEntry.advertisementRestore)}</span>
              }
            </div>
            }
          </div>
        )
        )}
        {Array.apply(null, { length: (8 - dispatchedData?.length >= 0 ? (8-dispatchedData.length) : 0) }).map((e, i) => (
          <div className='bulk-upload-IOI-summary-entry' key={i+dispatchedData.length}>
            <div className='bulk-upload-checkbox-container'>
            </div>
            <div className='bulk-upload-IOI-summary-entry-text'></div>
          </div>
        ))}

      </div>
      <button className='cancel-button-bulk-confirm' onClick={() => {setDispatchedData(null); props.setRestore(false)}}>{translation.cancel}</button>
      <button className='submit-button-bulk-confirm' onClick={() => {submitUploads()}}>{translation.upload}</button>
    </div>
  )
}

RestoreIOI.propTypes = {
  setRestore: PropTypes.func.isRequired,
  container: PropTypes.string.isRequired,
  setContainer: PropTypes.func.isRequired
};

export default RestoreIOI