import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useTranslation from "../components/customHooks/Translations";
import '../styles/IOIpopups.css'
import { RootState } from '../app/store';

export const AutoLogOutPopup = props => {

  const translation = useTranslation();

  const userInfo = useSelector((state: RootState) => state.auth.userInfo)

  return (
    <div className='IOI-basic-popup' style={props.container === 'minimal' ? {top: '-8%', height: "115%"}: {}}>
      <div className='IOI-connection-declined-top'>{translation.loggedOut}</div>
      <div className='IOI-review-body'>
        <div className='IOI-main-details'>
          {props.notification?.message === userInfo?.username + ' timed out' ? 
          translation.loggedOutDetailsTimeout
          :
          translation.loggedOutDetails}
        </div>
        <div className='IOI-review-buttons' style={{marginTop: '5%'}}>
            <button className='connection-declined-button' onClick={() => {props.closeNotification(props.notification);}}>{translation.logOff}</button>
        </div>
      </div>
    </div>
  )
}

AutoLogOutPopup.propTypes = {
  notification: PropTypes.object.isRequired,
  closeNotification: PropTypes.func.isRequired,
  container: PropTypes.string.isRequired
};

export default AutoLogOutPopup