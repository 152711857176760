import { useDispatch } from 'react-redux';

import {
  Action,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import advertisementsReducer from '../features/adverts/advertisementSlices';
import authReducer from '../features/auth/authSlice';
import brokersReducer from '../features/brokers/brokersSlices';
import connectionAgreementsReducer
  from '../features/connectionAgreements/connectionAgreementSlices';
import demoReducer from '../features/demo/demoSlices';
import equitiesReducer from '../features/equity/equitiesSlices';
import { ioiCountsApi } from '../features/ioiCounts';
import notificationFetchReducer
  from '../features/notifications/notificationFetchSlice';
import resetPasswordReducer from '../features/resetPassword/resetPasswordSlice';
import staffReducer from '../features/staff/staffSlices';
import traderPreferencesReducer
  from '../features/traderPreferences/traderPreferencesSlice';
import { authApi } from './services/auth/authService';
import { versionApi } from './services/versionService';

const store = configureStore({
  reducer: {
    auth: authReducer,
    advertisements: advertisementsReducer,
    equities: equitiesReducer,
    brokers: brokersReducer,
    connectionAgreements: connectionAgreementsReducer,
    demo: demoReducer,
    staff: staffReducer,
    notificationFetch: notificationFetchReducer,
    [authApi.reducerPath]: authApi.reducer,
    resetPassword: resetPasswordReducer,
    traderPreferences: traderPreferencesReducer,
    [ioiCountsApi.reducerPath]: ioiCountsApi.reducer,
    [versionApi.reducerPath]: versionApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(ioiCountsApi.middleware)
      .concat(versionApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>;


export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
