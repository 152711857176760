import PropTypes from 'prop-types';
import useTranslation from "../components/customHooks/Translations";
import '../styles/passwordResetEmailSentPopup.css'
import {useNavigate} from 'react-router-dom';

export const PasswordResetEmailSentPopup = (props: { message: string; redirect: string}) => {
  const translation = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='password-reset-basic-popup' style={{top: '40%', height: "20%"}}>
      <div className='password-reset-top'>{translation.resetPassword}</div>
      <div className='password-reset-body'>
        <div className='password-reset-message'>
          {props.message}
        </div>
        <div className='password-reset-buttons' style={{marginTop: '5%'}}>
            <button className='password-reset-button' onClick={() => {navigate(props.redirect)}}>{translation.ok}</button>
        </div>
      </div>
    </div>
  )
}

PasswordResetEmailSentPopup.propTypes = {
  message: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired
};

export default PasswordResetEmailSentPopup