import { useEffect, useState } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import PropTypes from 'prop-types';
import Header from '../components/Header'
import BulkUpload from '../components/adverts/BulkUpload'
import RestoreIOI from '../components/adverts/RestoreIOI'
import AdvertButtons from '../components/adverts/AdvertButtons'
import IOIStatus from '../components/adverts/IOIStatus'
import IOIReview from '../components/adverts/IOIReview'
import IOIErrorPopup from '../components/adverts/IOIErrorPopup';
import IOIConnectionDeclined from '../components/adverts/IOIConnectionDeclined'
import IOIConnectionAccepted from '../components/adverts/IOIConnectionAccepted'
import ContraLocated from '../components/adverts/ContraLocated'
import IOIListingsComponent from '../components/adverts/IOIListingsComponent'
import StockSearchBar from '../components/adverts/StockSearchBar'
import BulkUploadButton from '../components/adverts/BulkUploadButton'
import RestoreIOIButton from '../components/adverts/RestoreIOIButton'
import IOIHome from '../components/adverts/IOIHome'
import MenuButton from '../components/adverts/MenuButton'
import AutoLogOutPopup from '../components/AutoLogOutPopup';
import { IOIMessagePopup } from '../components/adverts/IOIMessagePopup'
import { RootState } from '../app/store';

import '../styles/containers.css'
import { fetchAdvertisements, selectAdvertisement, closeMessageView } from '../features/adverts/advertisementSlices';
import { residualAdvert, acceptContraConnection, rejectContraConnection, setRefresh } from '../features/adverts/advertisementActions';
import { callLogout } from '../features/auth/authSlice'
import useTranslation from '../components/customHooks/Translations';

const AdvertsHome = props => {
  const dispatch = useDispatch();
  const translation = useTranslation();

  const initialNotificationList = useSelector((state: RootState) => state.notificationFetch);
  const userAdvertisements = useSelector((state: RootState) => state.advertisements);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo)

  //basic advanced and minimal
  const [container, setContainer] = useState(userAdvertisements?.advertisements?.length > 0 ? 'basic' : 'minimal');

  const [selectedId, setSelectedId] = useState('');

  const [notifications, setNotifications] = useState([]);

  const [review, setReview] = useState(false);
  const [upload, setUpload] = useState(false);
  const [restore, setRestore] = useState(false);

  const [selectedSecurities, setSelectedSecurities] = useState([]);

  
  // Ask for permission when the 'Enable notifications' button is clicked
  function askNotificationPermission() {

    // Check if the browser supports notifications
    if (!Reflect.has(window, 'Notification')) {
      console.log('This browser does not support notifications.');
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then();
      } else {
        Notification.requestPermission();
      }
    }
  };

  function closeMessage(errorCode) {
    if (errorCode === 'ADVERT-ERROR-011') {
        setContainer('advanced')
    }
    
    dispatch(closeMessageView());
  }

  useEffect(() => {
    if((initialNotificationList.lastAction === 'fetchNotificationSuccess') 
        && initialNotificationList.notifications.length > 0 && notifications.length <= 0){
      for(let i = 0; i < initialNotificationList.notifications.length; i++){
        try {
          const JSONreceived = JSON.parse(initialNotificationList.notifications.at(i).message);
          const parsedDate = new Date(initialNotificationList.notifications.at(i).utcTime);
          const utcDate = new Date(parsedDate + " UTC");
          const localDateString = utcDate.toLocaleString();
	        const equityId = initialNotificationList.notifications.at(i).securityId;
          JSONreceived.securityId = equityId;
          if (JSONreceived && typeof JSONreceived === "object") {
            if(["Contra Connection Accepted!", "Contra Connection Declined", "Connection Declined","Contra locate timeout"].indexOf(JSONreceived.message) !== -1){
              if('securityId' in JSONreceived){
                const prevNotif = notifications.findIndex((notif) => {return (notif?.securityId === JSONreceived.securityId && notif?.message !== 'Contra Connection Accepted!' && notif?.message !== "Advertisement expired")});
                if(prevNotif !== -1){
                  notifications.splice(prevNotif,1);
                }
              }
              notifications.unshift({...JSONreceived, 'visible': false, "ack": true, "messageType": "locate", "timestamp": localDateString});
              setNotifications([...notifications]);
            }else if(JSONreceived.message === "Advertisement expired"){
              let securityId = undefined;
              if('securityId' in JSONreceived){ 
                securityId = JSONreceived.securityId;
              } else if('advertisementId' in JSONreceived){
                securityId = userAdvertisements.advertisements.find(ad => ad.id === JSONreceived.advertisementId)?.securityId;
              }
              notifications.unshift({...JSONreceived, 'visible': false, "ack": true, "messageType": "statusAlert", "securityId": securityId, "timestamp": localDateString});
              setNotifications([...notifications]);
            }else if(JSONreceived.message === "LOCATE_ERROR"){
              let securityId = undefined;
              if('securityId' in JSONreceived){ 
                securityId = JSONreceived.securityId;
              } else if('advertisementId' in JSONreceived){
                securityId = userAdvertisements.advertisements.find(ad => ad.id === JSONreceived.advertisementId)?.securityId;
              }
              notifications.unshift({...JSONreceived, 'visible': false, "ack": true, "messageType": "statusAlert", "securityId": securityId, "timestamp": localDateString});
              setNotifications([...notifications]);
            }else if(JSONreceived.message === "CANCEL_TRIGGER"){
              let securityId = undefined;
              if('securityId' in JSONreceived){ 
                securityId = JSONreceived.securityId;
              } else if('advertisementId' in JSONreceived){
                securityId = userAdvertisements.advertisements.find(ad => ad.id === JSONreceived.advertisementId)?.securityId;
              }
              notifications.unshift({...JSONreceived, 'visible': false, "ack": true, "messageType": "statusAlert", "securityId": securityId, "timestamp": localDateString});
              setNotifications([...notifications]);
            }else if(JSONreceived.message === "MARKET_ERROR"){
              let securityId = undefined;
              if('securityId' in JSONreceived){ 
                securityId = JSONreceived.securityId;
              } else if('advertisementId' in JSONreceived){
                securityId = userAdvertisements.advertisements.find(ad => ad.id === JSONreceived.advertisementId)?.securityId;
              }
              notifications.unshift({...JSONreceived, 'visible': false, "ack": true, "messageType": "statusAlert", "securityId": securityId, "timestamp": localDateString});
              setNotifications([...notifications]);
            }
          }
        }catch(e) {
          //Message received not json
          //No need to ACK back
        }
      }
      
      dispatch(setRefresh());
    }
  }, [initialNotificationList])

  // Check whether browser supports the promise version of requestPermission()
  // Safari only supports the old callback-based version
  function checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch(e) {
      return false;
    }

    return true;
  };

  // Create a notification with the given title
  function createNotification() {
    // Create and show the notification
    const notification = new Notification('Authentic Indication', { body: translation.contraLocated, icon: '/AI-Favicon-circle.png', });
    notification.onclick = (event) => {
      window.focus();
      window.parent.focus();
      notification.close();
    };
  };


  useEffect(() => {
    if(props?.advertisements?.selectedAdvertisement?.id === undefined && props?.advertisements?.advertisements.length > 0){
      //Selecting first advert in list
      dispatch(selectAdvertisement(props.advertisements.advertisements[0]));
    }
    props.setUserHeader(true);
  }, [])

  useEffect(() => {
    if(userAdvertisements?.error?.message.includes("Platform hours for the")){
      //create the popup error message
      notifications.unshift({
        "message": "Platform hours for the",
        "error": userAdvertisements.error,
        "visible": true,
        "ack": true,
        "messageType": "system",
        "timestamp": Date.now()
      });
      console.log(notifications)
      setNotifications([...notifications]);
    }
  }, [userAdvertisements.error])

  useEffect(() => {
    if(userAdvertisements.selectedAdvertisement !== undefined && userAdvertisements.selectedAdvertisement !== null){
      if(userAdvertisements.advertisements.length > 0 && !('id' in userAdvertisements.selectedAdvertisement)){
        dispatch(selectAdvertisement(userAdvertisements.advertisements[0]));
      }
    }
    if(userAdvertisements.advertisements.length > 0 && userAdvertisements.selectedAdvertisement === undefined){
      dispatch(selectAdvertisement(userAdvertisements.advertisements[0]));
    }
  }, [userAdvertisements.advertisements.length])

  useEffect(() => {
    if(userAdvertisements.selectedAdvertisement === undefined || userAdvertisements.selectedAdvertisement === null &&
      (userAdvertisements.advertisements.length > 0)){
        dispatch(selectAdvertisement(userAdvertisements.advertisements[0]));
    }
  }, [userAdvertisements.selectedAdvertisement])
  
  useEffect(() => {
    if(userAdvertisements.needRefresh){
      dispatch(fetchAdvertisements());
    }
  }, [userAdvertisements.needRefresh])

  useEffect(() => {
    if(userAdvertisements.isLoading){
      props.setUserDataLoad('Started Loading');
    }else{
      if(props.userDataLoad === 'Started Loading'){
        props.setUserDataLoad('Finished Loading');
        if(selectedId !== ''){
          const createdAdvertisement = userAdvertisements.advertisements.find(advertisement => advertisement.securityId === selectedId && advertisement?.advertisementStatus?.toUpperCase() !== 'COMPLETED');
          if(createdAdvertisement?.id){
          dispatch(selectAdvertisement(createdAdvertisement));
          setSelectedId('');
          document.getElementById('buy-sell-toggle-button').focus();
          }
        }
      }
      //Havent loaded anything yet
    }
  }, [userAdvertisements])

  useEffect(() => {
    if(props?.socketMessageIndex < props.incomingMessage.length){
    try {
        const JSONreceived = JSON.parse(JSON.parse(props.incomingMessage[props?.socketMessageIndex]).message);
        console.log(JSONreceived)
        if (JSONreceived && typeof JSONreceived === "object") {
          if(["Contra Located", "Contra Connection Accepted!", "Contra Connection Declined", "Connection Declined","Contra locate timeout"].indexOf(JSONreceived.message) !== -1){
            //notifications.push({...JSONreceived, 'visible': true, "ack": false});
            if('securityId' in JSONreceived){
              const prevNotif = notifications.findIndex((notif) => {return (notif?.securityId === JSONreceived.securityId && notif?.message !== 'Contra Connection Accepted!' && notif?.message !== "Advertisement expired")});
              if(prevNotif !== -1){
                notifications.splice(prevNotif,1);
              }
            }
            if(JSONreceived.message === "Contra Located" && !document.hasFocus()){
              createNotification();
            }
            notifications.unshift({...JSONreceived, 'visible': true, "ack": false, "messageType": "locate", "timestamp": Date.now()});
            console.log(notifications)
            setNotifications([...notifications]);
            dispatch(setRefresh());
          }else if(JSONreceived.message === "USER_ACCEPTED_LOCATE"){
            let securityId = undefined;
            if('securityId' in JSONreceived){ 
              securityId = JSONreceived.securityId;
            } else if('advertisementId' in JSONreceived){
              securityId = userAdvertisements.advertisements.find(ad => ad.id === JSONreceived.advertisementId)?.securityId;
            }
            if (securityId) {
              const prevNotif = notifications.findIndex((notif) => {return (notif?.securityId === securityId && notif?.message === 'Contra Located')});
              if(prevNotif !== -1){
                notifications.splice(prevNotif,1);
              }
            }
            notifications.unshift({...JSONreceived, 'visible': false, "ack": false, "messageType": "statusAlert", "securityId": securityId, "timestamp": Date.now()});
            console.log(notifications);
            setNotifications([...notifications]);
            dispatch(setRefresh());
          }else if(JSONreceived.message === "USER_RESUMED_ADVERT"){
            let securityId = undefined;
            if('securityId' in JSONreceived){ 
              securityId = JSONreceived.securityId;
            } else if('advertisementId' in JSONreceived){
              securityId = userAdvertisements.advertisements.find(ad => ad.id === JSONreceived.advertisementId)?.securityId;
            }
            if (securityId) {
              const prevNotif = notifications.findIndex((notif) => {return (notif?.securityId === securityId && notif?.message === 'Contra Connection Declined')});
              if(prevNotif !== -1){
                notifications.splice(prevNotif,1);
              }
            }
            setNotifications([...notifications]);
            dispatch(setRefresh());
          }else if(JSONreceived.message === "ADVERT_UPDATED"){
            dispatch(setRefresh());
          }else if(JSONreceived.message === "ADVERT_SUBMITTED"){
            dispatch(setRefresh());
          }else if(JSONreceived.message === "Advertisement expired"){
            let securityId = undefined;
            if('securityId' in JSONreceived){ 
              securityId = JSONreceived.securityId;
            } else if('advertisementId' in JSONreceived){
              securityId = userAdvertisements.advertisements.find(ad => ad.id === JSONreceived.advertisementId)?.securityId;
            }
            notifications.unshift({...JSONreceived, 'visible': false, "ack": false, "messageType": "statusAlert", "securityId": securityId, "timestamp": Date.now()});
            console.log(notifications);
            setNotifications([...notifications]);
            dispatch(setRefresh());
          }else if(JSONreceived.message === "LOCATE_ERROR"){
            let securityId = undefined;
            if('securityId' in JSONreceived){ 
              securityId = JSONreceived.securityId;
            } else if('advertisementId' in JSONreceived){
              securityId = userAdvertisements.advertisements.find(ad => ad.id === JSONreceived.advertisementId)?.securityId;
            }
            notifications.unshift({...JSONreceived, 'visible': false, "ack": false, "messageType": "statusAlert", "securityId": securityId, "timestamp": Date.now()});
            console.log(notifications);
            setNotifications([...notifications]);
            dispatch(setRefresh());
          }else if(JSONreceived.message === "CANCEL_TRIGGER"){
            let securityId = undefined;
            if('securityId' in JSONreceived){ 
              securityId = JSONreceived.securityId;
            } else if('advertisementId' in JSONreceived){
              securityId = userAdvertisements.advertisements.find(ad => ad.id === JSONreceived.advertisementId)?.securityId;
            }
            notifications.unshift({...JSONreceived, 'visible': false, "ack": false, "messageType": "statusAlert", "securityId": securityId, "timestamp": Date.now()});
            console.log(notifications);
            setNotifications([...notifications]);
            dispatch(setRefresh());
          }else if(JSONreceived.message === "MARKET_ERROR"){
            let securityId = undefined;
            if('securityId' in JSONreceived){ 
              securityId = JSONreceived.securityId;
            } else if('advertisementId' in JSONreceived){
              securityId = userAdvertisements.advertisements.find(ad => ad.id === JSONreceived.advertisementId)?.securityId;
            }
            notifications.unshift({...JSONreceived, 'visible': false, "ack": false, "messageType": "statusAlert", "securityId": securityId, "timestamp": Date.now()});
            console.log(notifications);
            setNotifications([...notifications]);
            dispatch(setRefresh());
          }
      }
    }
    catch (e) {
      //Message received not json
      //Check if it is logout message
      try{
        const JSONreceived = JSON.parse(props.incomingMessage[props?.socketMessageIndex]);
        console.log(JSONreceived)
        if(JSONreceived && typeof JSONreceived === "object") {
          if(userInfo?.username + ' logged out' === JSONreceived?.message || userInfo?.username + ' timed out' === JSONreceived?.message){
            //session is invalid
            notifications.unshift({message: JSONreceived?.message, 'visible': true, "ack": true, "messageType": "statusAlert"});
            setNotifications([...notifications]);
            //disconnect websocket to prevent any further popups to an invalid session
            props.disconnectWS();
          }
        }
      }catch(e) {
        //Message received not json
        //No need to ACK back
      }
    }
    props?.setSocketMessageIndex(props?.socketMessageIndex+1);
    }
  }, [props.incomingMessage, props.socketMessageIndex])

  function closeNotification(notification, action=null) {
    const indexFound = notifications.indexOf(notification);
    if(indexFound !== -1){notifications[indexFound].visible = false; notifications[indexFound].ack = true}
    setNotifications([...notifications]);
    if(action !== null){
      const userDirection = (notification?.contraDirection?.toLowerCase() === 'buy' ? 'sell' : 'buy');
      if(action === 'decline'){
        dispatch(rejectContraConnection(notification?.locateNotificationId || '', userDirection));
      }else if(action === 'accept'){
        dispatch(acceptContraConnection(notification?.locateNotificationId || '', userDirection));
      } else if(action === 'residual'){
        dispatch(residualAdvert(notification?.advertisementId));
      }
    }
  }

  return (
    <div className={'containers advert-'+container+'-container'} onLoad={() => askNotificationPermission()}>
      {<Header notifications={notifications} setNotifications={setNotifications} disconnectWS={props.disconnectWS} userHeader={props.userHeader} setUserHeader={props.setUserHeader} userDataLoad={props.userDataLoad} container={container}/>}
      {container !== "minimal" && container !== "history" ?<IOIStatus container={container}/>: null}
      {container !== "minimal" ?<AdvertButtons setReview={setReview} container={container} selectedSecurities={selectedSecurities} setSelectedSecurities={setSelectedSecurities}/>: null}
      {container !== "minimal" && container !== "history" ?<IOIHome container={container} setContainerState={setContainer}/>: null}
      {container !== "minimal" ? <IOIListingsComponent container={container} selectedSecurities={selectedSecurities} setSelectedSecurities={setSelectedSecurities}/>: null}
      {container !== "history" ? <StockSearchBar container={container} setContainerState={setContainer} setSelectedId={setSelectedId}/>: null}
      {container !== "history" ? <BulkUploadButton restore={restore} setUpload={setUpload} container={container}/>: null}
      {container !== "history" ? <RestoreIOIButton upload={upload} setRestore={setRestore} container={container}/>: null}
      {container !== "minimal" ? <MenuButton setContainerState={setContainer} container={container}/> : null}
      {review ? <IOIReview setReview={setReview}/> : null}
      {upload ? <BulkUpload setUpload={setUpload} container={container} setContainer={setContainer}/> : null}
      {restore ? <RestoreIOI setRestore={setRestore} container={container} setContainer={setContainer}/> : null}
      {notifications.length > 0 && container !== "minimal" ? 
        (notifications.filter((notif) => notif?.visible).length > 0) ? 
          (notifications.filter((notif) => notif?.visible)[0]?.message === 'Contra Located' ? 
            <ContraLocated notification={notifications.filter((notif) => notif?.visible)[0]} closeNotification={closeNotification}/> 
          : 
            null) 
        :
          null
      : 
        null}
      
      {notifications.length > 0 && container !== "minimal" ? 
        (notifications.filter((notif) => notif?.visible).length > 0) ? 
          (notifications.filter((notif) => notif?.visible)[0]?.message === 'Contra Connection Accepted!' ? 
            <IOIConnectionAccepted notification={notifications.filter((notif) => notif?.visible)[0]} closeNotification={closeNotification}/> 
          : 
            null)
        :
          null 
      : 
        null}

      {notifications.length > 0 && container !== "minimal" ? 
        (notifications.filter((notif) => notif?.visible).length > 0) ? 
          ((notifications.filter((notif) => notif?.visible)[0]?.message === 'Contra Connection Declined' 
          || notifications.filter((notif) => notif?.visible)[0].message === 'Connection Declined' 
          || notifications.filter((notif) => notif?.visible)[0].message === 'Contra locate timeout') ? 
            <IOIConnectionDeclined notification={notifications.filter((notif) => notif?.visible)[0]} closeNotification={closeNotification} notificationStack={notifications} updateNotificationStack={setNotifications}/> 
          : 
            null) 
        :
          null
      : 
        null}

      {notifications.length > 0 ? 
        (notifications.filter((notif) => notif?.visible).length > 0) ? 
          ((notifications.filter((notif) => notif?.visible)[0]?.message === 'Platform hours for the') ? 
            <IOIErrorPopup container={container} notification={notifications.filter((notif) => notif?.visible)[0]} closeNotification={(notif) => {notifications.splice(notifications.indexOf(notif),1); setNotifications([...notifications]);}}/> 
          : 
            null) 
        :
          null
      : 
        null}

      {notifications.length > 0 ? 
        (notifications.filter((notif) => notif?.visible).length > 0) ? 
          (((notifications.filter((notif) => notif?.visible)[0]?.message === userInfo?.username + ' logged out') || (notifications.filter((notif) => notif?.visible)[0]?.message === userInfo?.username + ' timed out')) ? 
            <AutoLogOutPopup container={container} notification={notifications.filter((notif) => notif?.visible)[0]} closeNotification={(notif) => {notifications.splice(notifications.indexOf(notif),1); setNotifications([...notifications]); props.disconnectWS(); dispatch(callLogout());}}/> 
          : 
            null) 
        :
          null
      : 
        null}

    { userAdvertisements?.error !== null && !userAdvertisements?.error.message?.includes("Platform hours for the") ?
       <IOIMessagePopup container={container} error={userAdvertisements?.error} closeMessage={closeMessage}/>
     : null
    }
    </div>
  )
}

AdvertsHome.propTypes = {
  incomingMessage: PropTypes.array.isRequired,
  disconnectWS: PropTypes.func.isRequired,
  userDataLoad: PropTypes.string.isRequired,
  setUserDataLoad: PropTypes.func.isRequired,
  userHeader: PropTypes.bool.isRequired,
  setUserHeader: PropTypes.func.isRequired,
  socketMessageIndex: PropTypes.number.isRequired,
  setSocketMessageIndex: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  // Map the relevant state from the Redux store to props
  return {
    advertisements: state.advertisements,
  };
};

export default connect(mapStateToProps)(AdvertsHome)