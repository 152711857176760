import { createSlice } from '@reduxjs/toolkit';

import { resetPassword, resetPasswordWithToken, requestResetPassword, getPasswordResetTokenStatus } from './resetPasswordActions';

type ResetPasswordState = {
  loading: boolean;
  success: boolean;
  error?: string;
}

const initialState: ResetPasswordState = {
  loading: false,
  success: false,
  error: null,
}

const resetPasswordSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload as string;
      })
      .addCase(resetPasswordWithToken.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(resetPasswordWithToken.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(resetPasswordWithToken.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload as string;
      })
      .addCase(requestResetPassword.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(requestResetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(requestResetPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload as string;
      })
      .addCase(getPasswordResetTokenStatus.pending, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(getPasswordResetTokenStatus.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.error = null
      })
      .addCase(getPasswordResetTokenStatus.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = action.payload as string;
      })
  }
});

export default resetPasswordSlice.reducer