import { useEffect, useState } from 'react'
import { useDispatch, connect, useSelector } from 'react-redux'
import { RootState } from '../../app/store';
import useTranslation from "../../components/customHooks/Translations";
import '../../styles/IOIbasic.css'
import PropTypes from 'prop-types';

export const IOIStatus = props => {

  const translation = useTranslation();
  const [dateState, setDateState] = useState(new Date());
  const [status, setStatus] = useState(props.advert?.status || '');
  const exchangeHours = useSelector((state: RootState) => state.advertisements.exchangeHours);//["23:15-05:45", "07:30-08:45", "09:01-09:30", "09:40-10:00", "10:30-10:33"]
  const platformHours = useSelector((state: RootState) => state.advertisements.platformHours);//["23:00-08:45", "09:01-10:33", "10:40-11:00"]
  const [exchangeStatus, setExchangeStatus] = useState("closed");
  const [exchangeTimes, setExchangeTimes] = useState("");

  function getExchangeTimeString(times) {
    let retString = "";
    if(times.length > 0){
      for(let i = 0; i < times.length; i++){
        const smallerTimes = times[i]?.match(/\d\d:\d\d/g);
        if(smallerTimes.length > 1){
          retString = retString + (new Date('Jan 1, 1970 ' + (smallerTimes[0] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" })) + '-' + (new Date('Jan 1, 1970 ' + (smallerTimes[1] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" }));
          if(i < times.length - 1) {
            retString = retString + ', ';
          }
        }
      }
    }
    return retString;
  }

  function generatePausedString() {
    let exchangeHoursindex = 0;
    let pausedTimesList = [];
    for(let i = 0; i < platformHours.length; i++){
      if(exchangeHoursindex >= exchangeHours.length){
        pausedTimesList.push(platformHours[i]);
      }

      const platformHoursGroup = platformHours[i]?.match(/\d\d:\d\d/g);

      //Used to tell if we need to start from the platform start
      let startOfPlatformHours = true;

      //loop through all the matchmaking periods starting within platform period i
      while(exchangeHoursindex < exchangeHours.length){
        const exchangeHoursGroup = exchangeHours[exchangeHoursindex]?.match(/\d\d:\d\d/g);

        //check if the next exchange group is after the current platform period end point
        if(platformHoursGroup[0] > platformHoursGroup[1] && exchangeHoursGroup[0] > platformHoursGroup[1] && exchangeHoursGroup[0] < platformHoursGroup[0]){
          break;
        }else if(platformHoursGroup[0] <= platformHoursGroup[1] && exchangeHoursGroup[0] > platformHoursGroup[1]){
          break;
        }

        //check if platform is on before first matchmaking period starts
        if(platformHoursGroup[0] > platformHoursGroup[1] && exchangeHoursGroup.length > 1 && startOfPlatformHours){
          if(exchangeHoursGroup[0] > platformHoursGroup[0] || exchangeHoursGroup[0] < platformHoursGroup[1] ){
            
            pausedTimesList.push(platformHoursGroup[0] + "-" + exchangeHoursGroup[0]);
          }
        }else if(exchangeHoursGroup.length > 1 && startOfPlatformHours){
          if(exchangeHoursGroup[0] !== platformHoursGroup[0]){
            pausedTimesList.push(platformHoursGroup[0] + "-" + exchangeHoursGroup[0]);
          }
        }

        //Given this was true on the first loop, set to false to not re check
        startOfPlatformHours = false;

        //check if matchmaking ends before platform closes
        if(platformHoursGroup[0] > platformHoursGroup[1] && exchangeHoursGroup[1] < platformHoursGroup[1]){
          if(exchangeHours.length > exchangeHoursindex + 1){
            const nextExchangeHoursGroup = exchangeHours[exchangeHoursindex + 1]?.match(/\d\d:\d\d/g);
            if(nextExchangeHoursGroup[0] < platformHoursGroup[1] || nextExchangeHoursGroup[0] > platformHoursGroup[0]){
              pausedTimesList.push(exchangeHoursGroup[1] + "-" + nextExchangeHoursGroup[0]);
            }
          }else{
            pausedTimesList.push(exchangeHoursGroup[1] + "-" + platformHoursGroup[1]);
          }
        }else{
          if(exchangeHoursGroup[1] < platformHoursGroup[1]){
            //check if next event is matchmaking re open or platform close
            if(exchangeHours.length > exchangeHoursindex + 1){
              const nextExchangeHoursGroup = exchangeHours[exchangeHoursindex + 1]?.match(/\d\d:\d\d/g);
              if(nextExchangeHoursGroup[0] < platformHoursGroup[1]){
                pausedTimesList.push(exchangeHoursGroup[1] + "-" + nextExchangeHoursGroup[0]);
              }else{
                pausedTimesList.push(exchangeHoursGroup[1] + "-" + platformHoursGroup[1]);
                //exchangeHoursindex++;
              }
            }else{
              pausedTimesList.push(exchangeHoursGroup[1] + "-" + platformHoursGroup[1]);
            }
          }
        }
        exchangeHoursindex++;
      }
      
    }
    setExchangeTimes(getExchangeTimeString(pausedTimesList));
  }

  function checkForStatusUpdate() {
    const currentTime = new Date(Date.now());
    let withinPlatformHours = false;
    let withinExchangeHours = false;
    if(platformHours?.length > 0){
      for(let i = 0; i < platformHours.length; i++){
        const smallerTimes = platformHours[i]?.match(/\d\d:\d\d/g);
        if(smallerTimes.length > 1){
          const startTime = (new Date('Jan 1, 1970 ' + (smallerTimes[0] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" }));
          const endTime = (new Date('Jan 1, 1970 ' + (smallerTimes[1] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" }));
          const currentTimeString = currentTime.toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" })
          if(startTime > endTime){
            //check if current time is either >= than index 0 OR <= than index 1
            if(currentTimeString >= startTime || currentTimeString <= endTime){
              withinPlatformHours = true;
              break;
            }
          }else{
            //check if current time is both >= than index 0 and <= than index 1
            if(currentTimeString >= startTime && currentTimeString <= endTime){
              withinPlatformHours = true;
              break;
            }
          }
        }
      }
      if(exchangeHours?.length > 0 && withinPlatformHours){
        for(let i = 0; i < exchangeHours.length; i++){
          const smallerTimes = exchangeHours[i]?.match(/\d\d:\d\d/g);
          if(smallerTimes.length > 1){
            const startTime = (new Date('Jan 1, 1970 ' + (smallerTimes[0] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" }));
            const endTime = (new Date('Jan 1, 1970 ' + (smallerTimes[1] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" }));
            const currentTimeString = currentTime.toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" })
            if(startTime > endTime){
              //check if current time is either >= than index 0 OR <= than index 1
              if(currentTimeString >= startTime || currentTimeString <= endTime){
                withinExchangeHours = true;
                break;
              }
            }else{
              //check if current time is both >= than index 0 and <= than index 1
              if(currentTimeString >= startTime && currentTimeString <= endTime){
                withinExchangeHours = true;
                break;
              }
            }
          }
        }
        if(withinExchangeHours){
          //open and use exchange hours string
          setExchangeStatus("open");
          setExchangeTimes(getExchangeTimeString(exchangeHours));
        }else{
          //paused and generate paused time string
          setExchangeStatus("paused");
          generatePausedString();
        }
      }else if(withinPlatformHours){
        //paused and use platform hours string
        setExchangeStatus("paused");
        setExchangeTimes(getExchangeTimeString(platformHours));
      }else{
        //closed and use platform hours string
        setExchangeStatus("closed");
        setExchangeTimes(getExchangeTimeString(platformHours));
      }
    }else{
      setExchangeStatus("closed");
      setExchangeTimes("");
    }
  }

  useEffect(() => {
    checkForStatusUpdate();
    const newIntervalId = setInterval(() => {
      checkForStatusUpdate()
    }, 10000);
    return () => {
      clearInterval(newIntervalId);
    };
  }, [platformHours, exchangeHours])

  useEffect(() => { 
    if(props.advert?.advertisementStatus){
      if (props.advert.advertisementStatus?.toUpperCase() === 'SENT' || props.advert.advertisementStatus?.toUpperCase() === 'LIVE') {
        setStatus(translation.statusSENT);
      } else if (props.advert.advertisementStatus?.toUpperCase() === 'PAUSED') {
        setStatus(translation.statusPAUSED);
      } else if (props.advert.advertisementStatus?.toUpperCase() === 'UNSENT') {
        setStatus(translation.statusUNSENT);
      } else if (props.advert.advertisementStatus?.toUpperCase() === 'DEAD') {
        if(props.advert.causeOfDeath?.toUpperCase() === 'EXPIRED'){
          setStatus(translation.statusEXPIRED);
        }else{
          setStatus(translation.statusCANCELLED);
        }
      } else if (props.advert.advertisementStatus?.toUpperCase() === 'COMPLETED') {
        setStatus(translation.statusCOMPLETE);
      } else if (props.advert.advertisementStatus?.toUpperCase() === 'ACCEPT/DECLINE?' || props.advert.advertisementStatus?.toUpperCase() === 'LOCATED') {
        setStatus(translation.statusLOCATED);
      }else {
        setStatus(translation.statusUndefined);
      }
    }
    if((Date.parse((props?.advert?.expiryDate + " UTC").replace(/-/g, "/")) - (Date.now())) >= 0){
      setDateState(new Date(Date.parse((props?.advert?.expiryDate + " UTC").replace(/-/g, "/")) - (Date.now())))
    }else{
      setDateState(new Date(0))
    }
    const newIntervalId = setInterval(() => {
      if((Date.parse((props?.advert?.expiryDate + " UTC").replace(/-/g, "/")) - (Date.now())) >= 0){
        setDateState(new Date(Date.parse((props?.advert?.expiryDate + " UTC").replace(/-/g, "/")) - (Date.now())))
      }else{
        setDateState(new Date(0))
      }
    }, 1000);
    return () => {
      clearInterval(newIntervalId);
    };
  }, [props.advert, translation.language]);

  return (
    <div className='IOI-status' style={exchangeStatus === "closed" ? {borderColor: "#000000", backgroundColor: "#000000"} : exchangeStatus === 'open' ? {} : {borderStyle: "dashed"}}>
        {exchangeStatus === "closed" ? 
        <div className='IOI-status-text' style={{color: "#FFFFFF", lineHeight: "calc(16*var(--responsiveFont))", marginTop: "0%"}}>
          {translation.currentlyClosed} <br></br> <span style={{fontWeight: "200"}}>{translation.hoursOfOperation} <br></br> {exchangeTimes}</span>
        </div>
        : 
        <div className='IOI-status-text'>
            {translation.status}: 
            <span className={'IOI-status-string-'+ props?.advert?.advertisementStatus?.toUpperCase()}> {status}</span>
        </div>
        }
        {exchangeStatus === "closed" ? <></> :
        <div className='IOI-status-timestamp'> {
        (['DEAD', 'COMPLETED', 'UNSENT'].findIndex(status => status === props?.advert?.advertisementStatus?.toUpperCase()) === -1 && dateState !== undefined) ?
        dateState.toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    timeZone: 'UTC',
                    hourCycle: 'h23'
                })
              :
              '--:--:--'}</div>
        }
        <div className={'IOI-status-hours-indicator ' + props?.container} style={exchangeStatus === 'open' ? {backgroundColor: "#2E5F3C"} : (exchangeStatus === 'closed' ? {} : {backgroundColor: "#FFC400"})}></div>
        <div className='IOI-status-hours-indicator-menu' style={exchangeStatus === "closed" ? {display: 'none'} : translation.language === "Jp" ? {width: "110%", right: "-55%"} : {}}>
          {translation.receivingIOIs}, {translation.matchmakingEngine} {exchangeStatus === "open" ? translation.on : translation.off}:
          <br></br> <span style={{fontWeight: "500", color: "#000000"}}>{exchangeTimes}</span>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  // Map the relevant state from the Redux store to props
  return {
    advert: state.advertisements.selectedAdvertisement,
  };
};

IOIStatus.propTypes = {
  container: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(IOIStatus);