import '../../styles/bulkUpload.css';

import {
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import {
  RootState,
  useAppDispatch,
} from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';
import {
  bulkUploadAdverts,
  submitAdvert,
} from '../../features/adverts/advertisementActions';
import InvalidCSVPopup from '../InvalidCSVPopup';
import Spinner from '../Spinner';
import { AdvertisementBulkUpload } from '../../app/model/model';

export const BulkUpload = props => {

  const dispatch = useAppDispatch()
  const translation = useTranslation();

  const {lastAction, isLoading, advertisements } = useSelector((state: RootState) => state.advertisements);

  const [dispatchedData, setDispatchedData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const equitiesList = useSelector((state: RootState) => state.equities);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [showInvalidCsvDialog, setShowInvalidCsvDialog] = useState(false);
  const [selectAll, setSelectAll] = useState(true)
  const [initSelection, setInitSelection] = useState(true)

  async function bulkFileUpload () {
    const files = (document.getElementById('bulk-file-drop-input') as HTMLInputElement).files;
    if (files.length > 0) {
      const file = files[0];
      if (!file.name.endsWith('.csv') || (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel')) {
        setShowInvalidCsvDialog(true);
        return;
      }
      const dispatchedDataNew = await dispatch(bulkUploadAdverts(file, equitiesList.equities));
      setDispatchedData(dispatchedDataNew);
    }
  }

  useEffect(() => {
    if(lastAction === 'bulkUploadSuccess' && (dispatchedData?.length > 0)){
      console.log('DATA')
      console.log(dispatchedData);
      if (initSelection) {
        dispatchedData.forEach(element => {
          if (element.status == 'OK') {
            element.selected = true
          }
        });
        setInitSelection(false)
      }
      setUploadProgress(true);
    }
  }, [lastAction, dispatchedData])

  function getSecurityName (securityId) {
    const matchingEquity = equitiesList.equities.find(equity => equity.equityId === securityId)
    return (matchingEquity?.ric + ' - ' + matchingEquity?.name) || '';
  }

  async function submitUploads() {
    setSubmitLoading(true);
    let submitPassed = false;
    if(dispatchedData?.length > 0){
      for(let i = 0; i < dispatchedData.length; i++){
        if(dispatchedData[i].status === 'OK' && dispatchedData[i].selected === true){
          const submitResult = await dispatch(submitAdvert(dispatchedData[i].advertisement));
          if(typeof submitResult === typeof [] && 'status' in (submitResult as undefined as object)){
            submitPassed = true;
          }
        }
      }
    }
    setSubmitLoading(false);
    if(submitPassed && props.container === 'minimal'){
      props.setContainer('basic');
    }
    props.setUpload(false);
  }

  function getErrorMessage(dataEntry) {
    let errorString = translation[dataEntry?.errorCode] || '';
    for(const key in dataEntry?.errorMap){
      var value = dataEntry?.errorMap[key]
      if (key === 'shareQuantity' || key === 'minimumShareQuantity') {
        value = parseInt(value).toLocaleString('en-US')
      }
      errorString = errorString.replace('['+key+']', (translation[value.toLowerCase()] || value));
    }
  
    errorString = errorString.replace('[ric]', (equitiesList.equities.find(equity => equity.equityId === dataEntry?.errorMap["security_id"])?.ric));
    
    if(dataEntry?.errorCode === "ERR-012") {
        const timeStrings = dataEntry?.status?.match(/\d\d:\d\d to \d\d:\d\d/g)
        if (timeStrings != null) {
          for(let i = 0; i < timeStrings.length; i++){
            const smallerTimes = timeStrings[i]?.match(/\d\d:\d\d/g);
            errorString = errorString + new Date('Jan 1, 1970 ' + (smallerTimes[0] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" })
            errorString = errorString + '-' + new Date('Jan 1, 1970 ' + (smallerTimes[1] || '') + ' UTC').toLocaleTimeString([], { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" })
            if(i !== timeStrings.length-1){
              errorString = errorString + ', ';
            }
          }
        }
        const exchangeName = dataEntry?.status?.match(/the\s(.+)\sare/);
        if(exchangeName && exchangeName.length > 0){
          errorString = errorString.replace('[exchange]', exchangeName[1]);
        }
    }
    return errorString || dataEntry?.status;
  }

  type AdvertisementsSelection = {
    advertisementBulkUpload: AdvertisementBulkUpload;
    selected: boolean;
  };

  function changeSelectedState(index) {
    let newAdvertSelection: AdvertisementsSelection[] = [];
    let count = 0;
    dispatchedData.forEach(item => {
      const modifiableAdvert = { ...item };
      if (count == index) {
        modifiableAdvert.selected = !modifiableAdvert.selected;
      }
      newAdvertSelection.push(modifiableAdvert);
      count++;
    });
    setDispatchedData(newAdvertSelection);
  }

  function changeAllStates(selected: boolean) {
    setSelectAll(selected)
    let newAdvertSelection: AdvertisementsSelection[] = [];
    dispatchedData.forEach(item => {
      const modifiableAdvert = { ...item };
      modifiableAdvert.selected = selected
      newAdvertSelection.push(modifiableAdvert);
    });

    setDispatchedData(newAdvertSelection);
  }

  return (
    <div className={uploadProgress ? 'bulk-upload-confirmation-popup ' + props?.container : 'bulk-upload-popup ' + props?.container}>
      {(isLoading || submitLoading) && <Spinner/>}
      {uploadProgress ? <>
      <div className='bulk-upload-popup-top'>{translation.bulkUploadConfirm}</div>
      <div className='upload-confirmation-IOI-summary-header'>
        <div className='bulk-upload-checkbox-container' onClick={() => changeAllStates(!selectAll)}>
          <div className='bulk-upload-checkbox'>
            <div className={selectAll ?'bulk-upload-checkbox-check' : 'IOI-entry-security-checkbox-empty'}></div>
          </div>
        </div>
        <div className='upload-confirmation-summary-header-text'>{translation.ioiSummary}</div>
      </div>
      <div className='bulk-upload-IOI-summary'>
        {dispatchedData?.map((dataEntry,index) => (
          <div className='bulk-upload-IOI-summary-entry' key={index} style={dataEntry?.status === 'OK' ? null :  {backgroundColor: "#D5D5D5"}}>
            <div className='bulk-upload-checkbox-container' onClick={() => changeSelectedState(index)}>
              <div className='bulk-upload-checkbox'>
                {dataEntry?.status === 'OK' ?
                  dataEntry?.selected === true ?
                    <div className='bulk-upload-checkbox-check'  ></div>
                    : <div className='IOI-entry-security-checkbox-empty' ></div> 
                    
                  :
                  <svg className='bulk-upload-checkbox-x prevent-select' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>
                }
              </div>
            </div>
            {dataEntry?.status === 'OK' ?
            <div className='bulk-upload-IOI-summary-entry-text'>
              <span>{getSecurityName(dataEntry?.advertisement?.securityId || "")} | </span>
              <span style={dataEntry?.advertisement?.direction === 'BUY' ? {color: "#0EAA3B"}: {color: "#BC3C3C"}}>{dataEntry?.advertisement?.direction === 'BUY' ? translation.buy : translation.sell} {dataEntry?.advertisement?.shareQuantity ? <NumericFormat displayType='text' thousandSeparator=',' value={dataEntry?.advertisement?.shareQuantity}></NumericFormat> : '-'} {translation.shares}</span>
              <span style={{fontWeight: "normal"}}> (
              <NumericFormat displayType='text' decimalScale={1} prefix='$' suffix={dataEntry?.advertisement?.usdnotional > 100000 ? 'm ' : ' '} value={dataEntry?.advertisement?.usdnotional > 100000 ? (dataEntry?.advertisement?.usdnotional / 1000000): dataEntry?.advertisement?.usdnotional}></NumericFormat>
               /
              <NumericFormat displayType='text' decimalScale={1} prefix=' JPY ' suffix={dataEntry?.advertisement?.jpynotional > 100000 ? 'm ' : ' '} value={dataEntry?.advertisement?.jpynotional > 100000 ? (dataEntry?.advertisement?.jpynotional / 1000000): dataEntry?.advertisement?.jpynotional}></NumericFormat>
              {translation.notional})</span> <span>{dataEntry?.advertisement?.transactionTypePreference === 'CASH_SWAP' ? translation.transactionTypeCASH_SWAP : dataEntry?.advertisement?.transactionTypePreference === 'CASH_ONLY' ? translation.transactionTypeCASH_ONLY : translation.transactionTypeSWAP_ONLY}</span>
            </div>
            :
            <div className='bulk-upload-IOI-summary-entry-text'>
              {dataEntry?.advertisement !== null ?
                <span>{dataEntry?.status}</span>
                :
                <span style={{color: '#454545'}}>{getErrorMessage(dataEntry)}</span>
              }
            </div>
            }
          </div>
        )
        )}
        {Array.apply(null, { length: (8 - dispatchedData?.length >= 0 ? (8-dispatchedData.length) : 0) }).map((e, i) => (
          <div className='bulk-upload-IOI-summary-entry' key={i+dispatchedData.length}>
            <div className='bulk-upload-checkbox-container'>

            </div>
            <div className='bulk-upload-IOI-summary-entry-text'></div>
          </div>
        ))}

      </div>
      { dispatchedData?.length - dispatchedData.filter((entry) => entry?.status === 'OK').length > 0 ?
      <div className='bulk-upload-IOI-summary-results'>
        <div className='bulk-upload-checkbox-container'>
            <div className='bulk-upload-checkbox'>
              <svg className='bulk-upload-checkbox-x prevent-select' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>
            </div>
          </div>
          <span style={{fontWeight: "bold", color: "#CB0000"}}>{dispatchedData?.length - dispatchedData.filter((entry) => entry?.status === 'OK').length} {dispatchedData?.length - dispatchedData.filter((entry) => entry?.status === 'OK').length > 1 ? translation.securities : translation.security}</span>
          <span style={{color: "#CB0000"}}> {translation.couldNotUpload}</span> | {translation.reuploadCSVText}
      </div>
      :
      <div className='bulk-upload-IOI-summary-results'></div>
      }
      <button className='cancel-button-bulk-confirm' onClick={() => {setDispatchedData(null); props.setUpload(false)}}>{translation.cancel}</button>
      <button className='submit-button-bulk-confirm' onClick={() => {submitUploads()}}>{translation.upload}</button>
      </>
       : <>
      <div className='bulk-upload-popup-top'>{translation.bulkUpload}</div>
      <input className='bulk-file-drop-input prevent-select' id='bulk-file-drop-input' type='file' accept='text/csv' onInput={() => {bulkFileUpload();}}></input>
      <div className='bulk-file-drop' >
        <img id="Group_211" src="Group_211.png" srcSet="Group_211.png 1x, Group_211@2x.png 2x"></img>
        <div className='drag_n_drop_text'>
            {translation.dragDropCSV}
        </div>
        <div className='browse_file_text'>
          {translation.browseFile}
        </div>
      </div>
      <button className='cancel-button-bulk' onClick={() => {props.setUpload(false)}}>{translation.cancel}</button>
      <a href="bulkUploadTemplate.csv" download={"bulkUploadTemplate - Authentic Indication.csv"}><button className='template-button-bulk'>
      <svg xmlns="http://www.w3.org/2000/svg" className='downloadIcon' height="1em" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
        <span>{translation.downloadTemplate}</span>
      </button></a>
      </>}
      {showInvalidCsvDialog
        ? (
          <InvalidCSVPopup
            onCloseClick={() => setShowInvalidCsvDialog(false)}
          />
        )
        : null
      }
    </div>
  )
}

BulkUpload.propTypes = {
  setUpload: PropTypes.func.isRequired,
  container: PropTypes.string.isRequired,
  setContainer: PropTypes.func.isRequired
};

export default BulkUpload