import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import { RootState } from '../../app/store';
import { ConnectionAgreement } from '../../app/model/model';
import {GATEWAY_URL} from '../../Constant'

interface ConnectionAgreementState {
  isLoading: boolean;
  connectionAgreements: ConnectionAgreement[];
  error: string | null;
  lastAction: string | null;
  sortProperty: string;
  sortDirection: number
}

const initialState: ConnectionAgreementState = {
  isLoading: false,
  connectionAgreements: [],
  error: null,
  lastAction: null,
  sortProperty: 'locateTime',
  sortDirection: 1
};

type ConnectionAgreementsPayload = {
  data: ConnectionAgreement[];
  rootState: RootState;
};

const connectionAgreementsSlice = createSlice({
  name: 'connectionAgreements',
  initialState,
  reducers: {
    fetchConnectionAgreementsStart(state) {
      state.isLoading = true;
      state.error = null;
      state.lastAction = 'fetchStart';
    },
    fetchConnectionAgreementsSuccess(state, action: PayloadAction<ConnectionAgreementsPayload>) {
      const { data, rootState } = action.payload;
      state.isLoading = false;

      data.forEach((connectionAgreement) => {
        const matchingEquity = rootState.equities.equities.find(
          (equity) => equity.equityId === connectionAgreement.securityUUID
        );
        const securityName = (matchingEquity?.ric + ' - ' + matchingEquity?.name) || '';
        connectionAgreement.securityName = securityName;
      });

      state.connectionAgreements = data.sort((a , b) => {
      if(state.sortProperty in a){
        if(typeof a[state.sortProperty] === 'string'){
          if (a[state.sortProperty] < b[state.sortProperty]) {
            return (-1)*state.sortDirection;
          }
          if (a[state.sortProperty] > b[state.sortProperty]) {
            return 1*state.sortDirection;
          }

          return 0;

        }else if(typeof a[state.sortProperty] === 'number'){
          return ((a[state.sortProperty] - b[state.sortProperty]) * state.sortDirection);
        }else{
          //assumed boolean
          return (a[state.sortProperty] === b[state.sortProperty])? 0 : a[state.sortProperty]? ((-1)*state.sortDirection) : (1*state.sortDirection);
        }
      }
      if (a[state.sortProperty] < b[state.sortProperty]) {
        return -1;
      }
      if (a[state.sortProperty] > b[state.sortProperty]) {
        return 1;
      }
    
      //default return
      return 0;});
      state.lastAction = 'fetchSuccess';
    },
    fetchConnectionAgreementsFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'fetchFailure'
    },
    confirmConnectionExecutionStart(state) {
      state.isLoading = true;
      state.error = null;
      state.lastAction = 'confirmationStart';
    },
    confirmConnectionExecutionSuccess(state){
      state.isLoading = false;
      state.error = null;
      state.lastAction = 'confirmationSuccess';
    },
    confirmConnectionExecutionFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'confirmationFailure';
    },
    undoConfirmConnectionExecutionStart(state) {
      state.isLoading = true;
      state.error = null;
      state.lastAction = 'undoConfirmationStart';
    },
    undoConfirmConnectionExecutionSuccess(state){
      state.isLoading = false;
      state.error = null;
      state.lastAction = 'undoConfirmationSuccess';
    },
    undoConfirmConnectionExecutionFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'undoConfirmationFailure';
    },
    changeSortProperty(state, action: PayloadAction<Array<any>>) {
      state.sortDirection = action.payload[0] || 1;
      state.sortProperty = action.payload[1] || 'locateTime';
    },
    ackConnectionStart(state){
      state.lastAction = 'ackStart';
      state.error = null;
      state.isLoading = true;
    },
    ackConnectionSuccess(state){
      state.lastAction = 'ackSuccess';
      state.error = null;
      state.isLoading = false;
    },ackConnectionFailure(state, action: PayloadAction<string>){
      state.lastAction = 'ackFailure';
      state.error = action.payload;
      state.isLoading = false;
    }
  },
});

export const {
  fetchConnectionAgreementsStart,
  fetchConnectionAgreementsSuccess,
  fetchConnectionAgreementsFailure,
  confirmConnectionExecutionStart,
  confirmConnectionExecutionSuccess,
  confirmConnectionExecutionFailure,
  undoConfirmConnectionExecutionStart,
  undoConfirmConnectionExecutionSuccess,
  undoConfirmConnectionExecutionFailure,
  changeSortProperty,
  ackConnectionStart,
  ackConnectionSuccess,
  ackConnectionFailure,
} = connectionAgreementsSlice.actions;

export default connectionAgreementsSlice.reducer;

export const fetchConnectionAgreements = (): AppThunk => async (dispatch, getState) => {
  dispatch(fetchConnectionAgreementsStart());
  try {
    const rootState: RootState = getState();
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/connection-agreement/broker-user-execution-listing', {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const data = await response.json();
    dispatch(fetchConnectionAgreementsSuccess({ data, rootState }));
  } catch (error) {
    dispatch(fetchConnectionAgreementsFailure(error.message));
  }
};

export const confirmConnectionExecution = (connectionAgreementId, direction, status): AppThunk => async (dispatch, getState) => {
  dispatch(confirmConnectionExecutionStart());
  try {
    let bodyJSON = "";
    if(direction){
      bodyJSON = JSON.stringify({connectionAgreementId: connectionAgreementId, buyStatus: status})
    }else{
      bodyJSON = JSON.stringify({connectionAgreementId: connectionAgreementId, sellStatus: status})
    }
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/connection-agreement/broker-execution-confirmation', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: bodyJSON
    });
    const data = await response.status;
    dispatch(confirmConnectionExecutionSuccess());
  } catch (error) {
    dispatch(confirmConnectionExecutionFailure(error.message));
  }
};

export const undoConfirmConnectionExecution = (connectionAgreementId, direction, status): AppThunk => async (dispatch, getState) => {
  dispatch(undoConfirmConnectionExecutionStart());
  try {
    let bodyJSON = "";
    if(direction){
      bodyJSON = JSON.stringify({connectionAgreementId: connectionAgreementId, buyStatus: status})
    }else{
      bodyJSON = JSON.stringify({connectionAgreementId: connectionAgreementId, sellStatus: status})
    }
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/connection-agreement/broker-execution-undo', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: bodyJSON
    });
    const data = await response.status;
    dispatch(undoConfirmConnectionExecutionSuccess());
  } catch (error) {
    dispatch(undoConfirmConnectionExecutionFailure(error.message));
  }
};

export const setSortProperty = (direction, sortProperty): AppThunk => async (dispatch, getState) => {
  dispatch(changeSortProperty([direction, sortProperty]));
  dispatch(fetchConnectionAgreements());
};

export const ackConnectionAgreement = (connectionAgreementId): AppThunk => async (dispatch, getState) => {
  dispatch(ackConnectionStart());
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/connection-agreement/connect-broker', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({connectAgreementId: connectionAgreementId})
    });
    const data = await response.status;
    dispatch(ackConnectionSuccess());
  } catch (error) {
    dispatch(ackConnectionFailure(error.message));
  }
};