import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { GATEWAY_URL } from '../../Constant';

export const versionApi = createApi({
  reducerPath: 'versionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${GATEWAY_URL}/version`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('userToken');
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        return headers
      }
    },
  }),
  endpoints: (builder) => ({
    getWebVersion: builder.query<string, void>({
      query: () => ({
        url: 'web',
        responseHandler: 'text',
      }),
    }),
  }),
});

export const { useGetWebVersionQuery } = versionApi;
