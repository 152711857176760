export enum AdvertisementDuration {
  SIXTY = "60",
  ONE_HUNDRED_FIFTY = "150",
  END_OF_DAY = "END_OF_DAY",
};

export enum TransactionType {
  CASH_ONLY = 'CASH_ONLY',
  CASH_SWAP = 'CASH_SWAP',
  SWAP_ONLY = 'SWAP_ONLY',
};
