import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk } from '../../app/store';
import { RootState } from '../../app/store';
import { Advertisement, AdvertisementRestore, createAdvertisementObject, getPlatformExchangeHoursObject } from '../../app/model/model';
import {GATEWAY_URL} from '../../Constant'

interface AdvertisementsState {
  isLoading: boolean;
  advertisements: Advertisement[];
  expiredAdvertisements: AdvertisementRestore[];
  restoreAvailable: boolean;
  restoreDate: string;
  selectedAdvertisement: Advertisement;
  error: object | null;
  needRefresh: boolean;
  lastAction: string | null;
  lastUpdate: number;
  sortProperty: string;
  sortDirection: number;
  afterHours: boolean;
  platformHours: string[];
  exchangeHours: string[];
}

const initialState: AdvertisementsState = {
  isLoading: false,
  advertisements: [],
  expiredAdvertisements: [],
  restoreAvailable: false,
  restoreDate: null,
  selectedAdvertisement: null,
  error: null,
  needRefresh: false,
  lastAction: null,
  lastUpdate: Date.now(),
  sortProperty: 'submitTime',
  sortDirection: -1,
  afterHours: true,
  platformHours: [],
  exchangeHours: []
};

type AdvertisementsPayload = {
  data: Advertisement[];
  rootState: RootState;
};

type PrepareReloadPayload = {
  data: AdvertisementRestore[];
  rootState: RootState;
};

const advertisementsSlice = createSlice({
  name: 'advertisements',
  initialState,
  reducers: {
    fetchAdvertisementsStart(state) {
      state.isLoading = true;
      state.error = null;
      state.needRefresh = false;
      state.lastAction = 'fetchStart';
    },
    fetchAdvertisementsSuccess(state, action: PayloadAction<AdvertisementsPayload>) {
      const { data, rootState } = action.payload;
      state.isLoading = false;

      data.forEach((advertisement) => {
        const matchingEquity = rootState.equities.equities.find(
          (equity) => equity.equityId === advertisement.securityId
        );
        const securityName = (matchingEquity?.ric + ' - ' + matchingEquity?.name) || '';
        advertisement.securityName = securityName;
      });

      state.advertisements = data.sort((a , b) => {
        if(state.sortProperty === 'percentRemaining'){
          if ((a["quantityRemaining"] / a["shareQuantity"]) < (b["quantityRemaining"] / b["shareQuantity"])) {
            return (-1)*state.sortDirection;
          }
          if ((a["quantityRemaining"] / a["shareQuantity"]) > (b["quantityRemaining"] / b["shareQuantity"])) {
            return 1*state.sortDirection;
          }
          //default return
          return 0;
        }
        if(state.sortProperty in a){
          if(typeof a[state.sortProperty] === 'string'){
            if (a[state.sortProperty] < b[state.sortProperty]) {
              return (-1)*state.sortDirection;
            }
            if (a[state.sortProperty] > b[state.sortProperty]) {
              return 1*state.sortDirection;
            }
            if(state.sortProperty === 'advertisementStatus'){
              if (a['causeOfDeath'] < b['causeOfDeath']) {
                return (-1)*state.sortDirection;
              }
              if (a['causeOfDeath'] > b['causeOfDeath']) {
                return 1*state.sortDirection;
              }
            }
  
            return 0;
  
          }else if(typeof a[state.sortProperty] === 'number'){
            return ((a[state.sortProperty] - b[state.sortProperty]) * state.sortDirection);
          }else{
            //assumed boolean
            return (a[state.sortProperty] === b[state.sortProperty])? 0 : a[state.sortProperty]? ((-1)*state.sortDirection) : (1*state.sortDirection);
          }
        }
        if (a[state.sortProperty] < b[state.sortProperty]) {
          return -1;
        }
        if (a[state.sortProperty] > b[state.sortProperty]) {
          return 1;
        }
      
        //default return
        return 0;});
      if(state.selectedAdvertisement?.id){
        state.selectedAdvertisement = state.advertisements.find(advertisement => advertisement.id === state.selectedAdvertisement.id);
      }
      const currentExpiredIOI = [];
      const todayDate = new Date();
      const today = todayDate.toLocaleDateString();
      state.advertisements.forEach(item => {
        if (item.advertisementStatus === 'DEAD' && item.causeOfDeath === 'EXPIRED') {
          currentExpiredIOI.push(item);
        } else if (item.advertisementStatus === 'PAUSED' || item.advertisementStatus === 'SENT' || item.advertisementStatus === 'LIVE') {
          currentExpiredIOI.push(item);
        }
      });

      let found = false;
      if (state.advertisements.length > 0) {
        localStorage.setItem(today, JSON.stringify(currentExpiredIOI));
      }
      let backDays = 1;
      let previousDay = getPreviousDay(todayDate);
      while(!found && backDays < 7) {
        let storedData = localStorage.getItem(previousDay.toLocaleDateString());
        if (storedData != null) {
          state.restoreAvailable = true;
          state.restoreDate = previousDay.toLocaleDateString();
          found = true;
        }
        previousDay = getPreviousDay(previousDay);
        backDays++;
      }

      state.lastUpdate = Date.now();
      state.lastAction = 'fetchSuccess';
    },
    fetchAdvertisementsFailure(state, action: PayloadAction<object>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'fetchFailure';
    },
    prepareRestoreAdvertsStart(state) {
      state.isLoading = true;
      state.error = null;
      state.needRefresh = false;
      state.lastAction = 'prepareRestoreAdvertsStart';
    },
    prepareRestoreAdvertsSuccess(state, action: PayloadAction<PrepareReloadPayload>) {
      const { data, rootState } = action.payload;
      state.isLoading = false;
      state.expiredAdvertisements = data;
      state.needRefresh = false;
      state.lastAction = 'prepareRestoreAdvertsSuccess';
    },
    prepareRestoreAdvertsFailure(state, action: PayloadAction<object>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'prepareRestoreAdvertsFailure';
    },
    createAdvertisementStart(state) {
      state.isLoading = true;
      state.error = null;
      state.lastAction = 'createStart';
    },
    createAdvertisementSuccess(state, action: PayloadAction<createAdvertisementObject>) {
      console.log('Create Success');
      state.isLoading = false;
      state.needRefresh = true;
      state.lastAction = 'createSuccess';
    },
    createAdvertisementFailure(state, action: PayloadAction<object>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'createFailure';
    },
    selectAdvertisementState(state, action: PayloadAction<Advertisement>) {
      console.log("Selected for state :" + action.payload.id);
      state.selectedAdvertisement = action.payload;
      state.lastAction = 'select';
    },
    modifySelectedAdvertisement(state, action: PayloadAction<Advertisement>) {
      console.log('Modified Advert');
      state.selectedAdvertisement = action.payload;
      state.lastAction = 'modifySelected';
    },
    setRefreshFlag(state) {
      console.log('Refresh Flag Set');
      state.needRefresh = true;
      state.lastAction = 'setRefresh';
    },
    bulkUploadStart(state) {
      state.lastAction = 'bulkUploadStart';
      state.isLoading = true;
    },
    bulkUploadFailure(state) {
      state.lastAction = 'bulkUploadFailure';
      state.isLoading = false;
    },
    bulkUploadSuccess(state) {
      state.lastAction = 'bulkUploadSuccess';
      state.isLoading = false;
    },
    changeAdvertisementSortProperty(state, action: PayloadAction<Array<any>>) {
      state.sortDirection = action.payload[0] || -1;
      state.sortProperty = action.payload[1] || 'submitTime';
    },
    submitAdvertisementStart(state) {
      state.isLoading = false;
      state.error = null;
      state.lastAction = 'submitStart';
    },
    submitAdvertisementFailure(state, action: PayloadAction<object>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'submitFailure';
    },
    submitPriceConditionDefaultsStart(state) {
      state.isLoading = false;
      state.error = null;
      state.lastAction = 'submitPriceConditionDefaultsStart';
    },
    closeMessageView(state) {
      state.isLoading = false;
      state.error = null;
      state.lastAction = 'closeMessageView';
    },
    submitPriceConditionDefaultsSuccess(state) {
      state.isLoading = false;
      state.error = null;
      state.lastAction = 'submitPriceConditionDefaultsSuccess';
    },
    submitPriceConditionDefaultsFailure(state, action: PayloadAction<object>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'submitPriceConditionDefaultsFailure';
    },
    submitBrokerDefaultsStart(state) {
      state.isLoading = false;
      state.error = null;
      state.lastAction = 'submitBrokerDefaultsStart';
    },
    submitBrokerDefaultsSuccess(state) {
      state.isLoading = false;
      state.error = null;
      state.lastAction = 'submitBrokerDefaultsSuccess';
    },
    submitBrokerDefaultsFailure(state, action: PayloadAction<object>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'submitBrokerDefaultsFailure';
    },
    getAdvertExchangeStatusSuccess(state, action: PayloadAction<getPlatformExchangeHoursObject>) {
      state.isLoading = false;
      state.platformHours = action.payload.platformHourRanges;
      state.exchangeHours = action.payload.matchingEngineHourRanges;
      state.afterHours = action.payload.afterHours;
      state.lastAction = 'exchangeStatusSuccess';
    },
    getAdvertExchangeStatusFailure(state, action: PayloadAction<object>) {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = 'exchangeStatusFailure';
    },
  },
});

function getPreviousDay(today) {
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return yesterday;
}

export const {
  fetchAdvertisementsStart,
  fetchAdvertisementsSuccess,
  fetchAdvertisementsFailure,
  prepareRestoreAdvertsStart,
  prepareRestoreAdvertsSuccess,
  prepareRestoreAdvertsFailure,
  createAdvertisementStart,
  createAdvertisementSuccess,
  createAdvertisementFailure,
  selectAdvertisementState,
  modifySelectedAdvertisement,
  setRefreshFlag,
  closeMessageView,
  bulkUploadStart,
  bulkUploadFailure,
  bulkUploadSuccess,
  changeAdvertisementSortProperty,
  submitAdvertisementStart,
  submitAdvertisementFailure,
  submitPriceConditionDefaultsStart,
  submitPriceConditionDefaultsSuccess,
  submitPriceConditionDefaultsFailure,
  submitBrokerDefaultsStart,
  submitBrokerDefaultsSuccess,
  submitBrokerDefaultsFailure,
  getAdvertExchangeStatusSuccess,
  getAdvertExchangeStatusFailure,
} = advertisementsSlice.actions;

export default advertisementsSlice.reducer;

export const prepareRestoreAdverts = (expiredAdvertisements): AppThunk => async (dispatch, getState) => {
  dispatch(prepareRestoreAdvertsStart());
  try {
    const rootState: RootState = getState();
    const userToken = localStorage.getItem('userToken');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      maxBodyLength: Infinity,
    }
    const { data } = await axios.post(
      `${GATEWAY_URL}/advert/prepare-restore`, expiredAdvertisements,
      config,
    )
    dispatch(prepareRestoreAdvertsSuccess({data,rootState}));
  } catch (error) {
    dispatch(prepareRestoreAdvertsFailure());
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message
    } else {
      return error
    }
  }
};

export const fetchAdvertisements = (): AppThunk => async (dispatch, getState) => {
  dispatch(fetchAdvertisementsStart());
  try {
    const rootState: RootState = getState();
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/advert/advert-list', {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const data = await response.json();
    dispatch(fetchAdvertisementsSuccess({data,rootState}));
  } catch (error) {
    dispatch(fetchAdvertisementsFailure(error.message));
  }
};

export const getAdvertExchangeStatus = (advert): AppThunk => async (dispatch, getState) => {
  console.log('Getting exchange Status')
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/status/get-platform-hours/' + advert?.securityId, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const data = await response.json();
    dispatch(getAdvertExchangeStatusSuccess(data));
  } catch (error) {
    dispatch(getAdvertExchangeStatusFailure(error.message));
  }
};

export const selectAdvertisement = (advert): AppThunk => async (dispatch, getState) => {
  dispatch(selectAdvertisementState(advert));
  dispatch(getAdvertExchangeStatus(advert))
};

export const modifySelectAdvertisement = (advert): AppThunk => async (dispatch, getState) => {
  dispatch(modifySelectedAdvertisement(advert));
};

export const createAdvertisement = (equityId): AppThunk => async (dispatch, getState) => {
  dispatch(createAdvertisementStart());
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/advert/create-advert', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({securityId: equityId})
    });
    const data = await response.json();
    if(data?.error){
      throw(data)
    }
    dispatch(createAdvertisementSuccess(data));
    return true;
  } catch (error) {
    console.log(error)
    dispatch(createAdvertisementFailure(error));
    return false;
  }
};

export const setAdvertisementSortProperty = (direction, sortProperty): AppThunk => async (dispatch, getState) => {
  dispatch(changeAdvertisementSortProperty([direction, sortProperty]));
  dispatch(fetchAdvertisements());
};