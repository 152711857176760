import '../../styles/tabs.css';

import Tab from './Tab';

type TabConfig = {
  label: string;
  value: string;
}

type TabsProps = {
  tabs: TabConfig[];
  activeValue?: string;
  onTabClick?: (value: string) => void;
}

function Tabs({
  tabs,
  activeValue,
  onTabClick,

}: TabsProps): JSX.Element {
  return (
    <div className='tabs'>
      {tabs.map(t => (
        <Tab
          key={t.value}
          label={t.label}
          value={t.value}
          active={t.value === activeValue}
          onClick={() => onTabClick?.call(null, t.value)}
        />
      ))}
    </div>
  )
}

export default Tabs
