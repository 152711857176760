import Header from '../components/Header'
import PropTypes from 'prop-types';
import useTranslation from '../components/customHooks/Translations';

import '../styles/containers.css'
import BrokerSummary from '../components/brokers/BrokerSummary'
import '../styles/brokerSummary.css'
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../app/store';
import MatchConfirmedPopup from '../components/brokers/MatchConfirmedPopup';
import { ackConnectionAgreement, fetchConnectionAgreements } from '../features/connectionAgreements/connectionAgreementSlices';
import AutoLogOutPopup from '../components/AutoLogOutPopup';
import { callLogout } from '../features/auth/authSlice';

const BrokerHome = props => {

  const dispatch = useDispatch();
  const translation = useTranslation();

  const [notifications, setNotifications] = useState([]);

  const { userInfo } = useSelector((state) => (state as any).auth);

  function copyToClipboard() {
    let clipboardContent = '';
    const entries = document.getElementsByClassName("broker-summary-entry");
    const checkedEntries = [];
    for(let i = 0; i< entries.length; i++){
      if(entries[i].querySelector("input[name='securityCheck']:checked")){
        checkedEntries.push(entries[i]);
        clipboardContent = clipboardContent + entries[i].querySelector(".agreement-entry-security")?.textContent + "\t";
        clipboardContent = clipboardContent + entries[i].querySelector(".agreement-entry-quantity")?.textContent + "\t";
        clipboardContent = clipboardContent + entries[i].querySelector(".agreement-entry-price")?.textContent + "\t";
        clipboardContent = clipboardContent + entries[i].querySelector(".agreement-entry-type")?.textContent + "\t";
        const passwords = entries[i].querySelectorAll(".agreement-entry-password");
        const statuses = entries[i].querySelectorAll(".agreement-entry-status-div"); 
        clipboardContent = clipboardContent + passwords[0]?.textContent + "\t";
        clipboardContent = clipboardContent + statuses[0]?.textContent + "\t";
        clipboardContent = clipboardContent + passwords[1]?.textContent + "\t";
        clipboardContent = clipboardContent + statuses[1]?.textContent + "\t";
        clipboardContent = clipboardContent + entries[i].querySelector(".agreement-entry-timestamp")?.textContent;
        clipboardContent = clipboardContent + "\n";
      }
    }
    navigator.clipboard.writeText(clipboardContent).then(() => {alert(translation.copied)});
  }

  useEffect(() => {
    if(props?.socketMessageIndex < props.incomingMessage.length){
    try {
      const JSONreceived = JSON.parse(JSON.parse(props.incomingMessage[props?.socketMessageIndex]).message);
      if (JSONreceived && typeof JSONreceived === "object") {
        if('New IOI Match Confirmed' === JSONreceived.message){
          dispatch(fetchConnectionAgreements());
          notifications.unshift({...JSONreceived, 'visible': true, "ack": false, "messageType": "locate", "confirmedBy": null});
          console.log(notifications)
          setNotifications([...notifications]);
        }else if('Connection confirmed' === JSONreceived.message){
          dispatch(fetchConnectionAgreements());
          const notifIndex = notifications.findIndex(notif => (notif?.connectionAgreementId === JSONreceived.connectionAgreementId) && (notif?.message === 'New IOI Match Confirmed'));
          notifications[notifIndex].visible = false;
          notifications[notifIndex].confirmedBy = JSONreceived?.brokerUserName;

          if(JSONreceived?.brokerUserName === userInfo?.username){
            notifications[notifIndex].ack = true;
          }else{
            notifications[notifIndex].ack = false;
          }
          setNotifications([...notifications]);
          console.log(notifications)
        }else if('Connection status updated.' === JSONreceived?.message){
          dispatch(fetchConnectionAgreements());
          notifications.unshift({...JSONreceived, 'visible': false, "ack": (JSONreceived?.brokerUserName === userInfo?.username), "messageType": "statusAlert"});
          setNotifications([...notifications]);
          console.log(notifications)
        }
      }
    }
    catch (e) { 
      //Message received not json
      //Check if it is logout message
      try{
        const JSONreceived = JSON.parse(props.incomingMessage[props?.socketMessageIndex]);
        console.log(JSONreceived)
        if(JSONreceived && typeof JSONreceived === "object") {
          if(userInfo?.username + ' logged out' === JSONreceived?.message || userInfo?.username + ' timed out' === JSONreceived?.message){
            //session is invalid
            notifications.unshift({message: JSONreceived?.message, 'visible': true, "ack": true, "messageType": "statusAlert"});
            setNotifications([...notifications]);
            //disconnect websocket to prevent any further popups to an invalid session
            props.disconnectWS();
          }
        }
      }catch(e) {
        //Message received not json
        //No need to ACK back
      }
    }
    props?.setSocketMessageIndex(props?.socketMessageIndex+1);
    }
  }, [props.incomingMessage, props.socketMessageIndex])

  function closeNotification(notification, action=null) {
    const indexFound = notifications.indexOf(notification);
    if(indexFound !== -1){notifications[indexFound].visible = false; notifications[indexFound].ack = true}
    setNotifications([...notifications]);
    if(action !== null){
      if(action === 'ok'){
        dispatch(ackConnectionAgreement(notification?.connectionAgreementId))
      }
    }
  }

  return (
    <div className='broker-home-container containers'>
      {<Header notifications={notifications} setNotifications={setNotifications} container={"broker-home"} disconnectWS={props.disconnectWS} userDataLoad={props.userDataLoad} userHeader={props.userHeader} setUserHeader={props.setUserHeader}/>}
      {<BrokerSummary/>}
      <button onClick={() => {copyToClipboard()}} className='copy-summary-button prevent-select'>
        <img id="Group_38" src="Group_38_hh.png" srcSet="Group_38_hh.png 1x, Group_38_hh@2x.png 2x"></img>
        <span style={translation.language === 'Jp' ? {fontSize: "calc(13*var(--responsiveFont))"} : null}>{translation.copyToClipboard}</span>
      </button>
      {notifications.length > 0 ? 
        (notifications.filter((notif) => notif?.visible).length > 0) ? 
          (notifications.filter((notif) => notif?.visible)[notifications.filter((notif) => notif?.visible).length-1]?.message === 'New IOI Match Confirmed' ? 
            <MatchConfirmedPopup notification={notifications.filter((notif) => notif?.visible)[notifications.filter((notif) => notif?.visible).length-1]} closeNotification={closeNotification}/> 
          : 
            null) 
        :
          null
      : 
        null}

      {notifications.length > 0 ? 
        (notifications.filter((notif) => notif?.visible).length > 0) ? 
          (((notifications.filter((notif) => notif?.visible)[0]?.message === userInfo?.username + ' logged out') || (notifications.filter((notif) => notif?.visible)[0]?.message === userInfo?.username + ' timed out')) ? 
            <AutoLogOutPopup container={"advanced"} notification={notifications.filter((notif) => notif?.visible)[0]} closeNotification={(notif) => {notifications.splice(notifications.indexOf(notif),1); setNotifications([...notifications]); dispatch(callLogout());}}/> 
          : 
            null) 
        :
          null
      : 
        null}
    </div>
  )
}

BrokerHome.propTypes = {
  disconnectWS: PropTypes.func.isRequired,
  userDataLoad: PropTypes.string,
  userHeader: PropTypes.bool.isRequired,
  setUserHeader: PropTypes.func.isRequired,
  incomingMessage: PropTypes.array.isRequired,
  socketMessageIndex: PropTypes.number.isRequired,
  setSocketMessageIndex: PropTypes.func.isRequired,
};

export default BrokerHome