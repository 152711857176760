import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userLogin } from './authActions';
import { AppThunk } from '../../app/store';
import {GATEWAY_URL} from '../../Constant';

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userInfo: null,
  userToken,
  error: null,
  lastAction: null,
}

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    firstSetup: (state) => {
      state.loading = true;
      state.error = null;
      state.lastAction = 'firstSetup';
    },
    firstSetupSuccess: (state) => {
      localStorage.removeItem('userToken') // delete token from storage
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
      state.loading = false;
      state.lastAction = 'firstSetupSuccess';
    },
    firstSetupFailure: (state) => {
      state.loading = false;
      state.error = 'First Setup Failure';
      state.lastAction = 'firstSetupFailure';
    },
    logout: (state) => {
      localStorage.removeItem('userToken') // delete token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
      state.lastAction = 'logout';
    },
    logoutCallSuccess: (state) => {
      state.error = null;
      state.lastAction = 'logoutCallSuccess';
    },
    logoutCallFailure: (state) => {
      state.error = 'Logout Failure';
      state.lastAction = 'logoutCallFailure';
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
      state.error = null;
      state.lastAction = 'setCredentials';
      state.loading = false;
    },
    fetchUserInfoStart: (state) => {
      state.loading = true;
      state.error = null;
      state.lastAction = 'fetchStart';
    },
    fetchUserInfoSuccess: (state, { payload }) => {
      state.userInfo = payload;
      state.loading = false;
      state.error = null;
      state.lastAction = 'fetchSuccess';
    },
    fetchUserInfoFailure: (state, action: PayloadAction<string>) => {
      state.userInfo = null;
      state.loading = false;
      if(localStorage.getItem('userToken') !== null){
        state.error = 'You have been logged out from your previous session';
        localStorage.removeItem('userToken');
      }/*else{
        state.error = ' ';
      }*/
      state.loading = false;
      state.lastAction = 'fetchFailure';
    },
  },
  extraReducers: (builder) => {
    builder
    // login user
    .addCase(userLogin.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(userLogin.fulfilled, (state, {payload}) => {
      state.loading = false
      state.userToken = payload.userToken
    })
    .addCase(userLogin.rejected, (state, {payload}) => {
      state.loading = false
      state.error = payload
    })
  },
})

export const { firstSetup, firstSetupSuccess, firstSetupFailure, logout, logoutCallSuccess, logoutCallFailure, setCredentials, fetchUserInfoStart, fetchUserInfoSuccess, fetchUserInfoFailure } = authSlice.actions

export default authSlice.reducer

export const fetchUserInfo = (): AppThunk => async (dispatch, getState) => {
  dispatch(fetchUserInfoStart());
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/user/my-details', {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const data = await response.json();
    dispatch(fetchUserInfoSuccess(data));
  } catch (error) {
    dispatch(fetchUserInfoFailure(error.message));
  }
};

export const callLogout = (): AppThunk => async (dispatch, getState) => {
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/logout', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const data = await response.ok;
    dispatch(logoutCallSuccess());
  } catch (error) {
    console.log(error);
    dispatch(logoutCallFailure(error.message));
  }
}

export const callFirstSetup = (password, passwordConfirmation): AppThunk => async (dispatch, getState) => {
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(GATEWAY_URL + '/user/update-temp-password', {
      method: 'POST',
      body: JSON.stringify({password: password, passwordConfirmation: passwordConfirmation}),
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
    });
    const data = await response.ok;
    dispatch(firstSetupSuccess());
  } catch (error) {
    console.log(error);
    dispatch(firstSetupFailure(error.message));
  }
}