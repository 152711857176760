import '../styles/IOIpopups.css';

import semver from 'semver';

import { useGetWebVersionQuery } from '../app/services/versionService';
import {
  APP_VERSION,
  DEBUG,
} from '../Constant';
import useTranslation from './customHooks/Translations';

function NewVersionAvailablePopup(): JSX.Element {
  const translation = useTranslation();
  const { data } = useGetWebVersionQuery(null, {
    pollingInterval: 60 * 60 * 1000, // every hour
  });

  const showPopup = !DEBUG && data && semver.gt(data, APP_VERSION);

  return showPopup
    ? (
      <div
        className='IOI-basic-popup'
        style={{
          width: 'auto',
          height: 'auto',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '1rem',
        }}
      >
        <div
          className='IOI-review-body'
          style={{
            width: 'auto',
            height: 'auto',
          }}
        >
          <div
            className='IOI-main-details'
            style={{
              width: '30rem',
              height: 'auto',
            }}
          >
            {translation.newVersionAvailable}
          </div>
          <div
            className='IOI-review-buttons'
            style={{
              marginTop: '2rem',
              width: 'auto',
              height: 'auto',
            }}
          >
            <button
              className='connection-declined-button'
              style={{
                width: '5rem',
                height: 'auto',
                padding: '1rem',
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              {translation.ok}
            </button>
          </div>
        </div>
      </div>
    )
  : null;
}

export default NewVersionAvailablePopup;
