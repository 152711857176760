import '../../styles/forms.css';
import '../../styles/buttons.css';

import {
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import useTranslation from '../../components/customHooks/Translations';
import {
  RootState,
  useAppDispatch,
} from '../../app/store';
import {
  resetPassword,
} from '../../features/resetPassword/resetPasswordActions';

type Inputs = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

function ResetPassword(): JSX.Element {
  const state = useSelector((state: RootState) => state.resetPassword);
  const dispatch = useAppDispatch();
  const translation = useTranslation();

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, dirtyFields },
  }  = useForm<Inputs>({
    reValidateMode: 'onChange',
  });

  const onValid: SubmitHandler<Inputs> = ({
    oldPassword,
    newPassword,
    newPasswordConfirm
  }) => {
    dispatch(resetPassword({
      oldPassword,
      newPassword,
      newPasswordConfirm,
    }))
  };

  return (
    <div className='reset-password-content'>
      <h4 className='header'>{translation.resetPassword}</h4>
      <div className='form-container'>
        <form
          className='form'
          onSubmit={handleSubmit(onValid)}
        >
          <div className='form-field'>
            <label htmlFor="oldPassword">{translation.currentPassword}</label>
            <input
              type='password'
              className={errors.oldPassword && 'error'}
              {...register('oldPassword', {
                required: true,
              })}
            />
            {errors.oldPassword && <p className='error' role="alert">{errors.oldPassword.message}</p>}
          </div>
          <div className='form-field'>
            <label htmlFor="newPassword">{translation.newPassword}</label>
            <input
              type='password'
              className={errors.newPassword && 'error'}
              {...register('newPassword', {
                required: true,
                onChange() {
                  if (dirtyFields.newPasswordConfirm) {
                    trigger('newPasswordConfirm');
                  }
                }
              })}
            />
            {errors.newPassword && <p className='error' role="alert">{errors.newPassword.message}</p>}
          </div>
          <div className='form-field'>
            <label htmlFor="newPasswordConfirm">{translation.newPassword}</label>
            <input
              type='password'
              className={errors.newPasswordConfirm && 'error'}
              {...register('newPasswordConfirm', {
                required: true,
                deps: 'newPassword',
                onChange() {
                  trigger('newPasswordConfirm');
                },
                validate(value, formValues) {
                  return value === formValues.newPassword
                  || translation.passwordDoesNotMatch;
                }
              })}
            />
            {errors.newPasswordConfirm && <p className='error' role="alert">{errors.newPasswordConfirm.message}</p>}
          </div>
          <div>
            <input
              className='btn'
              value={translation.submitForm}
              type="submit"
              disabled={state.loading}
            />
          </div>
          {state.error && <p className='error'>{state.error === "Invalid existing password" ? translation.invalidExistingPassword : "state.error"}</p>}
          {state.success && <p className='success'>{translation.passwordResetSuccess}</p>}
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
