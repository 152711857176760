import axios from 'axios';
import Papa from 'papaparse';

import {
  Advertisement,
  BrokerDefaults,
  PriceConditionDefaults,
} from '../../app/model/model';
import { AppThunk } from '../../app/store';
import { GATEWAY_URL } from '../../Constant';
import {
  bulkUploadFailure,
  bulkUploadStart,
  bulkUploadSuccess,
  setRefreshFlag,
  submitAdvertisementFailure,
  submitAdvertisementStart,
  submitBrokerDefaultsFailure,
  submitBrokerDefaultsStart,
  submitBrokerDefaultsSuccess,
  submitPriceConditionDefaultsFailure,
  submitPriceConditionDefaultsStart,
  submitPriceConditionDefaultsSuccess,
} from './advertisementSlices';

export const submitBrokerDefaults = (advertisement): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(submitBrokerDefaultsStart())
      // configure header's Content-Type as JSON
      const userToken = localStorage.getItem('userToken');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
      var brokerDefs = <BrokerDefaults>{};
      brokerDefs.brokerIds = advertisement.brokerIds;
      brokerDefs.ranked = advertisement.ranked;

      const { data } = await axios.post(
        `${GATEWAY_URL}/user-defaults/set-broker-defaults`,
        brokerDefs,
        config
      ).catch((res) => {throw(res.response.data)});

      //dispatch(setRefreshFlag(data));
      dispatch(submitBrokerDefaultsSuccess())
      return data
    } catch (error) {
      dispatch(submitBrokerDefaultsFailure(error?.message || 'SubmitError'))
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return error.response.data.message
      } else {
        return error
      }
    }
};

export const submitPriceConditionDefaults = (advertisement): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(submitPriceConditionDefaultsStart())
      // configure header's Content-Type as JSON
      const userToken = localStorage.getItem('userToken');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
      var submitPriceCondDefs = <PriceConditionDefaults>{};
      submitPriceCondDefs.duration = advertisement.duration;
      submitPriceCondDefs.minQuantity = Number.parseInt((advertisement.minimumQuantity/ advertisement.shareQuantity * 100).toFixed(0));
      submitPriceCondDefs.quantityDecay =  advertisement.quantityDecay;
      submitPriceCondDefs.credibilityHurdle = advertisement.credibilityHurdle;
      submitPriceCondDefs.transactionType = advertisement.transactionType;
      submitPriceCondDefs.contraTransactionType = advertisement.transactionTypePreference;

      const { data } = await axios.post(
        `${GATEWAY_URL}/user-defaults/set-advert-pref-defaults`,
        submitPriceCondDefs,
        config
      ).catch((res) => {throw(res.response.data)});

     //dispatch(setRefreshFlag(data));
     dispatch(submitPriceConditionDefaultsSuccess());
      return data
    } catch (error) {
      dispatch(submitPriceConditionDefaultsFailure(error?.message || 'SubmitError'))
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return error.response.data.message
      } else {
        return error
      }
    }
};

export const submitAdvert = (advertisement): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(submitAdvertisementStart())
    console.log("Submit :" + advertisement);
      // configure header's Content-Type as JSON
      const userToken = localStorage.getItem('userToken');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
      var submitAdvert = <Advertisement>{};
      submitAdvert.id = advertisement.id;
      submitAdvert.securityId = advertisement.securityId;
      submitAdvert.duration = advertisement.duration;
      submitAdvert.direction = advertisement.direction;
      submitAdvert.shareQuantity = advertisement.shareQuantity;
      submitAdvert.minimumQuantity = advertisement.minimumQuantity;
      submitAdvert.quantityDecay =  advertisement.quantityDecay;
      //submitAdvert.contraCredRating = advertisement.contraCredRating;
      submitAdvert.currentQuantity = advertisement.currentQuantity;
      submitAdvert.credibilityHurdle = advertisement.credibilityHurdle;
      submitAdvert.advertisingTriggerActivated = advertisement.advertisingTriggerActivated;
      submitAdvert.advertisingTrigger = advertisement.advertisingTrigger;
      submitAdvert.advertTriggerSatisfied = advertisement.advertTriggerSatisfied;
      submitAdvert.knockoutTriggerActivated = advertisement.knockoutTriggerActivated;
      submitAdvert.knockoutTrigger = advertisement.knockoutTrigger;
      submitAdvert.indexRelativeKOTriggerActivated = advertisement.indexRelativeKOTriggerActivated;
      submitAdvert.indexRelativeKOTrigger = advertisement.indexRelativeKOTrigger;
      submitAdvert.brokerIds = advertisement.brokerIds;
      submitAdvert.ranked = advertisement.ranked;
      submitAdvert.transactionType = advertisement.transactionType;
      submitAdvert.transactionTypePreference = advertisement.transactionTypePreference;
      submitAdvert.usdnotional = Number(advertisement.usdnotional.toFixed(0));
      submitAdvert.jpynotional = Number(advertisement.jpynotional.toFixed(0));

      const { data } = await axios.post(
        `${GATEWAY_URL}/advert/submit-advert`,
        submitAdvert,
        config
      ).catch((res) => {throw(res.response.data)});

      dispatch(setRefreshFlag(data));

      return data
    } catch (error) {
      dispatch(submitAdvertisementFailure(error || 'SubmitError'))
      if (error.response && error.response.data.message) {
        return error.response.data.message
      } else {
        return error
      }
    }
};

export const modifyAdvert = (advertisement): AppThunk => async (dispatch, getState) => {
  try {
    console.log("Update :" + advertisement);
      // configure header's Content-Type as JSON
      const userToken = localStorage.getItem('userToken');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
      var submitAdvert = <Advertisement>{};
      submitAdvert.id = advertisement.id;
      submitAdvert.securityId = advertisement.securityId;
      submitAdvert.duration = advertisement.duration;
      submitAdvert.direction = advertisement.direction;
      submitAdvert.shareQuantity = advertisement.shareQuantity;
      submitAdvert.minimumQuantity = advertisement.minimumQuantity;
      submitAdvert.quantityDecay =  advertisement.quantityDecay;
      submitAdvert.currentQuantity = advertisement.currentQuantity;
      submitAdvert.credibilityHurdle = advertisement.credibilityHurdle;
      submitAdvert.advertisingTriggerActivated = advertisement.advertisingTriggerActivated;
      submitAdvert.advertisingTrigger = advertisement.advertisingTrigger;
      submitAdvert.advertTriggerSatisfied = advertisement.advertTriggerSatisfied;
      submitAdvert.knockoutTriggerActivated = advertisement.knockoutTriggerActivated;
      submitAdvert.knockoutTrigger = advertisement.knockoutTrigger;
      submitAdvert.indexRelativeKOTriggerActivated = advertisement.indexRelativeKOTriggerActivated;
      submitAdvert.indexRelativeKOTrigger = advertisement.indexRelativeKOTrigger;
      submitAdvert.brokerIds = advertisement.brokerIds;
      submitAdvert.ranked = advertisement.ranked;
      submitAdvert.transactionType = advertisement.transactionType;
      submitAdvert.transactionTypePreference = advertisement.transactionTypePreference;
      submitAdvert.usdnotional = Number(advertisement.usdnotional.toFixed(0));
      submitAdvert.jpynotional = Number(advertisement.jpynotional.toFixed(0));

      const { data } = await axios.post(
        `${GATEWAY_URL}/advert/modify-advert`,
        submitAdvert,
        config
      ).catch((res) => {throw(res.response.data)});

      dispatch(setRefreshFlag(data));

      return data
    } catch (error) {
      dispatch(submitAdvertisementFailure(error || 'SubmitError'))
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return error.response.data.message
      } else {
        return error
      }
    }
};

export const pauseAdvert = (advertisement): AppThunk => async (dispatch, getState) => {
  try {
    // configure header's Content-Type as JSON
    const userToken = localStorage.getItem('userToken');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    }

    const { data } = await axios.get(
      `${GATEWAY_URL}/advert/pause-advert/${advertisement.id}`,
      config
    )

    dispatch(setRefreshFlag(data));

    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message
    } else {
      return error
    }
  }
};

export const resumeAdvert = (advertisementId: string): AppThunk => async (dispatch, getState) => {
  try {
    // configure header's Content-Type as JSON
    const userToken = localStorage.getItem('userToken');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    }

    const { data } = await axios.get(
      `${GATEWAY_URL}/advert/resume-advert/${advertisementId}`,
      config
    )

    dispatch(setRefreshFlag(data));

    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message
    } else {
      return error
    }
  }
};

export const cancelAdvert = (advertisement): AppThunk => async (dispatch, getState) => {
  try {
    // configure header's Content-Type as JSON
    const userToken = localStorage.getItem('userToken');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    }

    const { data } = await axios.get(
      `${GATEWAY_URL}/advert/cancel-advert/${advertisement.id}`,
      config
    )

    dispatch(setRefreshFlag(data));

    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message
    } else {
      return error
    }
  }
};

export const residualAdvert = (advertisementId): AppThunk => async (dispatch, getState) => {
  try {
    // configure header's Content-Type as JSON
    const userToken = localStorage.getItem('userToken');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    }

    const { data } = await axios.get(
      `${GATEWAY_URL}/advert/create-residual-advertisement/${advertisementId}`,
      config
    )

    dispatch(setRefreshFlag(data));

    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message
    } else {
      return error
    }
  }
};

export const bulkUploadAdverts = (file, equitiesList): AppThunk => async (dispatch, getState) => {
  dispatch(bulkUploadStart());
  try {
    let rowsArray = [];
    let valuesArray = [];
    const parsePromise = new Promise(resolve => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader:function(h) {return h?.toLowerCase();},
      transform:function(val,col) {if(col === 'ric'){
        if(val === '' || val === undefined || val === null){
          return val;
        }
        const equityId = equitiesList.find((equity) => equity.ric === val)?.equityId;
        return equityId || 'invalid';
      }
      if(val.indexOf(',') > -1){
        return "\"" + val + "\"";
      }
      return val},
      complete: function (results) {
        console.log('results:')
        console.log(results.data)

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        if(rowsArray.length > 0){
          resolve(rowsArray[0].toString() + '\n' + valuesArray.map(a => a.join(",")).join('\n'));
        } else {
          resolve('');
        }
      },
    });
    })
    let parsedData = await parsePromise;
    console.log("parsedData", parsedData);
    /*let formData = new FormData();
    formData.append('file', file);*/
    // configure header's Content-Type as JSON
    const userToken = localStorage.getItem('userToken');
    const config = {
      headers: {
        'Content-Type': 'text/html',
        Authorization: `Bearer ${userToken}`,
      },
      maxBodyLength: Infinity,
    }

    const { data } = await axios.post(
      `${GATEWAY_URL}/advert/bulk-upload-adverts`, parsedData,
      config,
    )

    console.log(data);

    dispatch(bulkUploadSuccess());

    return data
  } catch (error) {
    dispatch(bulkUploadFailure());
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message
    } else {
      return error
    }
  }
};

export const acceptContraConnection = (loctionNotificationId, locationDirection): AppThunk => async (dispatch, getState) => {
  try {
    // configure header's Content-Type as JSON
    const userToken = localStorage.getItem('userToken');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    }

    const { data } = await axios.post(
      `${GATEWAY_URL}/locate-notification/connect-acceptance`,
      {"direction": locationDirection, "locationNotificationId" : loctionNotificationId},
      config
    )

    dispatch(setRefreshFlag(data));

    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message
    } else {
      return error
    }
  }
};

export const rejectContraConnection = (loctionNotificationId, locationDirection): AppThunk => async (dispatch, getState) => {
  try {
    // configure header's Content-Type as JSON
    const userToken = localStorage.getItem('userToken');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    }

    const { data } = await axios.post(
      `${GATEWAY_URL}/locate-notification/connect-rejection`,
      {"direction": locationDirection, "locationNotificationId" : loctionNotificationId},
      config
    )

    dispatch(setRefreshFlag(data));

    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message
    } else {
      return error
    }
  }
};

export const setRefresh = (): AppThunk => async (dispatch, getState) => {
  dispatch(setRefreshFlag());
};

//TEMPORARY CALL TO THE LIST
//WILL BE REPLACED WITH A GET AND MOVED TO DIFFERENT FILE
export const fetchLocationNotification = (locationNotificationId): AppThunk => async (dispatch, getState) => {
  try {
    // configure header's Content-Type as JSON
    const userToken = localStorage.getItem('userToken');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    }

    const { data } = await axios.get (
      `${GATEWAY_URL}/locate-notification/list-locate-notifications`,
      config
    )

    const userNotification = data[data.findIndex(notification => notification.locateNotificationId === locationNotificationId)];
    return userNotification
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message
    } else {
      return error
    }
  }
};
